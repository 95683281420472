import {createContext, useReducer} from "react";

const login = (user) => {
    sessionStorage.setItem('user', JSON.stringify(user));
    user.user_type = parseInt(user.user_type)
    return user;
}

const logout = (user) => {
    sessionStorage.removeItem('user');
    return {}
}

const reload = () => {
    let user = sessionStorage.getItem('user')
    if (user === null) {
        return {}
    }
    user = JSON.parse(user);
    user.user_type = parseInt(user.user_type);
    return user;
}

export const UserDetails = createContext(undefined);
export const UserActions = createContext(undefined);

export function UserProvider({children}) {

    const [user, dispatch] = useReducer(userReducer, null)

    return (
        <UserDetails.Provider value={user}>
            <UserActions.Provider value={dispatch}>
                {children}
            </UserActions.Provider>
        </UserDetails.Provider>
    )
}


function userReducer(state, action) {
    switch (action.type) {
        case 'login':
            return login(action.user);
        case 'logout':
            return logout();
        case 'reload':
            return reload();
        default:
            return state
    }
}
