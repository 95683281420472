import {Box, Button, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import {Formik} from "formik";
import React, {useContext, useEffect} from "react";
import {CBContext} from "./CBContext";
import {tokenValidationSchema} from "./validations";
import TextField from "../utils/components/TextField";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import API from "../utils/API";
import {ProgressContext} from "../utils/ProgressContext";
import APIError from "../utils/components/APIError";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {generalAPI, removeNulls} from "./utils";
import SaveIcon from "@mui/icons-material/Save";
import {getPatchValues} from "../utils/utils";


async function addTokenAPI(data, transactionId) {
    const response = await API.post(`cbs/${transactionId}/token-number/`, data);
    return response.data;
}

export default function TokenNumberForm() {
    const {cbState, cbDispatch} = useContext(CBContext);
    const {status} = cbState.baseData;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const isReading = status !== 2 || status === 7;
    const queryClient = useQueryClient();

    const mutation = useMutation(
        ['cbs', cbState.baseData.transactionId, 'token-number'],
        (data) => addTokenAPI(data, cbState.baseData.transactionId),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                cbDispatch({
                    type: 'addBaseData',
                    data: {status: 3, navigateOnSuccess: -2}
                });
                cbState.baseData.setActiveStep(4, cbState.baseData.activeStep)
                queryClient.invalidateQueries(['cbs', cbState.baseData.transactionId])
            }
        }
    );

    const {data: cbData, isFetching: cbIsFetching} = useQuery(
        ['cbs', cbState.baseData.transactionId],
        () => generalAPI(`cbs/${cbState.baseData.transactionId}/`),
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: (cbState.baseData.status >= 2) && !cbState.baseData.pages[cbState.baseData.activeStep].visited,
            select: data => removeNulls(data)
        }
    );

    useEffect(() => {
        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited && cbData && cbState.baseData.status !== 0 && !cbState.baseData.cloning) {
            cbDispatch({
                type: 'addUpdateFormData',
                data: {'token': cbData}
            })
        }

    }, [cbData, cbState.baseData.status, cbState.baseData.activeStep, cbState.baseData.pages, cbState.baseData.cloning, cbDispatch]);


    return <>
        <Paper sx={{
            p: 3,
            mx: 35
        }}>
            <Box sx={{my: 1, position: 'relative'}}>
                <Box>
                    <Typography variant={'h5'}>
                        Token Number
                    </Typography>
                </Box>
                {mutation.isError && <APIError error={mutation.error}/>}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '0',
                        left: '200px'
                    }}
                >
                    {cbIsFetching && <CircularProgress size={'2rem'}/>}
                </Box>
            </Box>
            <Formik
                initialValues={cbState.formData.token}
                validationSchema={tokenValidationSchema}
                validateOnChange={false}
                onSubmit={values => mutation.mutate(getPatchValues(cbState.updateData.token, values))}
                enableReinitialize
            >
                {
                    formik => (
                        <Grid container columnSpacing={5} rowSpacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    isReading={isReading}
                                    required={!isReading}
                                    disabled={isReading}
                                    name={'token_number'}
                                    label={'Token Number'}
                                    autoFocus
                                    apiError={mutation.error}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    startIcon={<SaveIcon/>}
                                    variant={'contained'}
                                    disabled={status !== 2}
                                    onClick={() => formik.submitForm()}
                                >
                                Save Token
                                </Button>
                            </Grid>
                        </Grid>

                    )
                }
            </Formik>
        </Paper>
        <Box sx={{textAlign: 'center', mt: 3}}>
            <Button
                startIcon={<NavigateBeforeIcon/>}
                variant={'outlined'}
                onClick={() => cbState.baseData.setActiveStep(2, cbState.baseData.activeStep)}
            >
                Previous
            </Button>
            <Button
                sx={{ml: 3}}
                disabled={status <= 2}
                startIcon={<NavigateNextIcon/>}
                variant={'contained'}
                onClick={() => cbState.baseData.setActiveStep(4, cbState.baseData.activeStep)}
            >
                Next
            </Button>
        </Box>
    </>
}