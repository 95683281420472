import {useField} from "formik";
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import {useFormikFieldError} from "../utils";

export default function RadioField(props) {
    const [field, meta] = useField(props);
    const {
        label,
        required,
        fullWidth = false,
        helperText = ' ',
        apiError,
        name,
        formControlSxProps,
        isReading,
        options,
        onChange=null,
        ...other
    } = props;

    const {error, textFieldHelperText} = useFormikFieldError(meta, name, apiError, helperText);

    const displayOptions = options ? options : [['Y', 'YES'], ['N', 'NO']]

    return (
        <FormControl
            error={error}
            fullWidth={fullWidth}
            required={required}
            variant="standard"
            sx={formControlSxProps}
            disabled={isReading}
            onChange={onChange !== null ? onChange : field.onChange}
        >
            <FormLabel sx={{fontSize: '0.85rem'}}>{label}</FormLabel>
            <RadioGroup
                {...other}
                {...field}
            >
                {
                    displayOptions.map(([key, value, radioProps]) => (
                        <FormControlLabel
                            key={key}
                            value={key}
                            control={<Radio size={"small"}/>}
                            {...radioProps}
                            label={value}
                        />
                    ))
                }
            </RadioGroup>
            <FormHelperText component={"div"}>
                {textFieldHelperText}
            </FormHelperText>
        </FormControl>
    )
}