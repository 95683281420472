import {alpha, Box, Button, Chip, Container, Tab, TableCell, Tabs, Toolbar} from "@mui/material";
import Link from "../utils/components/Link";
import DataTable from "../utils/components/DataTable";
import {useState} from "react";
import SearchTextField from "../utils/components/SearchTextField";
import {adminUser, nonOrganizationUser, organizationalUser, superAdminUser} from "../utils/constants/UserTypes";

function Header() {
    return (
        <>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>User Type</TableCell>
            <TableCell>Last Login</TableCell>
        </>
    )
}

function getUserType(userType) {
    switch (userType) {
        case organizationalUser:
            return "Organizational User"
        case nonOrganizationUser:
            return "Non-Organizational User"
        case adminUser:
            return "Admin User"
        case superAdminUser:
            return "Super Admin User"
        default:
            return <></>
    }
}

function Row({element}) {
    const status = element.is_active;
    let chip = <Chip label={'Active'} variant={'outlined'} color={'success'} sx={{backgroundColor: '#B2FFA8'}}/>
    if (!status) {
        chip = <Chip label={'Inactive'} color={'error'} variant={'outlined'} sx={{backgroundColor: '#FFDFDF'}}/>
    }
    return (
        <>
            <TableCell><Link underline={'none'} to={'./detail'}
                             state={{data: element}}>{element.name} </Link></TableCell>
            <TableCell>{element.email}</TableCell>
            <TableCell>{chip}</TableCell>
            <TableCell><Chip label={getUserType(element.user_type)}/></TableCell>
            <TableCell>{new Date(element.last_login).toLocaleString('en-IN')}</TableCell>
        </>
    )
}

const ORGANIZATIONAL_USERS = {'user_type__gte': 10}
const NON_ORGANIZATIONAL_USERS = {'user_type__lte': 1}


export default function UserList() {
    const [value, setValue] = useState(0);
    const [queryParams, setQueryParams] = useState(ORGANIZATIONAL_USERS);
    const [search, setSearch] = useState('');

    const tabsDesign = {
        color: 'primary.main',
        '&.Mui-selected': {
            backgroundColor: (theme) => `${alpha(theme.palette.primary.main, 0.08)}`,
        },
        '&.MuiButtonBase-root': {
            minHeight: '64px'
        }
    }

    function handleTabChange(e, newValue) {
        if (value !== newValue) {
            if (newValue === 0) {
                setValue(0);
                setQueryParams({...ORGANIZATIONAL_USERS, search: search});
                setSearch('')
            } else {
                setValue(1);
                setQueryParams({...NON_ORGANIZATIONAL_USERS, search: search});
                setSearch('')
            }
        }
    }

    function handleKeyDown(e) {
        if (e.key === "Enter") {
            setQueryParams({...queryParams, search: search})
        }
    }

    function handleChange(e) {
        setSearch(e.currentTarget.value);
    }

    return (
        <>
            <Toolbar sx={{justifyContent: 'space-between'}}>
                <Tabs value={value} onChange={handleTabChange}>
                    <Tab
                        label={"Organizational Users"}
                        sx={tabsDesign}
                        value={0}
                    />
                    <Tab
                        sx={tabsDesign}
                        label={"Non-organizational Users"}
                        value={1}
                    />
                </Tabs>
                <Button variant={'contained'} component={Link} to={'./create'}>Create User</Button>
            </Toolbar>
            <Box sx={{overflowY: 'auto', maxHeight: 'calc(100vh - 128px)'}}>
                <Container>
                    <SearchTextField
                        value={search}
                        handleChange={handleChange}
                        handleKeyDown={handleKeyDown}
                        placeholder={'Search by Name, Email'}
                    />
                    <DataTable
                        message={'Select the accountants name to view their details.'}
                        key={value}
                        header={<Header/>}
                        rowParser={(data) => <Row element={data}/>}
                        queryUrl={'accounts/users/'}
                        queryKey={['accounts', 'users']}
                        queryParams={queryParams}
                    />
                </Container>
            </Box>
        </>
    )
}