import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useRef, useState} from "react";
import {Alert, alpha, Box, Button, Container, Tab, Tabs, Toolbar} from "@mui/material";
import BackButton from "../utils/components/BackButton";
import TabPanel from "../utils/components/TabPanel";
import CBFileList from "./CBFileList";
import EditIcon from "@mui/icons-material/Edit";
import CBPreview from "./CBPreview";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import CBFileForm from "./CBFileForm";
import CAForm from "../caPartC/CAForm";
import {UserDetails} from "../utils/UserContext";
import DeleteIcon from "@mui/icons-material/Delete";
import API from "../utils/API";
import {useMutation} from "@tanstack/react-query";
import {ProgressContext} from "../utils/ProgressContext";
import {b64toBlob, downloadBlobFile, Modal, useConfirmation} from "../utils/utils";
import {adminUser} from "../utils/constants/UserTypes";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";

async function deleteCBAPI(id){
    const response = await API.delete(`/cbs/${id}/`);
    return response.data;
}

async function updateStatusAPI(transactionID) {
    const response = await API.post(`cbs/${transactionID}/check/`, {});
    return response.data;
}

async function pdfPreviewAPI(transactionID) {
    const response = await API.get(`cbs/${transactionID}/preview-pdf/`);
    return response.data
}

export default function CBDetailPage() {
    const [value, setValue] = useState(0);
    const [formType, setFormType] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const transaction = location.state?.transaction;
    const remitter = location.state?.remitter;
    const remittee = location.state?.remittee;
    const accountant = location.state?.accountant;
    const status = location.state?.status;
    const ca = location.state?.ca;
    const fileUploadFormRef = useRef();
    const user = useContext(UserDetails);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);

    const deleteCB = useMutation(
        ['cbs', transaction],
        () => deleteCBAPI(transaction),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate('/cb')
            }
        }
    );

    const checkCB = useMutation(
        ['cbs', transaction],
        () => updateStatusAPI(transaction),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate(-1)
            }
        }
    );

    const pdfMutation = useMutation(
        ['cbs', transaction, 'preview-pdf'],
        () => pdfPreviewAPI(transaction),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: data => {
                downloadBlobFile(`Preview.pdf`, b64toBlob(data.file))
            }
        }
    )

    function exportPDF() {
        pdfMutation.mutate()
    }

    const {open: deleteOpen, handleOpen: handleDeleteOpen, handleClose: handleDeleteClose} = useConfirmation(
        deleteCB.mutate,
    );


    function handleTabChange(e, newValue) {
        setValue(newValue);
    }

    const tabDesign = {
        position: 'absolute',
        left: '80px',
        top: 0
    }

    const tabsButtonDesign = {
        color: 'primary.main',
        height: '64px',
        '&.Mui-selected': {
            backgroundColor: (theme) => `${alpha(theme.palette.primary.main, 0.08)}`,
        }
    }

    return (
        <Box>
            <Modal
                open={deleteOpen}
                handleClose={handleDeleteClose}
                title={'Delete CB'}
                message={'Are you sure you want to delete the CB Transaction?\nThis will delete all the files, and any associated 15CA-PartC Transactions if created.\nThis action can not be un-done'}
            />
            <Toolbar>
                <BackButton to={-1}/>
                <Tabs value={value} onClick={() => setFormType('')} onChange={handleTabChange} sx={tabDesign}>
                    <Tab
                        sx={tabsButtonDesign}
                        label={"Preview"}
                        value={0}
                    />
                    <Tab
                        sx={tabsButtonDesign}
                        label={"Files"}
                        value={1}
                    />
                    <Tab
                        sx={tabsButtonDesign}
                        label={"15CA-PartC"}
                        value={2}
                    />
                </Tabs>
                <Box
                    sx={{
                        position: "absolute",
                        top: "12px",
                        right: "40px"
                    }}
                >
                    {
                        value === 0 && <>
                            {
                                status >= 2 ?
                                    <>
                                    </>
                                    : <Button
                                        startIcon={<CheckIcon/>}
                                        onClick={checkCB.mutate}
                                        variant={'outlined'}
                                    >
                                        Check
                                    </Button>
                            }
                            {
                                user.user_type >= adminUser && <>
                                    <Button
                                        sx={{ml: 3}}
                                        color={'error'}
                                        variant={'contained'}
                                        startIcon={<DeleteIcon/>}
                                        onClick={handleDeleteOpen}
                                    >
                                        Delete
                                    </Button>
                                </>
                            }
                            <Button startIcon={<DownloadIcon/>}
                                    sx={{mx: 3}}
                                    variant={'contained'}
                                    onClick={exportPDF}>
                                Preview
                            </Button>
                            <Button
                                startIcon={<EditIcon/>}
                                variant={'contained'}
                                onClick={() => navigate('/cb/form', {
                                    state: {
                                        remitter: remitter,
                                        remittee: remittee,
                                        accountant: accountant,
                                        status: status,
                                        transaction: transaction,
                                        navigateOnSuccess: -1
                                    }
                                })}
                            >
                                Edit
                            </Button>
                        </>
                    }
                    {
                        value === 1 && formType === '' && <Button
                            variant={'contained'}
                            startIcon={<FileUploadIcon/>}
                            onClick={() => setFormType('FILES')}
                        >
                            Upload Files
                        </Button>
                    }
                    {
                        value === 1 && formType === 'FILES' && <>
                            <Button
                                startIcon={<CloseIcon/>}
                                variant={'contained'}
                                onClick={() => {
                                    setFormType('');
                                    setValue(1);
                                }}
                            >
                                Discard
                            </Button>
                            <Button
                                sx={{
                                    ml: 3
                                }}
                                startIcon={<FileUploadIcon/>}
                                variant={'contained'}
                                onClick={() => fileUploadFormRef.current.submitForm()}
                            >
                                Upload
                            </Button>
                        </>
                    }
                </Box>
            </Toolbar>
            <Box
                sx={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 128px)'
                }}
            >
                <Container
                    sx={{
                        position: "relative",
                    }}
                >
                    <TabPanel value={value} index={0}>
                        <CBPreview transactionId={transaction}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {
                            formType === 'FILES' ?
                                <CBFileForm transactionId={transaction} ref={fileUploadFormRef} closeUpload={() => {
                                    setValue(1);
                                    setFormType('')
                                }}/> : <CBFileList ca={ca} transactionId={transaction}/>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {status < 4 ? <Box>
                                <Alert severity={'warning'} sx={{width: '600px', mx: 'auto'}}>
                                    15CA-PartC can be created only after the 15CB Transaction is acknowledged.
                                </Alert>
                            </Box> :
                            <>
                                {
                                    status === 7 ? <>
                                        <Alert severity={'error'}>
                                            Can not create/edit 15CA-PartC after the transaction is cancelled
                                        </Alert>
                                    </> : <><CAForm/></>
                                }
                            </>}
                    </TabPanel>
                </Container>
            </Box>
        </Box>
    )
}