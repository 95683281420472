import {Box, Button, Container, Toolbar, Typography} from "@mui/material";
import AccountantForm from "./AccountantForm";
import {adminUser} from "../utils/constants/UserTypes";
import withPermission from "../utils/components/PermissionRequired";
import BackButton from "../utils/components/BackButton";
import {useRef} from "react";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from "@mui/icons-material/Cancel";

function AccountantCreate() {
    const ref = useRef();
    return (
        <>
            <Toolbar>
                <BackButton to={-1}/>
                <Typography variant={"h5"} sx={{color: "primary.main"}}>Create Accountant</Typography>
                <Box
                    sx={{
                        position: 'absolute',
                        right: '40px'
                    }}
                >
                    <Button startIcon={<CancelIcon/>} variant={'contained'} onClick={() => ref.current.handleDiscardOpen()}>Discard</Button>
                    <Button startIcon={<AddIcon/>} sx={{ml: 3}} onClick={() => ref.current.handleSaveOpen()} variant={'contained'}>Create</Button>
                </Box>
            </Toolbar>
            <Box
                sx={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 128px)'
                }}
            >
                <Container
                    sx={{
                        position: "relative",
                    }}
                >
                    <AccountantForm ref={ref}/>
                </Container>
            </Box>
        </>
    )
}

export default withPermission(AccountantCreate, adminUser);
