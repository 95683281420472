import {useContext} from "react";
import {CBContext} from "./CBContext";
import {Box, Step, StepButton, StepLabel, Stepper} from "@mui/material";

export function getSteps(status) {

    return [
        {
            name: 'Entity Details',
            isChecked: status >= 1,
        },
        {
            name: 'Invoice Details',
            isChecked: status >= 1,
        },
        {
            name: 'Remittance',
            isChecked: status >= 1,
        },
        {
            name: 'Token Details',
            isChecked: status >= 3,
            isDisabled: (status <= 1)
        },
        {
            name: 'Acknowledgement Details',
            isChecked: status >= 4,
            isDisabled: (status <= 2)
        },
        {
            name: 'UDIN Details',
            isChecked: status >= 5,
            isDisabled: (status <= 3)
        }
    ];
}

function getCheckStatus(step, index, activeStep, status) {
    if (status !== 0) {
        return step.isChecked && activeStep !== index
    }
    return undefined
}

export default function CBFormStepper() {

    const {cbState} = useContext(CBContext);
    const {status, activeStep, setActiveStep} = cbState.baseData;
    const steps = getSteps(status)

    return (
        <Box sx={{mt: 1}}>
            <Stepper nonLinear={status > 1} sx={{mb: 2}} activeStep={activeStep}>
                {steps.map((step, index) => (
                    <Step
                        disabled={step.isDisabled}
                        key={step.name}
                        completed={getCheckStatus(step, index, activeStep, status)}
                    >
                        <StepButton disabled={status <= 1} onClick={() => setActiveStep(index, activeStep)}>
                            <StepLabel
                                sx={{
                                    '.MuiStepLabel-label': {
                                        fontWeight: '600',
                                        fontSize: '1rem',
                                    },
                                    '.MuiStepLabel-label.Mui-active': {
                                        color: 'primary.main',
                                        fontWeight: '600',
                                    },
                                    '.MuiStepLabel-label.Mui-completed': {
                                        fontWeight: '600',
                                        color: 'success.main'
                                    },
                                }}
                                StepIconProps={{
                                    sx: {
                                        '&.Mui-completed': {
                                            color: 'success.main'
                                        }
                                    }
                                }}
                            >
                                {step.name}
                            </StepLabel>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}


export function getActiveStep(status) {
    switch (status) {
        case 0:
            return 0;
        case 1:
            return 0;
        case 2:
            return 3;
        case 3:
            return 4;
        case 4:
            return 5
        case 5:
            return 5
        default:
            return 0
    }
}