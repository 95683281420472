import {useContext} from "react";
import {CAContext} from "./CAContext";
import Entity from "./Entity";
import AODetailForm from "./AODetailForm";
import AcknowledgementForm from "./AcknowledgementForm";

export default function CADetailForm(){
    const {caState} = useContext(CAContext);
    switch (caState.baseData.activeStep){
        case 0:
            return <Entity/>
        case 1:
            return <AODetailForm/>
        case 2:
            return <AcknowledgementForm/>
        default:
            return <></>
    }
}