import {Alert, alpha, Box, Button, IconButton, Tab, TableCell, Tabs, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import {downloadBlobFile, downloadHelper, getSize, parseDateTime, showDownloadProgress} from "../utils/utils";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import API from "../utils/API";
import {LabeledProgressContext} from "../utils/LabeledProgressContext";
import {useMutation} from "@tanstack/react-query";
import SearchTextField from "../utils/components/SearchTextField";
import DataTable from "../utils/components/DataTable";
import DownloadIcon from "@mui/icons-material/Download";
import TabPanel from "../utils/components/TabPanel";

function Header() {
    return (
        <>
            <TableCell>File Name</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Actions</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
        </>
    )
}

function Row({element, handleDownload}) {
    return (
        <>
            <TableCell>{element.file_name}</TableCell>
            <TableCell>{getSize(element.size)}</TableCell>
            <TableCell>{element.file_type}</TableCell>
            <TableCell>
                <IconButton onClick={() => handleDownload({
                    id: element.id,
                    name: element.file_name
                })}>
                    <FileDownloadIcon color={'primary'}/>
                </IconButton>
            </TableCell>
            <TableCell>{element.creator}</TableCell>
            <TableCell>{element.updator}</TableCell>
            <TableCell>{parseDateTime(element.created_at)}</TableCell>
            <TableCell>{parseDateTime(element.updated_at)}</TableCell>
        </>
    )
}

function InvoiceHeader() {
    return (
        <>
            <TableCell>File Name</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Actions</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
        </>
    )
}

function InvoiceRow({element, handleDownload}) {
    return (
        <>
            <TableCell>{element.invoice_file_name}</TableCell>
            <TableCell>{getSize(element.invoice_file_size)}</TableCell>
            <TableCell>
                <IconButton onClick={() => handleDownload({
                    id: element.id,
                    name: element.invoice_file_name
                })}>
                    <FileDownloadIcon color={'primary'}/>
                </IconButton>
            </TableCell>
            <TableCell>{element.creator}</TableCell>
            <TableCell>{element.updator}</TableCell>
            <TableCell>{parseDateTime(element.created_at)}</TableCell>
            <TableCell>{parseDateTime(element.updated_at)}</TableCell>
        </>
    )
}


function downloadCBFileAPI(data, setProgress, handleLabeledProgressClose) {
    return downloadHelper(`cbs/files/${data.id}/download/`, {'file_name': data.name}, setProgress, handleLabeledProgressClose)
}

function downloadInvoiceFileAPI(data, setProgress, handleLabeledProgressClose) {
    return downloadHelper(`cbs/invoices/${data.id}/download/`, {'file_name': data.name}, setProgress, handleLabeledProgressClose)
}


function downloadBulkInvoicesFileAPI(data, setProgress, handleLabeledProgressClose){
    return downloadHelper(`cbs/${data.id}/download-invoices/`, {}, setProgress, handleLabeledProgressClose)
}


function downloadBulkCBFileAPI(data, setProgress, handleLabeledProgressClose){
    return downloadHelper(`cbs/${data.id}/download-files/`, {}, setProgress, handleLabeledProgressClose)
}

function downloadCAPartCFileAPI(data, setProgress, handleLabeledProgressClose) {
    return downloadHelper(`cbs/cas/files/${data.id}/download/`, {'file_name': data.name}, setProgress, handleLabeledProgressClose)
}

function downloadBulkCAPartCFileAPI(data, setProgress, handleLabeledProgressClose){
    return downloadHelper(`cbs/cas/${data.id}/download-files/`, {}, setProgress, handleLabeledProgressClose)
}


export default function CBFileList(props){
    const [searchCB, setSearchCB] = useState('');
    const [searchCA, setSearchCA] = useState('');
    const [queryParamsCB, setQueryParamsCB] = useState({search: '', cb_id: props.transactionId});
    const [queryParamsCA, setQueryParamsCA] = useState({search: '', ca_id: props.ca});
    const {handleLabeledProgressOpen, handleLabeledProgressClose, setProgressValue} = useContext(LabeledProgressContext)
    const [page, setPage] = useState(0)

    const downloadSingleCBFile = useMutation(
        ['cbs', 'files', 'download'],
        (data) => downloadCBFileAPI(data, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => handleLabeledProgressOpen(),
            onSuccess: (data, variables, context) => {
                downloadBlobFile(variables.name, data);
            }
        }
    );

    const downloadMultipleCBFiles = useMutation(
        ['cbs', props.transactionId, 'download-invoices'],
        () => downloadBulkCBFileAPI({id: props.transactionId}, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => handleLabeledProgressOpen(),
            onSuccess: (data, variables, context) => {
                downloadBlobFile(`Transaction - ${props.transactionId} - Files.zip`, data);
            }
        }
    )

    const downloadSingleCAPartCFile = useMutation(
        ['cbs', 'cas', 'files', 'download'],
        (data) => downloadCAPartCFileAPI(data, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => handleLabeledProgressOpen(),
            onSuccess: (data, variables, context) => {
                downloadBlobFile(variables.name, data);
            }
        }
    );

    const downloadMultipleCAPartCFiles = useMutation(
        ['cbs', 'cas', props.ca, 'download-invoices'],
        () => downloadBulkCAPartCFileAPI({id: props.ca}, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => handleLabeledProgressOpen(),
            onSuccess: (data, variables, context) => {
                downloadBlobFile(`Transaction - ${props.transactionId} - 15CA-PartC - Files.zip`, data);
            }
        }
    )

    const downloadSingleInvoiceFile = useMutation(
        ['cbs', 'invoices', 'download'],
        (data) => downloadInvoiceFileAPI(data, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => handleLabeledProgressOpen(),
            onSuccess: (data, variables, context) => {
                downloadBlobFile(variables.name, data);
            }
        }
    )

    const downloadMultipleInvoice = useMutation(
        ['cbs', props.transactionId, 'invoices-download'],
        () => downloadBulkInvoicesFileAPI({id: props.transactionId}, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => handleLabeledProgressOpen(),
            onSuccess: (data, variables, context) => {
                downloadBlobFile(`Transaction - ${props.transactionId} - Invoices.zip`, data);
            }
        }
    )

    function handleKeyDownCB(e) {
        if (e.key === "Enter") {
            setQueryParamsCB({...queryParamsCB, search: searchCB})
        }
    }

    function handleChangeCB(e) {
        setSearchCB(e.currentTarget.value);
    }

    function handleKeyDownCA(e) {
        if (e.key === "Enter") {
            setQueryParamsCA({...queryParamsCA, search: searchCA})
        }
    }

    function handleChangeCA(e) {
        setSearchCA(e.currentTarget.value);
    }

    const tabsButtonDesign = {
        color: 'primary.main',
        height: '64px',
        '&.Mui-selected': {
            backgroundColor: (theme) => `${alpha(theme.palette.primary.main, 0.08)}`,
        }
    }

    function handlePageChange(e, newValue){
        setPage(newValue);
    }

    return (
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <Box>
                <Typography variant={'h6'}>Invoice Files</Typography>
                <DataTable
                    headerButton={<Button variant={'outlined'} startIcon={<DownloadIcon/>} onClick={downloadMultipleInvoice.mutate}>Download Invoices</Button>}
                    message={'Invoice Files associated with current Transaction'}
                    header={<InvoiceHeader/>}
                    rowParser={(data) => <InvoiceRow element={data} handleDownload={downloadSingleInvoiceFile.mutate}/>}
                    queryUrl={'cbs/invoices/'}
                    queryKey={['cbs', 'files']}
                    paginated={false}
                    queryParams={{cb_id: props.transactionId}}
                />
            </Box>
            <Box sx={{my: 3}}>
                <Tabs value={page} onChange={handlePageChange}>
                    <Tab
                        sx={tabsButtonDesign}
                        label={"15CB Files"}
                        value={0}
                    />
                    <Tab
                        sx={tabsButtonDesign}
                        label={"15CA-PartC Files"}
                        value={1}
                    />
                </Tabs>
            </Box>
            <TabPanel index={0} value={page}>
                <Box sx={{my: 5}}>
                    <Typography variant={'h6'}>15CB Files</Typography>
                    <SearchTextField
                        value={searchCB}
                        handleChange={handleChangeCB}
                        handleKeyDown={handleKeyDownCB}
                        placeholder={'Search by File Name, File Type'}
                    />
                    <DataTable
                        headerButton={<Button variant={'outlined'} startIcon={<DownloadIcon/>} onClick={downloadMultipleCBFiles.mutate}>Download Files</Button>}
                        message={'Other Files associated with current Transaction'}
                        header={<Header/>}
                        rowParser={(data) => <Row element={data} handleDownload={downloadSingleCBFile.mutate}/>}
                        queryUrl={'cbs/files/'}
                        queryKey={['cbs', 'files']}
                        queryParams={queryParamsCB}
                    />
                </Box>
            </TabPanel>
            <TabPanel index={1} value={page}>
                {
                    props.ca ? <>
                        <Typography variant={'h6'}>15CA-PartC Files</Typography>
                        <SearchTextField
                            value={searchCA}
                            handleChange={handleChangeCA}
                            handleKeyDown={handleKeyDownCA}
                            placeholder={'Search by File Name, File Type'}
                        />
                        <DataTable
                            headerButton={<Button variant={'outlined'} startIcon={<DownloadIcon/>} onClick={downloadMultipleCAPartCFiles.mutate}>Download Files</Button>}
                            message={'Other Files associated with current Transaction'}
                            header={<Header/>}
                            rowParser={(data) => <Row element={data} handleDownload={downloadSingleCAPartCFile.mutate}/>}
                            queryUrl={'cbs/cas/files'}
                            queryKey={['cbs', 'cas', 'files']}
                            queryParams={queryParamsCA}
                        />
                    </> : <>
                        <Alert severity={'warning'}>
                            15CA-PartC can be created only after the 15CB Transaction is acknowledged.
                        </Alert>
                    </>
                }
            </TabPanel>
        </Box>
    )

}
