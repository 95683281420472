import {useContext, useEffect} from "react";
import {CBContext} from "./CBContext";
import {useMutation, useQuery} from "@tanstack/react-query";
import {generalAPI} from "./utils";
import {Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import RadioField from "../utils/components/RadioField";
import Mapping from "../utils/UtilityMapping.json";
import TextField from "../utils/components/TextField";
import {Formik} from "formik";
import {remitteeValidationSchema} from "./validations";
import API from "../utils/API";
import {ProgressContext} from "../utils/ProgressContext";
import {getPatchValues} from "../utils/utils";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import APIError from "../utils/components/APIError";


async function remitteeUpdateAPI(data, id){
    const response = await API.patch(`cbs/remittees/${id}/`, data);
    return response.data;
}

export default function RemitteeForm() {
    const {cbState, cbDispatch} = useContext(CBContext);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);

    const remitteeRef = cbState.baseData.remitteeRef;

    const {data: templateRemitteeData, isFetching: templateRemitteeIsFetching,isError: templateRemitteeIsError, error: templateRemitteeError} = useQuery(
        ['remittees', cbState.baseData.remitteeId],
        () => generalAPI(`/remittees/${cbState.baseData.remitteeId}/`),
        {
            refetchOnWindowFocus: false,
            enabled: cbState.baseData.status === 0  && cbState.baseData.cloning === false && !cbState.baseData.pages[cbState.baseData.activeStep].visited
        }
    );

    const {data: remitteeData, isFetching: remitteeIsFetching, refetch: remitteeRefetch, isError: remitteeIsError, error: remitteeError} = useQuery(
        ['cbs', 'remittees', cbState.baseData.remitteeId],
        () => generalAPI(`cbs/remittees/${cbState.baseData.remitteeId}/`),
        {
            refetchOnWindowFocus: false,
            enabled: (cbState.baseData.status !== 0 || cbState.baseData.cloning === true) && !cbState.baseData.pages[cbState.baseData.activeStep].visited
        }
    );

    const updateRemittee = useMutation(
        ['cbs', 'remitters', cbState.baseData.remitterId],
        (data) => remitteeUpdateAPI(data, cbState.baseData.remitterId),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                remitteeRefetch();
            }
        }
    )


    useEffect(() => {
        function initializeForm(data, update=false) {
            cbDispatch({
                type: update ? 'addUpdateFormData' : 'addFormData',
                data: {'remittee': data}
            })
        }

        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited) {
            if ((cbState.baseData.cloning || cbState.baseData.status !== 0) && (remitteeData)) {
                initializeForm(remitteeData, true)
            } else if (templateRemitteeData) {
                initializeForm(templateRemitteeData)
            }
        }


    }, [remitteeData, templateRemitteeData, cbState.baseData.status, cbState.baseData.cloning, cbDispatch, cbState.baseData.pages, cbState.baseData.activeStep]);

    function handleUpdateRemittee(){
        let remittee = remitteeRef.current.validateForm();
        remittee.then(res => {
            if (Object.keys(res).length === 0){
                updateRemittee.mutate(getPatchValues(cbState.updateData.remittee, remitteeRef.current.values))
            }
        })
    }

    const isReading = cbState.baseData.status >= 2;
    const isError = templateRemitteeIsError || remitteeIsError
    const error = templateRemitteeError || remitteeError

    if (isError){
        return <>
            <Box sx={{mx: 25}}>
                <APIError error={error}/>
            </Box>
        </>
    }

    return (
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '-72px',
                    right: '125px'
                }}
            >
                {(templateRemitteeIsFetching || remitteeIsFetching) && <CircularProgress size={'2rem'}/>}
            </Box>
            {
                cbState.baseData.status === 1 && <Box
                    sx={{
                        position: 'absolute',
                        top: '-40px',
                        right: 0
                    }}
                >
                    <Button startIcon={<CancelIcon/>} onClick={() => remitteeRef.current.resetForm({values: cbState.updateData.remittee})}>Discard</Button>
                    <Button sx={{ml: 3}} startIcon={<SaveIcon/>} onClick={handleUpdateRemittee}>Save</Button>
                </Box>
            }
            <Formik
                innerRef={remitteeRef}
                enableReinitialize
                initialValues={cbState.formData.remittee}
                validationSchema={remitteeValidationSchema}
                validateOnChange={false}
            >
                {formik => (
                    <>
                        <Grid container mt={2} sx={{alignItems: 'stretch'}}>
                            {
                                updateRemittee.isError && <Grid item xs={12}>
                                    <Box sx={{mx: 10}}>
                                        <APIError error={updateRemittee.error}/>
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={6} sx={{pr: 2}}>
                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                    Primary Details
                                </Typography>
                                <Grid container columnSpacing={2} rowSpacing={4}>
                                    <Grid item xs={6}>
                                        <RadioField
                                            row
                                            required
                                            fullWidth
                                            isReading={isReading}
                                            label={'Honorific'}
                                            name={'honorific'}
                                            options={Object.entries(Mapping.remittee.beneficiaryHonorific).filter(([key, value]) => key !== '-1')}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            upperCase
                                            required
                                            isReading
                                            name={'name'}
                                            label={'Name'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required
                                            isReading={isReading}
                                            name={'country_of_remittance'}
                                            label={'Country of Remittance'}
                                            options={Mapping.remittee.countryRemMadeSecb}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            isReading={isReading}
                                            disabled={formik.values.country_of_remittance !== '9999'}
                                            required={formik.values.country_of_remittance === '9999'}
                                            name={'country_of_remittance_other'}
                                            label={'Country of Remittance (Other)'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required
                                            isReading={isReading}
                                            name={'currency_of_remittance'}
                                            label={'Currency of Remittance'}
                                            options={Mapping.remittee.currencySecbCode}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            upperCase
                                            isReading={isReading}
                                            disabled={formik.values.currency_of_remittance !== '99'}
                                            required={formik.values.currency_of_remittance === '99'}
                                            name={'currency_of_remittance_other'}
                                            label={'Currency of Remittance (Other)'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required
                                            isReading={isReading}
                                            name={'nature_of_remittance'}
                                            label={'Nature of Remittance'}
                                            options={Mapping.remittee.natureRemCategory}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            upperCase
                                            isReading={isReading}
                                            disabled={formik.values.nature_of_remittance !== '16.99'}
                                            required={formik.values.nature_of_remittance === '16.99'}
                                            name={'nature_of_remittance_other'}
                                            label={'Nature of Remittance (Other)'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required
                                            isReading={isReading}
                                            name={'relevant_purpose_code'}
                                            label={'Relevant Purpose Code'}
                                            options={Mapping.remittee.revPurCategory}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required
                                            isReading={isReading}
                                            name={'relevant_purpose_code_detail'}
                                            label={'Relevant Purpose Category'}
                                            options={Mapping.remittee.revPurCode[formik.values.relevant_purpose_code ? formik.values.relevant_purpose_code : '-1']}

                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}
                                  sx={{
                                      borderLeft: "1px solid",
                                      borderColor: "rgba(0, 0, 0, 0.10)",
                                      pl: 2
                                  }}
                            >
                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                    Address Details
                                </Typography>
                                <Grid container columnSpacing={2} rowSpacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading={isReading}
                                            name={'flat_door_building'}
                                            label={'Flat/Door/Building'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            isReading={isReading}
                                            name={'premise_building_village'}
                                            label={'Premise/Building/Village'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            isReading={isReading}
                                            name={'road_street'}
                                            label={'Road/Street'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading={isReading}
                                            name={'area_locality'}
                                            label={'Area/Locality'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading={isReading}
                                            name={'town_city_district'}
                                            label={'Town/City/District'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading={isReading}
                                            name={'state'}
                                            label={'State'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required
                                            isReading={isReading}
                                            name={'country'}
                                            label={'Country'}
                                            apiError={updateRemittee.error}
                                            options={Mapping.remitter.country}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading={isReading}
                                            name={'zipcode'}
                                            label={'Zipcode'}
                                            apiError={updateRemittee.error}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioField
                                    fullWidth
                                    required={!isReading}
                                    isReading={isReading}
                                    name={'is_ca_applicable'}
                                    label={'Is 15CA Applicable?'}
                                    helperText={''}
                                    row
                                    apiError={updateRemittee.error}
                                />
                            </Grid>

                            {
                                formik.values.is_ca_applicable === 'Y' && (
                                    <Grid container item xs={12} mt={2}>
                                        <Grid item xs={6} sx={{pr: 2}}>
                                            <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                Preliminary Details
                                            </Typography>
                                            <Grid container columnSpacing={2} rowSpacing={4}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        upperCase
                                                        isReading={isReading}
                                                        name={'pan'}
                                                        label={'PAN'}
                                                        apiError={updateRemittee.error}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        isReading={isReading}
                                                        name={'status'}
                                                        label={'Status'}
                                                        options={Mapping.remittee.status}
                                                        apiError={updateRemittee.error}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        upperCase
                                                        required={!isReading}
                                                        isReading={isReading}
                                                        name={'principal_place_of_business'}
                                                        label={'Principal Place of Business'}
                                                        apiError={updateRemittee.error}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}
                                              sx={{
                                                  borderLeft: "1px solid",
                                                  borderColor: "rgba(0, 0, 0, 0.10)",
                                                  pl: 2
                                              }}>
                                            <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                Contact Details
                                            </Typography>
                                            <Grid container columnSpacing={2} rowSpacing={4}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        isReading={isReading}
                                                        name={'email'}
                                                        label={'Email'}
                                                        apiError={updateRemittee.error}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        isReading={isReading}
                                                        name={'phone'}
                                                        label={'Phone'}
                                                        apiError={updateRemittee.error}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </>
                )}
            </Formik>
        </Box>
    )
}