import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import Accounts from "./accounts/Accounts";
import Login from "./accounts/Login";
import LoginOtp from "./accounts/LoginOtp";
import ForgotPassword from "./accounts/ForgotPassword";
import {uidTokenChecker} from "./accounts/utils";
import SetPassword from "./accounts/SetPassword";
import LinkExpired from "./accounts/LinkExpired";
import Users from "./users/Users";
import UserList from "./users/UserList";
import Accountants from "./accountants/Accountants";
import AccountantList from "./accountants/AccountantList";
import UserDetail from "./users/UserDetail";
import UserCreate from "./users/UserCreate";
import AccountantCreate from "./accountants/AccountantCreate";
import AccountantDetail from "./accountants/AccountantDetail";
import Remitters from "./remitters/Remitters";
import RemitterList from "./remitters/RemitterList";
import RemitterCreate from "./remitters/RemitterCreate";
import RemitterDetail from "./remitters/RemitterDetail";
import Remittees from "./remittees/Remittees";
import RemitteeDetail from "./remittees/RemitteeDetail";
import CBForm from "./cbs/CBForm";
import CBDetailPage from "./cbs/CBDetailPage";
import CB from "./cbs/CB";
import CBBase from "./cbs/CBBase";
import IPAddress from "./ipaddress/IPAddress";
import Home from "./Home";
import Page404 from "./utils/components/Page404";
import RateCards from "./rateCards/RateCards";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            {
                path: '',
                element: <Home/>,
                index: true
            },
            {
                path: 'rate-cards',
                element: <RateCards/>
            },
            {
                path: 'users',
                element: <Users/>,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <UserList/>
                    },
                    {
                        path: 'detail',
                        element: <UserDetail/>
                    },
                    {
                        path: 'create',
                        element: <UserCreate/>
                    }
                ]
            },
            {
                path: 'ipaddress',
                element: <IPAddress/>
            },
            {
                path: 'accountants',
                element: <Accountants/>,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <AccountantList/>
                    },
                    {
                        path: 'create',
                        element: <AccountantCreate/>
                    },
                    {
                        path: 'detail',
                        element: <AccountantDetail/>
                    }
                ]
            },
            {
                path: 'remitters',
                element: <Remitters/>,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <RemitterList/>
                    },
                    {
                        path: 'create',
                        element: <RemitterCreate/>
                    },
                    {
                        path: 'detail',
                        element: <RemitterDetail/>
                    },
                ]
            },
            {
                path: 'remitters/remittees',
                element: <Remittees/>,
                children: [
                    {
                        path: 'detail',
                        element: <RemitteeDetail/>
                    }
                ]
            },
            {
                path: 'cb',
                element: <CB/>,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <CBBase/>
                    },
                    {
                        path: 'form',
                        element: <CBForm/>
                    },
                    {
                        path: 'detail',
                        element: <CBDetailPage/>
                    },
                ]
            },
            {
                path: 'accounts',
                element: <Accounts/>,
                children: [
                    {
                        path: 'login',
                        element: <Login/>
                    },
                    {
                        path: 'login-otp',
                        element: <LoginOtp/>
                    },
                    {
                        path: 'forgot-password',
                        element: <ForgotPassword/>
                    },
                    {
                        path: "password-reset/:uid/:token",
                        element: <SetPassword/>,
                        loader: uidTokenChecker
                    },
                    {
                        path: "activate/:uid/:token",
                        element: <SetPassword/>,
                        loader: uidTokenChecker
                    },
                    {
                        path: "update-password",
                        element: <SetPassword/>
                    }
                ]
            },
            {
                path: "accounts/link-expired",
                element: <LinkExpired/>
            },
        ],
        errorElement: <Page404 />
    },
]);