import {Box, Chip, TableCell} from "@mui/material";
import Link from "../utils/components/Link";
import Mapping from "../utils/UtilityMapping.json";
import {parseDateTime, StatusChip} from "../utils/utils";
import {useState} from "react";
import SearchTextField from "../utils/components/SearchTextField";
import DataTable from "../utils/components/DataTable";
import withPermission from "../utils/components/PermissionRequired";
import {organizationalUser} from "../utils/constants/UserTypes";

function Header() {
    return (
        <>
            <TableCell>Name</TableCell>
            <TableCell>Nature of Remittance</TableCell>
            <TableCell>Country of Remittance</TableCell>
            <TableCell>15CA</TableCell>
            <TableCell>Usage Status</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
        </>
    )
}

function Row({element, remitter}) {
    const is_ca_applicable = element.is_ca_applicable;
    let chip = <Chip label={'Applicable'} variant={'outlined'} color={'success'} sx={{backgroundColor: '#B2FFA8'}}/>
    if (is_ca_applicable === 'N') {
        chip = <Chip label={'Not-Applicable'} color={'error'} variant={'outlined'} sx={{backgroundColor: '#FFDFDF'}}/>
    }

    return (
        <>
            <TableCell><Link underline={'none'} to={'/remitters/remittees/detail'}
                             state={{remittee: element, remitter: remitter}}>{element.name} </Link></TableCell>
            <TableCell>{Mapping.remittee.natureRemCategory[element.nature_of_remittance]}</TableCell>
            <TableCell>{Mapping.remittee.countryRemMadeSecb[element.country_of_remittance]}</TableCell>
            <TableCell>{chip}</TableCell>
            <TableCell><StatusChip status={element.usage_status}/></TableCell>
            <TableCell>{element.creator}</TableCell>
            <TableCell>{element.updator}</TableCell>
            <TableCell>{parseDateTime(element.created_at)}</TableCell>
            <TableCell>{parseDateTime(element.updated_at)}</TableCell>
        </>
    )
}

function RemitteeList(props) {

    const [search, setSearch] = useState('');
    const [queryParams, setQueryParams] = useState({search: '', remitter_id: props.remitter.id});

    function handleKeyDown(e) {
        if (e.key === "Enter") {
            setQueryParams({...queryParams, search: search})
        }
    }

    function handleChange(e) {
        setSearch(e.currentTarget.value);
    }

    return (
        <Box>
            <SearchTextField
                value={search}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                placeholder={'Search by Name'}
            />
            <DataTable
                message={'Select the remittee name to view their details.'}
                header={<Header/>}
                rowParser={(data) => <Row element={data} remitter={props.remitter}/>}
                queryUrl={'remittees/'}
                queryKey={['remittees']}
                queryParams={queryParams}
            />
        </Box>
    )
}

export default withPermission(RemitteeList, organizationalUser);

