import {Alert, Box, Button, Typography} from "@mui/material";
import API from "../utils/API";
import * as Yup from "yup";
import {useMutation} from "@tanstack/react-query";
import {useContext} from "react";
import {ProgressContext} from "../utils/ProgressContext";
import {Form, Formik} from "formik";
import APIError from "../utils/components/APIError";
import TextField from "../utils/components/TextField";
import BackButton from "../utils/components/BackButton";

const initialValues = {email: ""};
const validationSchema = Yup.object().shape({
    // eslint-disable-next-line
    email: Yup.string().email("Enter a valid Email ID").required("Email ID is required"),
});

async function initiatePasswordResetAPI(data) {
    const response = await API.post('accounts/users/initiate-password-reset/', data);
    return response.data;
}

function ForgotPasswordForm() {
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const mutation = useMutation(
        ['accounts', 'users', 'initiate-password-reset'],
        initiatePasswordResetAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose()
        }
    )

    if (mutation.isSuccess) {
        return (
            <Alert severity={"success"} sx={{mt: 5, alignItems: 'center'}}>
                A Reset Password link with further instructions is sent to you
                on the registered Email ID. Kindly note, the link expires in 3 hours
            </Alert>
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={mutation.mutate}
        >
            <Form>
                {mutation.isError && <Box sx={{mt: 5}}>
                    <APIError error={mutation.error}/>
                </Box>}
                <BackButton to={-1} sx={{top: "36px", left: "36px"}}/>
                <TextField
                    required
                    fullWidth
                    lowerCase
                    type={'email'}
                    name={"email"}
                    label={"Email ID"}
                    sx={{
                        mt: 5
                    }}
                    autoFocus
                    apiError={mutation.error}
                />
                <Button sx={{mt: 4}} type={"submit"} variant={"contained"}>Submit</Button>
            </Form>
        </Formik>
    )
}

export default function ForgotPassword() {
    return (
        <Box sx={{textAlign: "center"}}>
            <Typography variant={"h3"}>Forgot Password ?</Typography>
            <Typography mt={3} variant={"helpText"}>
                A link will be sent to your mail to set a new password.
            </Typography>
            <ForgotPasswordForm/>
        </Box>
    )
}