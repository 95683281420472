import React, {useContext, useRef} from "react";
import {CBContext} from "./CBContext";
import {ProgressContext} from "../utils/ProgressContext";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import API from "../utils/API";
import {Box, Button, Tooltip} from "@mui/material";
import {FormModal, getPatchValues} from "../utils/utils";
import {Form, Formik} from "formik";
import {updateStatusSchema} from "./validations";
import TextField from "../utils/components/TextField";
import InfoIcon from '@mui/icons-material/Info';

async function updateStatusAPI(transactionID, data) {
    const response = await API.post(`cbs/${transactionID}/update-status/`, data);
    return response.data;
}


let statusMapping = {
    "-1": <>
        SELECT A STATUS
    </>,
    1: <Box display={'flex'} alignItems={'center'} gap={2}>
        IN-PROGRESS
        <Tooltip
            title={'Checking, Token Details, Acknowledgement Details, Udin details, Udin status, Note on Certification and Mail Sent need to be filled again'}
            placement={'right'}>
            <InfoIcon sx={{color: 'primary.main'}}/>
        </Tooltip>
    </Box>,
    2: <Box display={'flex'} alignItems={'center'} gap={2}>
        CHECKED
        <Tooltip
            title={'Token Details, Acknowledgement Details, Udin details, Udin status, Note on Certification and Mail Sent need to be filled again'}
            placement={'right'}>
            <InfoIcon sx={{color: 'primary.main'}}/>
        </Tooltip>
    </Box>,
    3: <Box display={'flex'} alignItems={'center'} gap={2}>
        TOKEN ATTACHED
        <Tooltip
            title={'Acknowledgement Details, Udin details, Udin status, Note on Certification and Mail Sent need to be filled again'}
            placement={'right'}>
            <InfoIcon sx={{color: 'primary.main'}}/>
        </Tooltip>
    </Box>,
    4: <Box display={'flex'} alignItems={'center'} gap={2}>
        ACKNOWLEDGED
        <Tooltip
            title={'Udin details, Udin status and Mail Sent need to be filled again'}
            placement={'right'}>
            <InfoIcon sx={{color: 'primary.main'}}/>
        </Tooltip>
    </Box>,
}


export default function UpdateStatus() {
    const {cbState, cbDispatch} = useContext(CBContext);
    const {modalType, transactionId, status} = cbState.baseData;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const formikRef = useRef();
    const queryClient = useQueryClient();

    const mutation = useMutation(
        ['cbs', transactionId, 'updateStatus'],
        data => updateStatusAPI(transactionId, data),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: data => {
                cbDispatch({
                    type: 'addBaseData',
                    data: {modalType: '', status: data['status'], navigateOnSuccess: -2}
                });
                queryClient.invalidateQueries(['cbs', cbState.baseData.transactionId])
            }
        }
    )

    const footer = <>
        <Button variant={'outlined'} onClick={() => cbDispatch({
            type: 'addBaseData',
            data: {modalType: ''}
        })}>Close</Button>
        <Button sx={{ml: 3}} disabled={status < 1} onClick={() => formikRef.current.submitForm()}
                variant={'contained'}>Save</Button>
    </>;

    const cbStatusMapping = Object.keys(statusMapping).filter(key => key < cbState.baseData.status).reduce((newObj, key) => {
            newObj[key] = statusMapping[key]
            return newObj
        }, {}
    )

    return (
        <>
            <FormModal
                open={modalType === 'updateStatus'}
                title={'Update Status'}
                maxWidth={'xl'}
                width={'500px'}
                footer={footer}
            >
                <Formik
                    innerRef={formikRef}
                    initialValues={{status: '-1'}}
                    validationSchema={updateStatusSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                    onSubmit={values => {
                        let finalValues = getPatchValues(cbState.updateData.cancel, values)
                        mutation.mutate(finalValues)
                    }}
                >
                    <Form>
                        <TextField
                            name={'status'}
                            label={'Update Status To'}
                            required
                            fullWidth
                            disabled={status < 2}
                            select
                            options={cbStatusMapping}
                        />
                    </Form>
                </Formik>
            </FormModal>
        </>
    )

}