import API from "../utils/API";
import React, {useContext, useEffect} from "react";
import {ProgressContext} from "../utils/ProgressContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CAContext} from "./CAContext";
import {generalAPI, makeNulls} from "../cbs/utils";
import {Box, Button, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import APIError from "../utils/components/APIError";
import {Formik} from "formik";
import {acknowledgementValidationSchema} from "../cbs/validations";
import TextField from "../utils/components/TextField";
import SaveIcon from "@mui/icons-material/Save";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {useNavigate} from "react-router-dom";

async function addAcknowledgementAPI(transactionId, data) {
    const response = await API.post(`cbs/cas/${transactionId}/acknowledgement-number/`, data);
    return response.data;
}

export default function AcknowledgementForm() {
    const {caState, caDispatch} = useContext(CAContext);
    const {caStatus} = caState.baseData;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const isReading = caStatus !== 1;
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const caUpdate = useMutation(
        ['cbs', 'cas', caState.baseData.ca],
        (data) => addAcknowledgementAPI(caState.baseData.ca, data),
        {
            onMutate: variables => {
                variables = makeNulls(variables);
                handleProgressOpen()
                return variables
            }, onSettled: () => {
                handleProgressClose();
                refetch();
            }, onSuccess: () => {
                navigate(-1);
                queryClient.invalidateQueries(['cbs', 'cas', caState.baseData.ca])
            }
        });

    const {
        data,
        isFetching,
        refetch
    } = useQuery(
        ['cbs', 'cas', caState.baseData.ca],
        () => generalAPI(`cbs/cas/${caState.baseData.ca}/`),
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: (caState.baseData.caStatus !== 0) && !caState.baseData.pages[caState.baseData.activeStep].visited,
        });

    useEffect(() => {
        if (!caState.baseData.pages[caState.baseData.activeStep].visited && data && (caState.baseData.caStatus !== 0)) {
            caDispatch({
                type: 'addUpdateFormData', data: {'acknowledgement': data}
            })
        }

    }, [data, caState.baseData.ca, caState.baseData.activeStep, caState.baseData.pages, caDispatch, caState.baseData.caStatus]);


    return (
        <>
            <Paper sx={{p: 3, mx: 35}}>
                <Box sx={{my: 1, position: 'relative'}}>
                    <Box>
                        <Typography variant={'h5'}>
                            Acknowledgement Details
                        </Typography>
                    </Box>
                    {caUpdate.isError && <Box sx={{m: 2}}>
                        <APIError error={caUpdate.error}/>
                    </Box>}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '0',
                            left: '324px'
                        }}
                    >
                        {isFetching && <CircularProgress size={'2rem'}/>}
                    </Box>
                </Box>

                <Formik
                    enableReinitialize={true}
                    initialValues={caState.formData.acknowledgement}
                    validationSchema={acknowledgementValidationSchema}
                    validateOnChange={false}
                    onSubmit={caUpdate.mutate}
                >
                    {
                        formik => (
                            <Grid container columnSpacing={5} rowSpacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'acknowledgement_number'}
                                        label={'Acknowledgement Number'}
                                        required
                                        disabled={isReading}
                                        isReading={isReading}
                                        fullWidth
                                        autoFocus={!isReading}
                                        apiError={caUpdate.error}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'acknowledgement_date'}
                                        label={'Acknowledgement Date'}
                                        required
                                        type={'date'}
                                        disabled={isReading}
                                        isReading={isReading}
                                        fullWidth
                                        apiError={caUpdate.error}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{textAlign: 'center'}}>
                                    <Button
                                        disabled={caStatus !== 1}
                                        startIcon={<SaveIcon/>}
                                        variant={'contained'}
                                        onClick={() => formik.submitForm()}
                                    >
                                        Save Acknowledgment
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    }
                </Formik>
            </Paper>

            <Box sx={{textAlign: 'center', mt: 3}}>
                <Button
                    startIcon={<NavigateBeforeIcon/>}
                    variant={'outlined'}
                    onClick={() => caState.baseData.setActiveStep(1, caState.baseData.activeStep)}
                >
                    Previous
                </Button>
            </Box>
        </>
    )
}