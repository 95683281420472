import API from "../utils/API";
import {useLocation} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import APIError from "../utils/components/APIError";
import {Box, Container, Toolbar, Typography} from "@mui/material";
import UserForm from "../users/UserForm";
import fetchingStatus from "../utils/components/QueryFetching";
import BackButton from "../utils/components/BackButton";

async function userDetailAPI(id) {
    const response = await API.get(`accounts/users/${id}/`);
    return response.data;
}

export default function UserDetail() {
    const location = useLocation();
    let data = location.state?.data;

    const query = useQuery(
        ['accounts', 'users', data.id],
        () => userDetailAPI(data.id),
        {
            enabled: Boolean(data.id),
            placeholderData: data
        }
    );

    if (query.data) {
        return (
            <>
                <Toolbar>
                    <BackButton to={-1}/>
                    <Typography variant={"navbarTitle"}>{data.name}</Typography>
                </Toolbar>
                <Container sx={{position: "relative", overflowY: 'auto', maxHeight: 'calc(100vh - 128px)'}}>
                    <Box
                        sx={{
                            position: 'absolute',
                            right: "340px",
                            top: "32px"
                        }}
                    >
                        <Typography variant={"helpText"}>{fetchingStatus(query)}</Typography>
                    </Box>
                    <UserForm data={query.data}/>
                </Container>
            </>
        )
    }

    if (query.error) {
        return <APIError error={query.error}/>
    }

    return <></>
}