import {useMutation, useQuery} from "@tanstack/react-query";
import {useContext, useEffect} from "react";
import {CBContext} from "./CBContext";
import {generalAPI} from "./utils";
import {Formik} from "formik";
import {remitterValidationSchema} from "./validations";
import {Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import RadioField from "../utils/components/RadioField";
import Mapping from "../utils/UtilityMapping.json";
import TextField from "../utils/components/TextField";
import API from "../utils/API";
import {ProgressContext} from "../utils/ProgressContext";
import {getPatchValues} from "../utils/utils";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import APIError from "../utils/components/APIError";


async function remitterUpdateAPI(data, id) {
    const response = await API.patch(`cbs/remitters/${id}/`, data);
    return response.data;
}

export default function RemitterForm() {
    const {cbState, cbDispatch} = useContext(CBContext);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);

    const remitterRef = cbState.baseData.remitterRef;

    const {
        data: templateRemitterData,
        isFetching: templateRemitterIsFetching,
        isError: templateRemitterIsError,
        error: templateRemitterError
    } = useQuery(
        ['remitters', cbState.baseData.remitterId],
        () => generalAPI(`/remitters/${cbState.baseData.remitterId}/`),
        {
            refetchOnWindowFocus: false,
            enabled: cbState.baseData.status === 0  && cbState.baseData.cloning === false && !cbState.baseData.pages[cbState.baseData.activeStep].visited
        }
    );

    const {
        data: remitterData,
        isFetching: remitterIsFetching,
        refetch: remitterRefetch,
        isError: remitterIsError,
        error: remitterError
    } = useQuery(
        ['cbs', 'remitters', cbState.baseData.remitterId],
        () => generalAPI(`cbs/remitters/${cbState.baseData.remitterId}/`),
        {
            refetchOnWindowFocus: false,
            enabled: (cbState.baseData.status !== 0 || cbState.baseData.cloning === true) && !cbState.baseData.pages[cbState.baseData.activeStep].visited
        }
    );


    const updateRemitter = useMutation(
        ['cbs', 'remitters', cbState.baseData.remitterId],
        (data) => remitterUpdateAPI(data, cbState.baseData.remitterId),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                remitterRefetch();
            }
        }
    )


    function handleUpdateRemitter() {
        let remitter = remitterRef.current.validateForm();
        remitter.then(res => {
            if (Object.keys(res).length === 0) {
                updateRemitter.mutate(getPatchValues(cbState.updateData.remitter, remitterRef.current.values))
            }
        })
    }


    useEffect(() => {
        function initializeForm(data, update = false) {
            cbDispatch({
                type: update ? 'addUpdateFormData' : 'addFormData',
                data: {'remitter': data}
            })
        }

        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited) {
            if ((cbState.baseData.cloning || cbState.baseData.status !== 0) && (remitterData)) {
                initializeForm(remitterData, true)
            } else if (templateRemitterData) {
                initializeForm(templateRemitterData)
            }
        }

    }, [remitterData, templateRemitterData, cbState.baseData.status, cbState.baseData.activeStep, cbState.baseData.cloning, cbState.baseData.pages, cbDispatch]);

    const isReading = cbState.baseData.status >= 2;
    const isError = templateRemitterIsError || remitterIsError
    const error = templateRemitterError || remitterError

    if (isError) {
        return <>
            <Box sx={{mx: 25}}>
                <APIError error={error}/>
            </Box>
        </>
    }

    return (
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '-72px',
                    left: '125px'
                }}
            >
                {(templateRemitterIsFetching || remitterIsFetching || updateRemitter.isLoading) &&
                    <CircularProgress size={'1.5rem'}/>}
            </Box>

            {
                cbState.baseData.status === 1 && <Box
                    sx={{
                        position: 'absolute',
                        top: '-40px',
                        right: 0
                    }}
                >
                    <Button startIcon={<CancelIcon/>}
                            onClick={() => remitterRef.current.resetForm({values: cbState.updateData.remitter})}>Discard</Button>
                    <Button sx={{ml: 3}} startIcon={<SaveIcon/>} onClick={handleUpdateRemitter}>Save</Button>
                </Box>
            }

            <Formik
                innerRef={remitterRef}
                enableReinitialize
                initialValues={cbState.formData.remitter}
                validationSchema={remitterValidationSchema}
                validateOnChange={false}
            >
                {
                    formik => (
                        <>
                            <Grid container mt={2} sx={{alignItems: 'stretch'}}>
                                {
                                    updateRemitter.isError && <Grid item xs={12}>
                                        <Box sx={{mx: 10}}>
                                            <APIError error={updateRemitter.error}/>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={6} sx={{pr: 2}}>
                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                        Primary Details
                                    </Typography>
                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                        <Grid item xs={6}>
                                            <RadioField
                                                row
                                                required
                                                fullWidth
                                                isReading={isReading}
                                                label={'I/We'}
                                                name={'i_or_we'}
                                                options={Object.entries(Mapping.remitter.iorWe).filter(([key, value]) => key !== '-1')}
                                                apiError={updateRemitter.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <RadioField
                                                row
                                                required
                                                fullWidth
                                                isReading={isReading}
                                                label={'Honorific'}
                                                name={'honorific'}
                                                options={Object.entries(Mapping.remitter.remitterHonorific).filter(([key, value]) => key !== '-1')}
                                                apiError={updateRemitter.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                required
                                                isReading={true}
                                                name={'name'}
                                                label={'Name'}
                                                apiError={updateRemitter.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                required
                                                isReading={true}
                                                name={'pan_tan'}
                                                label={'PAN/TAN Number'}
                                                apiError={updateRemitter.error}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}
                                      sx={{
                                          borderLeft: "1px solid",
                                          borderColor: "rgba(0, 0, 0, 0.10)",
                                          pl: 2
                                      }}
                                >
                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                        Bank Details
                                    </Typography>
                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                        <Grid item xs={6}>
                                            <TextField
                                                select
                                                fullWidth
                                                required
                                                isReading={isReading}
                                                name={'bank_name'}
                                                label={'Bank Name'}
                                                apiError={updateRemitter.error}
                                                options={Mapping.remitter.nameBankCode}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                isReading={isReading}
                                                required={formik.values.bank_name !== '999'}
                                                disabled={formik.values.bank_name !== '999'}
                                                name={'bank_name_other'}
                                                label={'Bank Name Other'}
                                                apiError={updateRemitter.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                required
                                                isReading={isReading}
                                                name={'branch_name'}
                                                label={'Branch Name'}
                                                apiError={updateRemitter.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                isReading={isReading}
                                                name={'bsr_code'}
                                                label={'BSR Code'}
                                                apiError={updateRemitter.error}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioField
                                        row
                                        required={!isReading}
                                        isReading={isReading}
                                        name={'is_ca_applicable'}
                                        label={'Is 15CA Applicable?'}
                                        helperText={''}
                                        apiError={updateRemitter.error}
                                    />
                                </Grid>
                                {
                                    formik.values.is_ca_applicable === 'Y' && (
                                        <Grid container item xs={12} mt={2}>
                                            <Grid item xs={6} sx={{pr: 2}}>
                                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                    Address Details
                                                </Typography>
                                                <Grid container columnSpacing={2} rowSpacing={4}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'flat_door_building'}
                                                            label={'Flat/Door/Building'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            isReading={isReading}
                                                            name={'premise_building_village'}
                                                            label={'Premise/Building/Village'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            isReading={isReading}
                                                            name={'road_street'}
                                                            label={'Road/Street'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'area_locality'}
                                                            label={'Area/Locality'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'town_city_district'}
                                                            label={'Town/City/District'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'state'}
                                                            label={'State'}
                                                            apiError={updateRemitter.error}
                                                            options={Mapping.remitter.state}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'country'}
                                                            label={'Country'}
                                                            apiError={updateRemitter.error}
                                                            options={Mapping.remitter.country}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'pincode'}
                                                            label={'Pincode'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Typography mt={1.5} variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                    Certification Details
                                                </Typography>
                                                <Grid container columnSpacing={2} rowSpacing={4}>
                                                    <Grid item xs={6}>
                                                        <RadioField
                                                            row
                                                            required={!isReading}
                                                            fullWidth
                                                            isReading={isReading}
                                                            label={'I/We'}
                                                            name={'verification_i_we'}
                                                            options={Object.entries(Mapping.remitter.verificationIorWe).filter(([key, value]) => key !== '-1')}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'verification_person_name'}
                                                            label={'Person Name'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'landlord_name'}
                                                            label={'Parent/Guardian Name'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'verification_designation'}
                                                            label={'Designation'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'verification_place'}
                                                            label={'Place'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}
                                                  sx={{
                                                      borderLeft: "1px solid",
                                                      borderColor: "rgba(0, 0, 0, 0.10)",
                                                      pl: 2
                                                  }}>
                                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                    Business Details
                                                </Typography>
                                                <Grid container columnSpacing={2} rowSpacing={4}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            upperCase
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'place_of_business'}
                                                            label={'Place of Business'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'email'}
                                                            label={'Email'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'phone'}
                                                            label={'Phone'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}/>
                                                    <Grid item xs={6}>
                                                        <RadioField
                                                            required={!isReading}
                                                            fullWidth
                                                            isReading={isReading}
                                                            label={'Status'}
                                                            name={'status'}
                                                            options={Object.entries(Mapping.remitter.status).filter(([key, value]) => key !== '-1')}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <RadioField
                                                            required={!isReading}
                                                            fullWidth
                                                            isReading={isReading}
                                                            label={'Residential Status'}
                                                            name={'residential_status'}
                                                            options={Object.entries(Mapping.remitter.residentialStatus).filter(([key, value]) => key !== '-1')}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                    Optional Details
                                                </Typography>
                                                <Grid container columnSpacing={2} rowSpacing={4}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            upperCase
                                                            isReading={isReading}
                                                            name={'area_code'}
                                                            label={'Area Code'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            upperCase
                                                            isReading={isReading}
                                                            name={'ao_type'}
                                                            label={'AO Type'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            isReading={isReading}
                                                            name={'range_code'}
                                                            label={'Range Code'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            isReading={isReading}
                                                            name={'ao_number'}
                                                            label={'AO Number'}
                                                            apiError={updateRemitter.error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </>
                    )
                }
            </Formik>
        </Box>
    )

}