import {Outlet} from "react-router-dom";
import {organizationalUser} from "../utils/constants/UserTypes";
import withPermission from "../utils/components/PermissionRequired";

function Remittees() {
    return (
        <Outlet/>
    )
}

export default withPermission(Remittees, organizationalUser);
