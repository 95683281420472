import API from "../utils/API";
import {redirect} from "react-router-dom";


export async function uidTokenChecker({params}) {
    const data = {
        uid: params.uid,
        token: params.token
    }
    let response;
    try {
        response = await API.post('accounts/users/check-uid-token/', data);
    } catch (err) {
        if (err.code === "ERR_BAD_REQUEST" && err.response.status === 400) {
            return redirect('/accounts/link-expired');
        }
    }
    return response.data;
}
