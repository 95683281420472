import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {useField} from "formik";
import {useFormikFieldError} from "../utils";
import {useId} from "react";

const filter = createFilterOptions();

export default function AutoCompleteField(props) {
    const [field, meta, helpers] = useField(props);
    const {name, value} = field;
    const {label, options, apiError, isReading, helperText = ' ', required, upperCase = false, lowerCase = false, type = 'text'} = props;
    const {error, textFieldHelperText} = useFormikFieldError(meta, name, apiError, helperText)
    const id = useId();

    function handleInputValue(newValue) {
        newValue = lowerCase && newValue ? newValue.toLowerCase() : newValue;
        newValue = upperCase && newValue ? newValue.toUpperCase() : newValue;
        if (newValue) {
            helpers.setValue(newValue, true);
        } else {
            helpers.setValue('', true);
        }
    }

    return (
        <Autocomplete
            value={value || ''}
            onChange={(event, newValue) => {
                handleInputValue(newValue)
            }}
            inputValue={value || ''}
            onInputChange={(event, newValue) => {
                handleInputValue(newValue)
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const {inputValue} = params;
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                    filtered.push(inputValue);
                }
                return filtered;
            }}
            selectOnFocus
            handleHomeEndKeys
            options={options}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            freeSolo
            disabled={isReading}
            id={id}
            multiple={false}
            onBlur={() => helpers.setTouched(true, true)}
            renderInput={(params) => (
                <TextField
                    InputProps={{
                        readOnly: isReading,
                        disableUnderline: isReading,
                    }}
                    error={error}
                    type={type}
                    helperText={textFieldHelperText}
                    required={required}
                    {...params}
                    label={label}
                    size={'small'}
                    variant={'standard'}
                />
            )}
        />
    );
}