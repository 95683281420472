import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton, List,
    ListItemText,
    Paper, Tooltip,
    Typography
} from "@mui/material";
import FileField from "../utils/components/FileField";
import TextField from "../utils/components/TextField";
import React, {useContext, useEffect, useState} from "react";
import {CBContext} from "./CBContext";
import {useMutation, useQuery} from "@tanstack/react-query";
import {generalAPI} from "./utils";
import {FieldArray, Formik} from "formik";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {invoiceValidationSchema} from "./validations";
import {getPatchValues, getSize} from "../utils/utils";
import API from "../utils/API";
import {ProgressContext} from "../utils/ProgressContext";
import APIError from "../utils/components/APIError";
import SaveIcon from "@mui/icons-material/Save";
import {getInitialInvoiceForm} from "./initialValues";

function InvoiceFileForm(props) {
    const {index, arrayHelpers, refetch} = props;
    const {cbState, cbDispatch} = useContext(CBContext);
    const {status} = cbState.baseData;
    const invoice = arrayHelpers.form.values.invoices[index];
    const invoiceRef = cbState.baseData.invoicesRef;
    const isReading = status >= 2;
    const [validationError, setValidationError] = useState(false);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);

    const createInvoice = useMutation(
        ['invoices'],
        invoiceCreateAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: data => {
                cbDispatch({
                    type: 'addFormData',
                    data: {invoices: {invoices: [...cbState.updateData.invoices.invoices, data]}}
                })
            }
        }
    )

    const updateInvoice = useMutation(
        ['cbs', 'invoices'],
        invoiceUpdateAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                refetch();
            }
        }
    );

    function handleInvoiceCheck(successFunction, data){
        let invoices = invoiceRef.current.validateForm();
        setValidationError(false)
        invoices.then(res => {
            if (Object.keys(res).length === 0 || !res.invoices[index]) {
                successFunction(data)
            } else {
                setValidationError(true)
                for (let key of Object.keys(res.invoices[index])) {
                    arrayHelpers.form.setFieldTouched(`invoices.${index}.${key}`, true, true)
                }
            }
        });
    }
    function handleInvoiceUpdate() {
        let patchedData = getPatchValues(cbState.updateData.invoices.invoices[index], invoice)
        patchedData.id = arrayHelpers.form.values.invoices[index].id;
        handleInvoiceCheck(updateInvoice.mutate, patchedData);
    }

    function handleInvoiceReset() {
        setValidationError(false)
        let invoices = arrayHelpers.form.values.invoices;
        invoices[index] = cbState.updateData.invoices.invoices[index];
        cbDispatch({
            type: 'addFormData',
            data: {invoices: {invoices: invoices}}
        });
        for (let key of Object.keys(invoices[index])) {
            arrayHelpers.form.setFieldTouched(`invoices.${index}.${key}`, true, true)
        }
    }

    function handleInvoiceCreate(values, index) {
        values['cb'] = cbState.baseData.transactionId;
        handleInvoiceCheck(createInvoice.mutate, values)
    }

    const isError = createInvoice.isError || updateInvoice.isError;
    const error = createInvoice.error || updateInvoice.error;

    return (
        <Grid container columnSpacing={5} rowSpacing={4}>
            {
                validationError && <Grid item xs={12}>
                    <Alert severity={'error'} onClose={() => setValidationError(false)}>
                        All the fields are not filled.
                    </Alert>
                </Grid>
            }
            {
                isError && <Grid item xs={12}>
                    <APIError error={error}/>
                </Grid>
            }
            <Grid item xs={8}>
                {
                    status === 0 || !(Boolean(invoice?.id)) ? <>
                        <FileField
                            fullWidth
                            required
                            name={`invoices.${index}.invoice_file`}
                            label={'Invoice File'}
                            helperText={<>Maximum file size of 10MB. File will be renamed to it's invoice number for easy access</>}
                        />
                    </> : <>
                        {
                            status === 1 && Boolean(invoice.id) && <>
                                File Name: {' '}
                                <Typography component={'span'} sx={{fontWeight: 600}}>
                                    {invoice.invoice_file_name} ({getSize(invoice.invoice_file_size)})
                                </Typography>
                            </>
                        }
                    </>
                }
            </Grid>
            {
                (status === 1 && !Boolean(invoice.id)) && <>
                    <Grid item xs={4}>
                        <Box textAlign={'right'}>
                            <Button
                                startIcon={<AddIcon/>}
                                onClick={() => handleInvoiceCreate(invoice, index)}
                            >
                                Add
                            </Button>
                        </Box>
                    </Grid>
                </>
            }
            {
                status === 1 && Boolean(invoice.id) && <>
                    <Grid item xs={4}>
                        <Box textAlign={'right'}>
                            <Button
                                startIcon={<CloseIcon/>}
                                onClick={() => handleInvoiceReset()}
                            >
                                Reset
                            </Button>
                            <Button
                                sx={{ml: 3}}
                                startIcon={<SaveIcon/>}
                                onClick={() => handleInvoiceUpdate()}
                            >
                                Update
                            </Button>
                        </Box>
                    </Grid>
                </>
            }
            <Grid item xs={6}>
                <TextField
                    required
                    fullWidth
                    isReading={isReading}
                    label={'Invoice Number'}
                    name={`invoices.${index}.invoice_number`}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    required
                    isReading={isReading}
                    fullWidth
                    type={'number'}
                    label={'Invoice Amount'}
                    name={`invoices.${index}.invoice_amount`}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type={'date'}
                    isReading={isReading}
                    required
                    fullWidth
                    label={'Invoice Date'}
                    name={`invoices.${index}.invoice_date`}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={6}/>
            <Grid item xs={12}>
                <Typography sx={{color: 'grey.600'}} variant={'h6'}>Exchange Rate Details</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    required
                    fullWidth
                    type={'number'}
                    isReading={isReading}
                    label={'Exchange Rate'}
                    name={`invoices.${index}.exchange_rate`}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type={'date'}
                    required
                    fullWidth
                    isReading={isReading}
                    label={'Exchange Date'}
                    name={`invoices.${index}.exchange_date`}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
        </Grid>
    )
}


async function invoiceDeleteAPI(id) {
    const response = await API.delete(`/cbs/invoices/${id}/`)
    return response.data;
}

async function invoiceCreateAPI(data) {
    const response = await API.post('/cbs/invoices/', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        formSerializer: {
            dots: true
        }
    });
    return response.data;
}

async function invoiceUpdateAPI(data) {
    const response = await API.patch(`/cbs/invoices/${data.id}/`, data);
    return response.data;
}

export default function InvoiceForm() {
    const {cbState, cbDispatch} = useContext(CBContext);
    const invoiceRef = cbState.baseData.invoicesRef;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);

    const param = `?cb_id=${cbState.baseData.transactionId}`;
    const [errors, setErrors] = useState(null);

    const {data, isFetching, refetch, isError, error} = useQuery(
        ['cbs', 'invoices', param],
        () => generalAPI(`cbs/invoices/${param}`),
        {
            refetchOnWindowFocus: false,
            enabled: cbState.baseData.status > 0 && !cbState.baseData.pages[cbState.baseData.activeStep].visited
        }
    );


    const deleteInvoice = useMutation(
        ['invoices'],
        (id) => invoiceDeleteAPI(id),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
        }
    )

    function handleInvoiceSave(func) {
        let invoice = invoiceRef.current.validateForm();
        invoice.then(res => {
            if (Object.keys(res).length === 0) {
                if (cbState.baseData.status === 0) {
                    cbDispatch({
                        type: 'addFormData',
                        data: {
                            invoices: invoiceRef.current.values
                        }
                    });
                }
                func();
            } else {
                setErrors(res);
            }
        })
    }
    function handleInvoiceDelete(arrayHelpers, index) {
        if (cbState.baseData.status >= 1) {
            const invoice = arrayHelpers.form.values.invoices[index]
            const invoiceId = invoice.id;
            if (Boolean(invoiceId)) {
                deleteInvoice.mutate(invoiceId, {
                    onSuccess: () => {
                        arrayHelpers.remove(index)
                    }
                });
            } else {
                arrayHelpers.remove(index)
            }
        } else {
            arrayHelpers.remove(index)
        }
    }

    useEffect(() => {
        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited && data && cbState.baseData.status !== 0 && !cbState.baseData.cloning) {
            cbDispatch({
                type: 'addUpdateFormData',
                data: {'invoices': {'invoices': data}}
            })
        }

    }, [data, cbState.baseData.status, cbState.baseData.activeStep, cbState.baseData.pages, cbState.baseData.cloning, cbDispatch]);

    const DeleteButton = ({handleClick, id}) => <>
        <IconButton
            sx={{
                position: 'absolute',
                right: '16px',
                top: '8px'
            }}
            onClick={handleClick}
        >
            {
                cbState.baseData.status === 1 && Boolean(id) ?
                    <Tooltip title={'Will be deleted frm Database'} placement={'bottom'}>
                        <CloseIcon/>
                    </Tooltip> : <CloseIcon/>
            }
        </IconButton>
    </>;

    if (isError){
        return <>
            <Box sx={{mx: 25}}>
                <APIError error={error}/>
            </Box>
        </>
    }

    return (
        <Box
            sx={{
                position: 'relative'
            }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '40px',
                    left: '300px',
                    zIndex: 10
                }}
            >
                {isFetching && <CircularProgress size={'2rem'}/>}
            </Box>
            <Paper sx={{p: 3, mx: 15}}>

                {
                    errors && <Box sx={{my: 2}}>
                        <Alert
                            icon={false}
                            severity={'error'}
                            onClose={() => setErrors(null)}
                        >
                            <List disablePadding={true}>
                                <ListItemText>Atleast one invoice should be there</ListItemText>
                                <ListItemText>All the fields should be filled</ListItemText>
                            </List>
                        </Alert>
                    </Box>
                }

                {
                    deleteInvoice.isError && <Box sx={{my: 2}}>
                        <APIError error={deleteInvoice.error}/>
                    </Box>
                }

                <Formik
                    innerRef={invoiceRef}
                    enableReinitialize
                    validationSchema={invoiceValidationSchema}
                    initialValues={cbState.formData.invoices}
                    validateOnChange={false}
                >
                    {

                        formik => (
                            <>
                                <FieldArray name={'invoices'}>
                                    {
                                        arrayHelpers => (
                                            <>
                                                {
                                                    formik.values.invoices && formik.values.invoices.map((file, index) => (
                                                        <React.Fragment key={index}>
                                                            <Paper
                                                                sx={{
                                                                    pt: 2,
                                                                    pb: 4,
                                                                    position: 'relative',
                                                                    mb: 2,
                                                                    boxShadow: '0',
                                                                    border: '1px solid',
                                                                    borderColor: 'grey.400'
                                                                }}
                                                            >

                                                                {
                                                                    cbState.baseData.status <= 1 && <DeleteButton
                                                                        handleClick={() => handleInvoiceDelete(arrayHelpers, index)}
                                                                        id={file.id}
                                                                    />
                                                                }

                                                                <Typography sx={{px: 4, mb: 1, color: 'grey.700'}}
                                                                            variant={'h5'}>Invoice {index + 1}</Typography>
                                                                <Divider/>
                                                                <Box sx={{px: 4}}>
                                                                    <InvoiceFileForm arrayHelpers={arrayHelpers}
                                                                                     index={index}
                                                                                     refetch={refetch}
                                                                    />
                                                                </Box>
                                                            </Paper>
                                                        </React.Fragment>
                                                    ))
                                                }
                                                <Box sx={{textAlign: 'center'}}>
                                                    <Button
                                                        disabled={cbState.baseData.status >= 2}
                                                        startIcon={<AddIcon/>}
                                                        variant={'outlined'}
                                                        onClick={() => arrayHelpers.push(getInitialInvoiceForm())}
                                                    >
                                                        Add Invoice
                                                    </Button>
                                                </Box>
                                            </>
                                        )
                                    }
                                </FieldArray>
                            </>
                        )
                    }
                </Formik>
            </Paper>

            <Box sx={{textAlign: 'center', mt: 3}}>
                <Button
                    startIcon={<NavigateBeforeIcon/>}
                    variant={'outlined'}
                    onClick={() => handleInvoiceSave(() => cbState.baseData.setActiveStep(0, cbState.baseData.activeStep))}
                >
                    Previous
                </Button>
                <Button
                    variant={'contained'}
                    sx={{ml: 3}}
                    startIcon={<NavigateNextIcon/>}
                    onClick={() => handleInvoiceSave(() => cbState.baseData.setActiveStep(2, cbState.baseData.activeStep))}
                >
                    Next
                </Button>
            </Box>

        </Box>
    )
}
