import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Form, Formik} from "formik";
import PasswordField from "../utils/components/PasswordField";
import * as Yup from "yup";
import API from "../utils/API";
import {useMutation} from "@tanstack/react-query";
import {useContext, useState} from "react";
import {ProgressContext} from "../utils/ProgressContext";
import {UserActions} from "../utils/UserContext";
import {Box, Button, List, ListItem, Popover, Typography} from "@mui/material";
import APIError from "../utils/components/APIError";
import BackButton from "../utils/components/BackButton";

const initialValues = {password: ""};
const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required")
});


function SetPasswordForm() {
    const params = useParams();
    const navigate = useNavigate();
    const uid = params?.uid;
    const token = params?.token;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const dispatch = useContext(UserActions);
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();
    const path = location.pathname;

    let url;
    let mutationKey;
    let type;

    if (path.startsWith('/accounts/update-password')) {
        url = 'accounts/users/update-password/';
        mutationKey = ['accounts', 'users', 'update-password'];
        type = 'UPDATE'
    } else if (path.startsWith('/accounts/password-reset')) {
        url = 'accounts/users/password-reset/';
        mutationKey = ['accounts', 'users', 'password-reset'];
        type = 'FORGOT'
    } else if (path.startsWith('/accounts/activate')) {
        url = 'accounts/users/activate/';
        mutationKey = ['accounts', 'users', 'activate'];
        type = 'ACTIVATE'
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    async function resetPasswordAPI(data) {
        data['uid'] = uid === undefined ? '' : uid;
        data['token'] = token === undefined ? '' : token;
        const response = await API.post(url, data);
        return response.data;
    }

    const mutation = useMutation(
        mutationKey,
        resetPasswordAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: data => {
                if (type === 'UPDATE') {
                    navigate(-1);
                } else {
                    dispatch({type: 'login', user: data});
                    navigate('/')
                }
            }
        }
    )

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={mutation.mutate}
        >
            <Form>
                {mutation.isError && <Box sx={{mt: 5}}>
                    <APIError error={mutation.error}/>
                </Box>}
                <PasswordField
                    required
                    fullWidth
                    name={"password"}
                    label={"Password"}
                    formControlSxProps={{mt: 5}}
                    apiError={mutation.error}
                    autoFocus
                />
                <Button sx={{mt: "32px"}} type={"submit"} variant={"contained"}>Submit</Button>
                <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    sx={{
                        color: 'primary.main',
                        mt: 2
                    }}
                >
                    Need help to set password?
                </Typography>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography
                        as={'div'}
                        sx={{
                            p: 1,
                            color: 'grey.700',
                            fontSize: '0.625rem'
                        }}
                    >
                        Your password must include:
                        <List dense={true} sx={{px: 1, py: 0}}>
                            <ListItem sx={{p: 0}}>
                                At least 8 characters — the more, the better.
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                Both uppercase and lowercase letters.
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                Both letters and numbers.
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                At least one special character, e.g., ! @ # ? ]
                            </ListItem>
                            <ListItem sx={{p: 0}}>
                                No words present in your name or email id.
                            </ListItem>
                        </List>
                    </Typography>
                </Popover>
            </Form>
        </Formik>
    )
}

export default function SetPassword() {
    return (
        <Box sx={{textAlign: "center"}}>
            <BackButton to={-1} sx={{top: "36px", left: "36px"}}/>
            <Typography variant={"h3"}>Set Password</Typography>
            <Typography mt={3} variant={"helpText"}>
                Please set a new password for your account.
            </Typography>
            <SetPasswordForm/>
        </Box>
    )
}
