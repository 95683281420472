import {Box, Button, Chip, Container, TableCell, Toolbar, Typography} from "@mui/material";
import Link from "../utils/components/Link";
import {organizationalUser} from "../utils/constants/UserTypes";
import DataTable from "../utils/components/DataTable";
import withPermission from "../utils/components/PermissionRequired";
import Mapping from "../utils/UtilityMapping.json";
import {parseDateTime, StatusChip} from "../utils/utils";
import {useState} from "react";
import SearchTextField from "../utils/components/SearchTextField";

function Header() {
    return (
        <>
            <TableCell>Name</TableCell>
            <TableCell>PAN/TAN</TableCell>
            <TableCell>Bank Name</TableCell>
            <TableCell>15CA</TableCell>
            <TableCell>Usage Status</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
        </>
    )
}

function Row({element}) {
    const is_ca_applicable = element.is_ca_applicable;
    let chip = <Chip label={'Applicable'} variant={'outlined'} color={'success'} sx={{backgroundColor: '#B2FFA8'}}/>
    if (is_ca_applicable === 'N') {
        chip = <Chip label={'Not-Applicable'} color={'error'} variant={'outlined'} sx={{backgroundColor: '#FFDFDF'}}/>
    }

    let bank_name = Mapping.remitter.nameBankCode[element.bank_name];
    if (element.bank_name === '999') {
        bank_name = element.bank_name_other
    }
    return (
        <>
            <TableCell><Link underline={'none'} to={'./detail'}
                             state={{data: element}}>{element.name} </Link></TableCell>
            <TableCell>{element.pan_tan}</TableCell>
            <TableCell>{bank_name}</TableCell>
            <TableCell>{chip}</TableCell>
            <TableCell><StatusChip status={element.usage_status}/></TableCell>
            <TableCell>{element.creator}</TableCell>
            <TableCell>{element.updator}</TableCell>
            <TableCell>{parseDateTime(element.created_at)}</TableCell>
            <TableCell>{parseDateTime(element.updated_at)}</TableCell>
        </>
    )
}

function RemitterList() {
    const [search, setSearch] = useState('');
    const [queryParams, setQueryParams] = useState({search: ''});

    function handleKeyDown(e) {
        if (e.key === "Enter") {
            setQueryParams({...queryParams, search: search})
        }
    }

    function handleChange(e) {
        setSearch(e.currentTarget.value);
    }

    return (
        <>
            <Toolbar>
                <Typography variant={"h5"} sx={{color: "primary.main"}}>Remitter</Typography>
                <Box
                    sx={{
                        position: "absolute",
                        top: "12px",
                        right: "40px"
                    }}
                >
                    <Button variant={'contained'} component={Link} to={'./create'}>Create Remitter</Button>
                </Box>
            </Toolbar>
            <Box sx={{overflowY: 'auto', maxHeight: 'calc(100vh - 128px)'}}>
                <Container>
                    <SearchTextField
                        value={search}
                        handleChange={handleChange}
                        handleKeyDown={handleKeyDown}
                        placeholder={'Search by Name, PAN'}
                    />
                    <DataTable
                        message={'Select the remitter name to view their details.'}
                        header={<Header/>}
                        rowParser={(data) => <Row element={data}/>}
                        queryUrl={'remitters/'}
                        queryKey={['remitters']}
                        queryParams={queryParams}
                    />
                </Container>
            </Box>
        </>
    )
}

export default withPermission(RemitterList, organizationalUser);
