import API from "../API";
import {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState} from "react";
import {ProgressContext} from "../ProgressContext";
import {useQuery} from "@tanstack/react-query";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import APIError from "./APIError";
import Image from "./NoData.svg";
import fetchingStatus from "./QueryFetching";

async function dataAPI(queryUrl, keys) {
    const response = await API.get(`${queryUrl}?${keys.join('&')}`);
    return response.data;
}

const DataTable = forwardRef((props, ref) => {
    const {
        header,
        headerButton = null,
        rowParser,
        queryUrl,
        queryKey,
        queryParams = {},
        message,
        paginated = true
    } = props;

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);


    const finalParams = {
        ...queryParams,
        page_size: pageSize,
        page: page,
    }

    const keys = Object.entries(finalParams).map(([key, value]) => `${key}=${value}`);

    const query = useQuery(
        [...queryKey, ...keys],
        () => dataAPI(queryUrl, keys),
    )

    const refresh = useCallback(() => {
        query.refetch();
    }, [query]);

    useImperativeHandle(ref, () => {
        return {
            refetch() {
                refresh();
            },
            setFirstPage() {
                setPage(1);
            }
        }
    }, [refresh])

    useEffect(() => {
        if (query.status === 'loading') {
            handleProgressOpen()
        } else {
            handleProgressClose();
        }
    }, [query.status, handleProgressClose, handleProgressOpen]);

    useEffect(() => {
        setPage(1)
    }, [queryParams])


    function handlePageChange(e, newPage) {
        setPage(newPage + 1);
    }

    function handleChangeRowsPerPage(event) {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
    }

    let count;
    let results;
    if (paginated) {
        count = query.data === undefined ? 0 : query.data.count;
        results = query.data?.results;
    } else {
        count = query.data?.length;
        results = query.data;
    }

    if (count) {
        const tablePagination = (<>
                {
                    paginated ? <TablePagination
                            component="div"
                            count={count}
                            page={finalParams.page - 1}
                            onPageChange={handlePageChange}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                        /> :
                        <Box sx={{height: '56px'}}>{' '}</Box>
                }
            </>
        )

        return (
            <Box
                position={'relative'}
            >
                <Box
                    sx={{
                        display: 'flex',
                        mb: 1,
                    }}
                >
                    <Typography variant={'helpText'}>
                        {message}
                    </Typography>
                    <Typography flexGrow={1} variant={'helpText'} sx={{textAlign: 'end'}}>
                        {fetchingStatus(query)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 2,
                        top: '34px',
                        left: '32px'
                    }}
                >
                    {headerButton}
                </Box>
                <Paper sx={{boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.13)"}}>
                    {tablePagination}
                    <TableContainer component={Paper} sx={{borderRadius: 0}}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{borderTop: '1px solid rgba(224,224,224,1)'}}>
                                    {header}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{maxWidth: '100%', overflowX: 'auto'}}>
                                {results.map((item) =>
                                    <TableRow
                                        key={item.id}
                                    >
                                        {rowParser(item)}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {paginated && tablePagination}
                </Paper>
            </Box>
        )
    }

    if (query.error) {
        return <APIError error={query.error}/>
    }

    if (query.isLoading) {
        return <></>
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img src={Image} alt={'No Data available'}/>
            <Typography sx={{color: "grey.700", fontSize: "0.75rem", mt: 5, px: 1}}>There is not data available. We
                suggest you create data</Typography>
        </Box>
    )
});

export default DataTable;
