import {createContext} from "react";

export const CBContext = createContext({});

export function cbContextHandler(state, action) {
    switch (action.type) {
        case 'addFormData':
            return {...state, 'formData': {...state.formData, ...action.data}}
        case 'addBaseData':
            return {...state, 'baseData': {...state.baseData, ...action.data}}
        case 'addUpdateFormData':
            return {...state, 'formData': {...state.formData, ...action.data}, 'updateData': {...state.updateData, ...action.data}}
        default:
            return state
    }
}
