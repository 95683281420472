import * as Yup from "yup";
import {dropDownErrorMsg, maxLengthErrorMsg} from "./YupErrorMessages";
import {ascii} from "../utils";

export const defaultAddressFields = {
    flat_door_building: "",
    premise_building_village: "",
    road_street: "",
    area_locality: "",
    town_city_district: "",
    country: "-1",
};

export const defaultAddressValidationSchema = Yup.object().shape({
    flat_door_building: ascii.max(50, maxLengthErrorMsg).required("Flat/Door/Building is required"),
    premise_building_village: ascii.max(50, maxLengthErrorMsg).nullable(),
    road_street: ascii.max(50, maxLengthErrorMsg).nullable(),
    area_locality: ascii.max(50, maxLengthErrorMsg).required("Area/Locality is required"),
    town_city_district: ascii.max(50, maxLengthErrorMsg).required("Town/City/District is required"),
    country: ascii.max(4, maxLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required("Country is required"),
});
