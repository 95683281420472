import {Formik} from "formik";
import {Box, Button, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import TextField from "../utils/components/TextField";
import React, {useContext, useEffect} from "react";
import {CBContext} from "./CBContext";
import {acknowledgementValidationSchema} from "./validations";
import API from "../utils/API";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {ProgressContext} from "../utils/ProgressContext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import APIError from "../utils/components/APIError";
import {generalAPI, removeNulls} from "./utils";
import SaveIcon from "@mui/icons-material/Save";
import {getPatchValues} from "../utils/utils";


async function addAcknowledgementAPI(data, transactionId) {
    const response = await API.post(`cbs/${transactionId}/acknowledgement-number/`, data);
    return response.data;
}


export default function AcknowledgementForm() {
    const {cbState, cbDispatch} = useContext(CBContext);
    const {status} = cbState.baseData;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const isReading = status !== 3 || status === 7;
    const queryClient = useQueryClient();

    const mutation = useMutation(
        ['cbs', cbState.baseData.transactionId, 'acknowledgement-number'],
        (data) => addAcknowledgementAPI(data, cbState.baseData.transactionId),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                cbDispatch({
                    type: 'addBaseData',
                    data: {status: 4, navigateOnSuccess: -2}
                });
                cbState.baseData.setActiveStep(5, cbState.baseData.activeStep);
                queryClient.invalidateQueries(['cbs', cbState.baseData.transactionId])
            }
        }
    )

    const {data: cbData, isFetching: cbIsFetching} = useQuery(
        ['cbs', cbState.baseData.transactionId],
        () => generalAPI(`cbs/${cbState.baseData.transactionId}/`),
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: (cbState.baseData.status >= 3) && !cbState.baseData.pages[cbState.baseData.activeStep].visited,
            select: data => removeNulls(data)
        }
    );

    useEffect(() => {
        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited && cbData && cbState.baseData.status !== 0 && !cbState.baseData.cloning) {
            cbDispatch({
                type: 'addUpdateFormData',
                data: {'acknowledgement': cbData}
            })
        }

    }, [cbData, cbState.baseData.status, cbState.baseData.activeStep, cbState.baseData.pages, cbState.baseData.cloning, cbDispatch]);


    return (
        <>
            <Paper sx={{p: 3, mx: 35}}>
                <Box sx={{my: 1, position: 'relative'}}>
                    <Box>
                        <Typography variant={'h5'}>
                            Acknowledgement Details
                        </Typography>
                    </Box>
                    {mutation.isError && <APIError error={mutation.error}/>}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '0',
                            left: '324px'
                        }}
                    >
                        {cbIsFetching && <CircularProgress size={'2rem'}/>}
                    </Box>
                </Box>

                <Formik
                    enableReinitialize={true}
                    initialValues={cbState.formData.acknowledgement}
                    validationSchema={acknowledgementValidationSchema}
                    validateOnChange={false}
                    onSubmit={values => mutation.mutate(getPatchValues(cbState.updateData.acknowledgement, values))}
                >
                    {
                        formik => (
                            <Grid container columnSpacing={5} rowSpacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'acknowledgement_number'}
                                        label={'Acknowledgement Number'}
                                        required
                                        disabled={isReading}
                                        isReading={isReading}
                                        fullWidth
                                        autoFocus={!isReading}
                                        apiError={mutation.error}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'acknowledgement_date'}
                                        label={'Acknowledgement Date'}
                                        required
                                        type={'date'}
                                        disabled={isReading}
                                        isReading={isReading}
                                        fullWidth
                                        apiError={mutation.error}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{textAlign: 'center'}}>
                                    <Button
                                        disabled={status !== 3}
                                        startIcon={<SaveIcon/>}
                                        variant={'contained'}
                                        onClick={() => formik.submitForm()}
                                    >
                                        Save Acknowledgment
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    }
                </Formik>
            </Paper>

            <Box sx={{textAlign: 'center', mt: 3}}>
                <Button
                    startIcon={<NavigateBeforeIcon/>}
                    variant={'outlined'}
                    onClick={() => cbState.baseData.setActiveStep(3, cbState.baseData.activeStep)}
                >
                    Previous
                </Button>
                <Button
                    sx={{ml: 3}}
                    disabled={status <= 3}
                    startIcon={<NavigateNextIcon/>}
                    variant={'contained'}
                    onClick={() => cbState.baseData.setActiveStep(5, cbState.baseData.activeStep)}
                >
                    Next
                </Button>
            </Box>
        </>
    )
}