import {useContext, useReducer, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Box, Button, Container, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography} from "@mui/material";
import {CBContext, cbContextHandler} from "./CBContext";
import CBFormStepper, {getActiveStep} from "./CBFormStepper";
import CBDetailForm from "./CBDetailForm";
import {formData} from "./initialValues";
import BackButton from "../utils/components/BackButton";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import UdinStatusForm from "./UdinStatusForm";
import NoteOnCertificationForm from "./NoteOnCertificationForm";
import CommentForm from "./CommentForm";
import CancellationForm from "./CancellationForm";
import CommentIcon from '@mui/icons-material/Comment';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MailSent from "./MailSent";
import UndoIcon from '@mui/icons-material/Undo';
import UpdateStatus from "./UpdateStatus";
import {UserDetails} from "../utils/UserContext";
import {adminUser} from "../utils/constants/UserTypes";

export function ActionButton() {
    const {cbState, cbDispatch} = useContext(CBContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const user = useContext(UserDetails);

    const handleClose = (modalType) => {
        setAnchorEl(null);
        cbDispatch({
            type: 'addBaseData',
            data: {modalType: modalType}
        });
    };

    return (
        <>
            <Button
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant={'contained'}
                sx={{
                    ml: 3
                }}
            >
                Actions
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        mt: 1,
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    sx={{color: 'primary.main'}}
                    onClick={() => handleClose('comments')}>
                    <ListItemIcon>
                        <CommentIcon sx={{color: 'primary.main'}}/>
                    </ListItemIcon>
                    Comments
                </MenuItem>
                <MenuItem disabled={cbState.baseData.status < 5} sx={{color: 'primary.main'}} onClick={() => handleClose('udinStatus')}>
                    <ListItemIcon sx={{color: 'primary.main'}}>
                        <SettingsEthernetIcon/>
                    </ListItemIcon>
                    UDIN Status
                </MenuItem>
                <MenuItem disabled={(cbState.baseData.status < 5) || (cbState.baseData.status === 7)} sx={{color: 'primary.main'}} onClick={() => handleClose('mailSent')}>
                    <ListItemIcon sx={{color: 'primary.main'}}>
                        <MarkEmailReadIcon/>
                    </ListItemIcon>
                    Mail Sent
                </MenuItem>
                <MenuItem sx={{color: 'primary.main'}} disabled={(cbState.baseData.status < 3) || (cbState.baseData.status === 7)} onClick={() => handleClose('noteOnCertification')}>
                    <ListItemIcon>
                        <NoteAltIcon sx={{color: 'primary.main'}}/>
                    </ListItemIcon>
                    Note on Certification
                </MenuItem>
                <MenuItem sx={{color: 'primary.main'}} disabled={cbState.baseData.status === 7} onClick={() => handleClose('cancel')}>
                    <ListItemIcon>
                        <CancelIcon sx={{color: 'primary.main'}}/>
                    </ListItemIcon>
                    Cancel Transaction
                </MenuItem>
                {
                    user.user_type >= adminUser && <>
                        <MenuItem sx={{color: 'primary.main'}} disabled={(cbState.baseData.status < 2) || (cbState.baseData.status === 7)} onClick={() => handleClose('updateStatus')}>
                            <ListItemIcon>
                                <UndoIcon sx={{color: 'primary.main'}}/>
                            </ListItemIcon>
                            Update Status
                        </MenuItem>
                    </>
                }
            </Menu>
        </>
    )
}

function HeaderButton() {
    const {cbState} = useContext(CBContext);
    const navigate = useNavigate();
    const {remittanceRef} = cbState.baseData;

    switch (cbState.baseData.status) {
        case 0:
            return <>
                <Button
                    variant={'contained'}
                    startIcon={<CancelIcon/>}
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </Button>

                <Tooltip title={'Can create a transaction from Remittance page'} placement={'bottom'}>
                    <span>
                        <Button
                            disabled={cbState.baseData.activeStep < 2}
                            variant={'contained'}
                            sx={{ml: 3}}
                            startIcon={<AddIcon/>}
                            onClick={() => {
                                remittanceRef.current.submitForm();
                            }}
                        >
                            Create
                        </Button>
                    </span>
                </Tooltip>
            </>
        default:
            return <ActionButton/>
    }
}

export default function CBForm() {

    // Manage state of the all steps;

    const location = useLocation();
    const status = location.state?.status;
    const remitterId = location.state?.remitter;
    const remitteeId = location.state?.remittee;
    const accountantId = location.state?.accountant;
    const transactionId = location.state?.transaction;
    const navigateOnSuccess = location.state?.navigateOnSuccess;
    const cloning = Boolean(location.state?.cloning);

    const remitterRef = useRef();
    const remitteeRef = useRef();
    const accountantRef = useRef();
    const invoicesRef = useRef();
    const remittanceRef = useRef();

    const baseData = {
        remitteeId: remitteeId,
        remitterId: remitterId,
        accountantId: accountantId,
        transactionId: transactionId,
        status: status,
        cloning: cloning,
        activeStep: getActiveStep(status),
        setActiveStep: setActiveStep,
        remitterRef: remitterRef,
        remitteeRef: remitteeRef,
        accountantRef: accountantRef,
        invoicesRef: invoicesRef,
        remittanceRef: remittanceRef,
        navigateOnSuccess: navigateOnSuccess,
        modalType: '',
        pages: [
            {
                visited: false
            },
            {
                visited: false
            },
            {
                visited: false
            },
            {
                visited: false
            },
            {
                visited: false
            },
            {
                visited: false
            },
        ]
    }

    let formDataModified = formData;
    formDataModified.remittance = formDataModified.remittanceFunc();
    formDataModified.invoices.invoices[0] = formDataModified.invoiceFunc();
    const [cbState, cbDispatch] = useReducer(cbContextHandler, {baseData: baseData, formData: formDataModified});

    function setActiveStep(val, activeStep) {
        let pages = cbState.baseData.pages;
        pages[activeStep].visited = true;
        cbDispatch({
            type: 'addBaseData',
            data: {
                activeStep: val,
                pages: pages

            }
        });
    }

    return (
        <CBContext.Provider value={{cbState: cbState, cbDispatch: cbDispatch}}>
            <Toolbar>
                <BackButton to={cbState.baseData.navigateOnSuccess}/>
                <Typography variant={"navbarTitle"}>{status === 0 ? 'Create' : 'Edit'} Transaction</Typography>
                <Box sx={{
                    position: 'absolute',
                    top: '12px',
                    right: '40px'
                }}>
                    <HeaderButton/>
                </Box>
            </Toolbar>
            <Box
                sx={{
                    position: "relative",
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 128px)'
                }}
            >
                <Container>
                    <CBFormStepper/>
                    <Box sx={{mt: 4}}>
                        <CBDetailForm/>
                        <UdinStatusForm/>
                        <NoteOnCertificationForm/>
                        <CommentForm/>
                        <CancellationForm/>
                        <MailSent/>
                        <UpdateStatus/>
                    </Box>
                </Container>
            </Box>
        </CBContext.Provider>
    )
}