import React, {useContext, useEffect, useRef} from "react";
import {CBContext} from "./CBContext";
import {FormModal, getPatchValues} from "../utils/utils";
import {Box, Button, CircularProgress} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import API from "../utils/API";
import {ProgressContext} from "../utils/ProgressContext";
import {Form, Formik} from "formik";
import {noteOnCertificationSchema} from "./validations";
import TextField from "../utils/components/TextField";
import {generalAPI, removeNulls} from "./utils";


async function noteOnCertificationAPI(transactionID, data){
    const response = await API.post(`cbs/${transactionID}/note-on-certification/`, data);
    return response.data;
}

export default function NoteOnCertificationForm(){
    const {cbState, cbDispatch} = useContext(CBContext);
    const {modalType, transactionId, status} = cbState.baseData;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const formikRef = useRef();
    const queryClient = useQueryClient();

    const {data: cbData, isFetching: cbIsFetching} = useQuery(
        ['cbs', cbState.baseData.transactionId],
        () => generalAPI(`cbs/${cbState.baseData.transactionId}/`),
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: cbState.baseData.status >= 3,
            select: data => {
                data = removeNulls(data);
                return data;
            }
        }
    );

    useEffect(() => {
        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited && cbData && cbState.baseData.status !== 0 && !cbState.baseData.cloning) {
            cbDispatch({
                type: 'addUpdateFormData',
                data: {'noteOnCertification': cbData}
            })
        }
    }, [cbData, cbState.baseData.status, cbState.baseData.activeStep, cbState.baseData.pages, cbState.baseData.cloning, cbDispatch]);


    const mutation = useMutation(
        ['cbs', transactionId, 'note-on-certification'],
        data => noteOnCertificationAPI(transactionId, data),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                cbDispatch({
                    type: 'addBaseData',
                    data: {modalType: ''}
                });
                queryClient.invalidateQueries(['cbs', cbState.baseData.transactionId])
            }
        }
    )

    const footer = <>
        <Button variant={'outlined'} onClick={() => cbDispatch({
            type: 'addBaseData',
            data: {modalType: ''}
        })}>Close</Button>
        <Button sx={{ml: 3}} disabled={status < 3} onClick={() => formikRef.current.submitForm()} variant={'contained'}>Save</Button>
    </>

    return (
        <>
            <FormModal
                open={modalType === 'noteOnCertification'}
                title={'Note on Certification'}
                maxWidth={'xl'}
                width={'500px'}
                footer={footer}
            >
                <Formik
                    innerRef={formikRef}
                    initialValues={cbState.formData.noteOnCertification}
                    validationSchema={noteOnCertificationSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                    onSubmit={values => mutation.mutate(getPatchValues(cbState.updateData.noteOnCertification, values))}
                >
                    <Form>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '16px',
                                left: '150px'
                            }}
                        >
                            {cbIsFetching && <CircularProgress size={'2rem'}/>}
                        </Box>
                        <TextField
                            name={'note_on_certification'}
                            label={'Note on Certification'}
                            required
                            fullWidth
                            disabled={(status < 3) || (status === 7)}
                            select
                            options={{
                                "-1": "SELECT",
                                "0": 'NA',
                                "1": 'PENDING',
                                "2": 'SENT'
                            }}
                        />
                    </Form>
                </Formik>
            </FormModal>
        </>
    )
}