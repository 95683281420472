import API from "../utils/API";
import {Chip, Typography} from "@mui/material";
import TextField from "../utils/components/TextField";

export async function generalAPI(url) {
    const response = await API.get(url);
    return response.data;
}

export function removeNulls(data){
    for(let key in data){
        if(data[key] === null){
            data[key] = ''
        }
    }
    return data
}

export function makeNulls(variables){
    for(let key in variables){
        if (variables[key] === ''){
            variables[key] = null
        }
    }
    return variables;
}

export const cbStatusMapping = {
    0: 'CREATING',
    1: 'IN-PROGRESS',
    2: 'CHECKED',
    3: 'TOKEN ATTACHED',
    4: 'ACKNOWLEDGED',
    5: 'UDIN ATTACHED',
    6: 'MAIL SENT',
    7: 'CANCELED'
}

export default function CBStatus(props){
    switch (props.status){
        case 0:
            return <Chip label={cbStatusMapping[props.status]} sx={{
                backgroundColor: "#8e24aa",
                color: "white"
            }}/>
        case 1:
            return <Chip label={cbStatusMapping[props.status]} sx={{
                backgroundColor: "#9575cd",
                color: "white"
            }}/>
        case 2:
            return <Chip label={cbStatusMapping[props.status]} sx={{
                backgroundColor: "#3949ab",
                color: "white"
            }}/>
        case 3:
            return <Chip label={cbStatusMapping[props.status]} sx={{
                backgroundColor: "#4db6ac",
                color: "white"
            }}/>
        case 4:
            return <Chip label={cbStatusMapping[props.status]} sx={{
                backgroundColor: "#81c784",
                color: "white"
            }}/>
        case 5:
            return <Chip label={cbStatusMapping[props.status]} sx={{
                backgroundColor: "#00897b",
                color: "white"
            }}/>
        case 6:
            return <Chip label={cbStatusMapping[props.status]} sx={{
                backgroundColor: "#388e3c",
                color: "white"
            }}/>
        case 7:
            return <Chip label={cbStatusMapping[props.status]} sx={{
                backgroundColor: "error.main",
                color: "white"
            }}/>
        default:
            return <></>
    }
}

export function getDateWithDelta(days){
    const currentDate = new Date();

    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + days);

    return futureDate.toISOString().split('T')[0];
}

export function getPreviousDates(currentDate, format = 'yyyy-mm-dd') {
    // Previous Month
    const previousMonth = new Date(currentDate);
    previousMonth.setMonth(currentDate.getMonth() - 1);
    const firstDayOfPreviousMonth = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
    const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

    // Previous Quarter
    const previousQuarter = new Date(currentDate);
    previousQuarter.setMonth(currentDate.getMonth() - 3);
    const firstDayOfPreviousQuarter = new Date(previousQuarter.getFullYear(), Math.floor(previousQuarter.getMonth() / 3) * 3, 1);
    const lastDayOfPreviousQuarter = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3, 0);

    // Previous Year
    const previousYear = new Date(currentDate);
    previousYear.setFullYear(currentDate.getFullYear() - 1);
    const firstDayOfPreviousYear = new Date(previousYear.getFullYear(), 0, 1);
    const lastDayOfPreviousYear = new Date(currentDate.getFullYear() - 1, 11, 31);

    // Format the dates
    const formatDate = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();

        if (format === 'yyyy-mm-dd') {
            return `${yyyy}-${mm}-${dd}`;
        } else if (format === 'dd-mm-yyyy') {
            return `${dd}-${mm}-${yyyy}`;
        }
    };

    return {
        previousMonth: {
            start: formatDate(firstDayOfPreviousMonth),
            end: formatDate(lastDayOfPreviousMonth),
        },
        previousQuarter: {
            start: formatDate(firstDayOfPreviousQuarter),
            end: formatDate(lastDayOfPreviousQuarter),
        },
        previousYear: {
            start: formatDate(firstDayOfPreviousYear),
            end: formatDate(lastDayOfPreviousYear),
        },
    };
}

export async function accountantListAPI() {
    const response = await API.get('accountants/list/');
    return response.data;
}


export async function remitterListAPI() {
    const response = await API.get('remitters/list/');
    return response.data;
}


export async function remitteeListAPI(remitter_id) {
    const response = await API.get(`remittees/list/?remitter_id=${remitter_id}`);
    return response.data;
}

export function EntityDropdown(props) {
    const {api, label, name, ...otherProps} = props;
    return (
        <>
            {
                api.isSuccess ? <TextField
                    fullWidth
                    select
                    disabled={api.isLoading || api.isFetching}
                    name={name}
                    label={label}
                    options={api.data.reduce((acc, curval) => {
                        acc[curval.id] = curval.name;
                        return acc;
                    }, {'-1': 'Select'})}
                    apiError={api.error}
                    {...otherProps}
                    helperText={api.isFetching ? `Fetching ${label}` : ' '}
                /> : <Typography variant={'helpText'}>Fetching {label} List...</Typography>
            }
        </>
    )
}
