import {b64toBlob, downloadBlobFile, FormModal} from "../utils/utils";
import {Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import React, {useContext} from "react";
import {CBContext} from "./CBContext";

export default function ComputationModal(props){
    const {open, setOpen, computation} = props;
    const {cbState} = useContext(CBContext)

    const footer = <Box>
        <Button variant={'outlined'} onClick={() => setOpen(false)}>Close</Button>
        {
            computation.isSuccess && <>
                <Button
                    sx={{ml: 3}}
                    variant={'contained'}
                    startIcon={<DownloadIcon/>}
                    onClick={() => {
                        downloadBlobFile(`Computation - ${cbState.formData.remitter.name} - ${cbState.formData.remittee.name}.xlsx`, b64toBlob(computation.data.file));
                    }}>
                    Download Excel
                </Button>
            </>
        }
    </Box>

    return (
        <FormModal
            open={open}
            title={'Computation'}
            width={'1000px'}
            maxWidth={'xl'}
            footer={footer}
        >
            <>
                {
                    computation.isSuccess && <>
                        <Typography variant={'h6'}>
                            Exchange Rate: <b>{computation.data.cb.exchange_rate}</b>
                        </Typography>
                        <Table size={'small'} stickyHeader={true}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Particulars</TableCell>
                                    <TableCell>Amount[INR]</TableCell>
                                    <TableCell>Amount[FC]</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    computation.data.computation.map((line, index) => (
                                        <TableRow key={index} height={"32px"}>
                                            <TableCell sx={{
                                                py: 0,
                                                fontWeight: ['final', 'act_tax'].includes(line.key) ? '600' : '400'
                                            }}>{line.particular}</TableCell>
                                            <TableCell sx={{
                                                py: 0,
                                                fontWeight: ['final', 'act_tax'].includes(line.key) ? '600' : '400'
                                            }}>{line.indian_amt}</TableCell>
                                            <TableCell sx={{
                                                py: 0,
                                                fontWeight: ['final', 'act_tax'].includes(line.key) ? '600' : '400'
                                            }}>{line.frgn_amt}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </>
                }
            </>
        </FormModal>
    )
}