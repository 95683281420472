import * as Yup from "yup";
import {
    dropDownErrorMsg,
    lengthErrorMsg,
    maxLengthErrorMsg,
    minLengthErrorMsg
} from "../utils/constants/YupErrorMessages";
import {defaultAddressValidationSchema} from "../utils/constants/AddressFields";
import {ascii} from "../utils/utils";

export const validationSchema = defaultAddressValidationSchema.concat(Yup.object().shape({
    honorific: ascii.notOneOf(["-1"], dropDownErrorMsg).max(2, maxLengthErrorMsg).required('Honorific Details is required'),
    name: ascii.max(125, maxLengthErrorMsg).uppercase().required('Name is required'),
    state: ascii.max(125, maxLengthErrorMsg).required('State is required'),
    zipcode: ascii.max(8, maxLengthErrorMsg).required('Zipcode is required'),
    country_of_remittance: ascii.max(4, maxLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Country of Remittance'),
    country_of_remittance_other: ascii.max(125, maxLengthErrorMsg).when('currency_of_remittance', {
        is: val => val === '9999',
        then: schema => schema.required('Country of Remittance (Other)'),
        otherwise: schema => schema.nullable()
    }),
    currency_of_remittance: ascii.max(4, maxLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Currency of Remittance'),
    currency_of_remittance_other: ascii.max(75, maxLengthErrorMsg).uppercase().when('currency_of_remittance', {
        is: val => val === '99',
        then: schema => schema.required('Currency of Remittance (Other)'),
        otherwise: schema => schema.nullable()
    }),
    nature_of_remittance: ascii.max(5, maxLengthErrorMsg).min(4, minLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Nature of Remittance'),
    nature_of_remittance_other: ascii.max(125, maxLengthErrorMsg).uppercase().strict().when('currency_of_remittance', {
        is: val => val === '16.99',
        then: schema => schema.required('Nature of Remittance (Other)'),
        otherwise: schema => schema.nullable()
    }),
    relevant_purpose_code: ascii.max(7, maxLengthErrorMsg).min(6, minLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Relevant Purpose Code'),
    relevant_purpose_code_detail: ascii.max(13, maxLengthErrorMsg).min(12, minLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Relevant Purpose Code Detail'),
    is_ca_applicable: Yup.string().max(1, maxLengthErrorMsg).required('Is CA Applicable?'),
    pan: ascii.length(10, lengthErrorMsg).uppercase().nullable(),
    status: ascii.max(4, maxLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Status of Remittee is Required'),
        otherwise: schema => schema.nullable(),
    }),
    principal_place_of_business: ascii.max(125, maxLengthErrorMsg).uppercase().when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Principal Place of Business is Required'),
        otherwise: schema => schema.nullable(),
    }),
    email: ascii.email().max(125, maxLengthErrorMsg).nullable(),
    phone: ascii.max(10, maxLengthErrorMsg).min(10, minLengthErrorMsg).nullable(),
}));