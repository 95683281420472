import API from "../utils/API";
import {useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {alpha, Box, Breadcrumbs, Button, Container, Tab, Tabs, Toolbar, Tooltip, Typography} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import BackButton from "../utils/components/BackButton";
import {StatusChip} from "../utils/utils";
import TabPanel from "../utils/components/TabPanel";
import fetchingStatus from "../utils/components/QueryFetching";
import RemitteeForm from "./RemitteeForm";
import APIError from "../utils/components/APIError";
import withPermission from "../utils/components/PermissionRequired";
import {organizationalUser} from "../utils/constants/UserTypes";
import Link from "../utils/components/Link";
import RemitteeFileForm from "./RemitteeFileForm";
import RemitteeFileList from "./RemitteeFileList";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import CBList from "../cbs/CBList";
import UserLogging from "../utils/components/UserLogging";

export async function remitteeDetailAPI(id) {
    const response = await API.get(`remittees/${id}/`);
    return response.data;
}

function RemitteeDetail() {
    const [value, setValue] = useState(0);
    const [formType, setFormType] = useState('');
    const location = useLocation();
    let remittee = location.state?.remittee;
    let remitter = location.state?.remitter;
    const navigate = useNavigate();
    const fileUploadFormRef = useRef();

    function handleTabChange(e, newValue) {
        setValue(newValue);
    }

    const tabDesign = {
        position: 'absolute', left: '80px', top: 0
    }

    const tabsButtonDesign = {
        color: 'primary.main', '&.Mui-selected': {
            backgroundColor: (theme) => `${alpha(theme.palette.primary.main, 0.08)}`,
        }, height: '64px'
    }


    const query = useQuery(['remittees', remittee.id], () => remitteeDetailAPI(remittee.id), {
        enabled: Boolean(remittee.id), placeholderData: remittee
    });

    if (query.data) {

        const breadcrumbs = [<Link to={'/remitters'} key={1} underline={"none"}>Remitters</Link>,
            <Link to={'/remitters/detail'} key={2} state={{data: remitter, value: 2}}
                  underline={"none"}>{remitter.name}</Link>,
            <Link to={'/remitters/detail/'} key={3} state={{data: remitter, value: 0}}
                  underline={"none"}>Remittees</Link>,
            <Link to={'/remitters/remittees/detail/'} key={4} state={{remittee: query.data, remitter: remitter}}
                  underline={"none"} sx={{fontWeight: '600'}}>{query.data.name}</Link>];

        const disabled = query.data.usage_status >= 2

        const createTransactionButton = <Button
            disabled={disabled}
            variant={'contained'}
            onClick={() => {
                navigate('/cb/form', {
                    state: {
                        remitter: remitter.id,
                        remittee: query.data.id,
                        accountant: remitter.accountant,
                        status: 0
                    }
                })
            }}
        >
            Create Transactions
        </Button>

        const uploadFileButton = <Button
            disabled={disabled}
            startIcon={<FileUploadIcon/>}
            variant={'contained'}
            onClick={() => setFormType('FILES')}
        >
            Upload Files
        </Button>

        return (<>
            <Toolbar>
                <BackButton to={-1}/>
                <Tabs value={value} onClick={() => setFormType('')} onChange={handleTabChange} sx={tabDesign}>
                    <Tab
                        sx={tabsButtonDesign}
                        label={"Transactions"}
                        value={0}
                    />
                    <Tab
                        sx={tabsButtonDesign}
                        label={"Files"}
                        value={1}
                    />
                    <Tab
                        label={"Details"}
                        sx={tabsButtonDesign}
                        value={2}
                    />
                </Tabs>
                <Typography variant={"navbarTitle"}>{query.data.name}</Typography>
                <StatusChip sx={{ml: 2}} status={query.data.usage_status}/>
                <Box sx={{
                    position: 'absolute', top: '12px', right: '40px'
                }}>
                    <>
                        {value === 0 && <>
                            {
                                disabled ? <>
                                    <Tooltip title={'Can not create Transaction until the remittee is \'OPERATIVE\''}>
                                    <span>
                                        {createTransactionButton}
                                    </span>
                                    </Tooltip>
                                </> : <>{createTransactionButton}</>
                            }
                            </>
                        }
                    </>
                    {value === 1 && formType === '' &&
                        <>
                            {
                                disabled ? <>
                                    <Tooltip title={'Can not create upload files until the remittee is \'OPERATIVE\''}>
                                    <span>
                                        {uploadFileButton}
                                    </span>
                                    </Tooltip>
                                </> : <>{uploadFileButton}</>
                            }
                        </>
                    }
                    {formType === 'FILES' && <>
                        <Button
                            startIcon={<CloseIcon/>}
                            variant={'contained'}
                            onClick={() => {
                                setFormType('');
                                setValue(1);
                            }}
                        >
                            Discard
                        </Button>
                        <Button
                            startIcon={<FileUploadIcon/>}
                            sx={{ml: 3}}
                            variant={'contained'}
                            onClick={() => fileUploadFormRef.current.submitForm()}
                        >
                            Upload
                        </Button>
                    </>}
                </Box>
            </Toolbar>
            <Box
                sx={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 128px)'
                }}
            >
                <Container sx={{position: "relative"}}>
                    <Breadcrumbs separator={'>'}>
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    </Box>
                    {formType === '' && (<>
                        <TabPanel index={0} value={value}>
                            <CBList params={{
                                template_remittee: remittee.id, template_remitter: remitter.id
                            }}/>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Box
                                sx={{
                                    position: 'absolute', right: "44px", top: "24px"
                                }}
                            >
                                <Typography variant={"helpText"}>{fetchingStatus(query)}</Typography>
                            </Box>
                            <RemitteeForm data={query.data}/>
                            <UserLogging data={query.data}/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <RemitteeFileList remittee={remittee}/>
                        </TabPanel>
                    </>)}
                    {formType === 'FILES' && (
                        <RemitteeFileForm ref={fileUploadFormRef} data={query.data} closeUpload={() => {
                            setFormType('');
                            setValue(1)
                        }}/>)}
                </Container>
            </Box>
        </>)
    }

    if (query.error) {
        return <APIError error={query.error}/>
    }

    return <></>
}

export default withPermission(RemitteeDetail, organizationalUser);