import Image from "./HomeImage.svg"
import {Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import Typewriter from 'typewriter-effect';

export default function Home() {
    const iconProps = {
        fontSize: 'large',
        sx: {bgcolor: '#c53143', borderRadius: '50%', p: 1, color: "white"}
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                px: {
                    xs: 2,
                    lg: 8
                },
                py: 2,
                gap: 4
            }}
        >
            <Grid
                container
                component={Box}
                columnSpacing={6}
                rowSpacing={4}
            >
                <Grid
                    item
                    xs={12}
                    lg={7}
                    sx={{
                        display: 'flex',
                        gap: 4,
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        data-aos={'fade-right'}
                        variant={"h2"}
                        sx={{
                            color: "#c53143",
                            pt: {
                                lg: 10
                            },
                        }}
                    >
                        {'BGSS & Associates'}
                    </Typography>
                    <Typography
                        data-aos={'fade-right'}
                        sx={{
                            pt: {
                                lg: 5
                            },
                            fontSize: {
                                lg: '1.5em',
                                sm: '1.2em'
                            }
                        }}>
                        BGSS & Associates offers a wide range of services in the tax and regulatory space to clients in
                        India and around the world.
                    </Typography>
                    <Box
                        sx={{mt: 5}}
                        data-aos={'fade-right'}
                    >
                        <Typography
                            variant={'h5'}
                        >
                            We strive for
                        </Typography>
                        <Typography
                            sx={{
                                color: "#c53143",
                                fontSize: {
                                    lg: '1.5em',
                                    sm: '1.2em'
                                },
                            }}
                        >
                            <Typewriter
                                options={{
                                    strings: ['Efficiency', 'Consistency', 'Increasing Productivity', 'Providing easy data retrieval'],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </Typography>

                    </Box>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    item
                    lg={5}
                    xs={12}
                    textAlign={'center'}
                >
                    <img data-aos={'fade-in'} width={'100%'} src={Image} alt={"Link Has expired"}/>
                </Grid>
            </Grid>
            <Box>
                <Divider/>
            </Box>
            <Box
                data-aos={'fade-left'}
            >
                <Typography
                    variant={"h4"}
                    sx={{
                        color: "#c53143",
                        textAlign: 'center'
                    }}
                >
                    Service
                </Typography>
                <Typography
                    sx={{
                        py: 4,
                        px: {
                            xl: 40,
                            md: 20,
                            xs: 0
                        },
                        fontSize: {
                            lg: '1.5em',
                            sm: '1.2em'
                        },
                        textAlign: 'center'
                    }}>
                    <Box
                        sx={{
                        }}
                    >
                        Core of service organizations is his knowledge and ability to turn around in the shortest
                        possible time. BGSS Console brings technology closer to tax function. IT tools are developed
                        after a deep understanding of the subject and numerous hours of work experience.
                    </Box>
                </Typography>
            </Box>
            <Box>
                <Divider/>
            </Box>
            <Box>
                <Typography
                    variant={"h4"}
                    sx={{
                        color: "#c53143",
                        textAlign: 'center'
                    }}
                >
                    Contact Us
                </Typography>
                <Box sx={{
                    display: 'flex',
                    gap: 3,
                    flexDirection: 'column',
                }}>
                    <Grid container rowSpacing={3} columnSpacing={4} data-aos={'fade-up'}>
                        <Grid item lg={12} xs={12}>
                            <Typography variant={'h5'}>
                                Mumbai
                            </Typography>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon><CallIcon {...iconProps}/></ListItemIcon>
                                    <ListItemText
                                        primary={'Phone'}
                                        secondary={'+91 98339 15583'}
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon><EmailIcon {...iconProps}/></ListItemIcon>
                                    <ListItemText
                                        primary={'Email'}
                                        secondary={'bhaumik@bgss.co.in'}
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon><PlaceIcon {...iconProps}/></ListItemIcon>
                                    <ListItemText
                                        primary={'Address'}
                                        secondary={'605, Zee Nayak, M.G. Road,\n' +
                                            'Vile Parle (East),\n' +
                                            'Mumbai – 400 057'}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <iframe
                                data-aos={'fade-up'}
                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3770.1589074427084!2d72.84368987575786!3d19.100683482109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1699290167567!5m2!1sen!2sin"
                                loading={"lazy"}
                                width={'100%'}
                                height={'100%'}
                                title={'Mumbai Map'}
                                style={{
                                    border: '0',
                                    borderRadius: '20px'
                                }}
                            >

                            </iframe>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={4} data-aos={'fade-up'}>
                        <Grid item lg={12} xs={12}>
                            <Typography variant={'h5'}>
                                Ahemdabad
                            </Typography>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon><CallIcon {...iconProps}/></ListItemIcon>
                                    <ListItemText
                                        primary={'Phone'}
                                        secondary={'+91 90999 27783'}
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon><EmailIcon {...iconProps}/></ListItemIcon>
                                    <ListItemText
                                        primary={'Email'}
                                        secondary={'saumya@bgss.co.in'}
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon><PlaceIcon {...iconProps}/></ListItemIcon>
                                    <ListItemText
                                        primary={'Address'}
                                        secondary={'604, Shiti Ratna Building,\n' +
                                            'Panchwati Circle, C.G Road,\n' +
                                            'Ahmedabad – 380 009'}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <iframe
                                src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.0169657049037!2d72.55409947531379!3d23.023149279173435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84fb27dba6a7%3A0x70a6b8f668ec6ed0!2sShiti%20Ratna!5e0!3m2!1sen!2sin!4v1699289579900!5m2!1sen!2sin"}
                                loading="lazy"
                                width={'100%'}
                                height={'100%'}
                                title={'Ahemdabad Map'}
                                style={{
                                    border: '0',
                                    borderRadius: '20px'
                                }}
                            >
                            </iframe>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}