import * as Yup from "yup";
import {maxLengthErrorMsg} from "../utils/constants/YupErrorMessages";
import {isOrganizationalEmail} from "../utils/utils";

const USER_TYPE_ORGANIZATION_MEMBER = 'User within the organization should be either \'Organizational User\', \'Admin\', or \'Super Admin\''

const validationSchema = Yup.object().shape({
    name: Yup.string().max(150, maxLengthErrorMsg).required('Name is required'),
    email: Yup.string().email('Enter a valid email address').required('Email ID is required'),
    is_active: Yup.boolean(),
    user_type: Yup.string().when('email', {
        is: val => isOrganizationalEmail(val),
        then: schema => schema.notOneOf(['1'], USER_TYPE_ORGANIZATION_MEMBER),
        otherwise: schema => schema.oneOf(['1'], 'User is outside of organization'),
    }),
});


export default validationSchema;
