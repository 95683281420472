import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from "react";
import * as Yup from "yup";
import API from "../utils/API";
import {ProgressContext} from "../utils/ProgressContext";
import {useMutation} from "@tanstack/react-query";
import {FieldArray, Form, Formik} from "formik";
import {Alert, alpha, Box, Button, Divider, Grid, IconButton, Paper, Tab, Tabs, Typography} from "@mui/material";
import {getSize} from "../utils/utils";
import APIError, {AdditionalAPIError} from "../utils/components/APIError";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import FileField from "../utils/components/FileField";
import AutoCompleteField from "../utils/components/AutoCompleteField";
import {useLocation} from "react-router-dom";


let fileFields = {
    file: '', file_type: ''
}

let initialValues = {
    files: [fileFields]
}

const validationSchema = Yup.object().shape({
    files: Yup.array().of(Yup.object().shape({
        file: Yup.mixed().required('File is Required'),
        file_type: Yup.string().required('File Type is required'),
    }))
});

async function cbFilesAPI(data) {
    const response = await API.post('cbs/files/', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }, formSerializer: {
            dots: true
        }
    });
    return response.data;
}

async function caPartCFilesAPI(data) {
    const response = await API.post('cbs/cas/files/', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }, formSerializer: {
            dots: true
        }
    });
    return response.data;
}


function FileForm(props) {
    const {index} = props;

    return (<Grid container columnSpacing={5} rowSpacing={4}>
        <Grid item xs={6}>
            <FileField
                fullWidth
                required
                name={`files.${index}.file`}
                label={'Upload a File'}
                helperText={'Maximum file size of 10MB'}
            />
        </Grid>
        <Grid item xs={6}>
            <AutoCompleteField
                required
                label={'File Type'}
                name={`files.${index}.file_type`}
                options={['Exchange Rate ServiceCard', 'Note on Certification', 'Acknowledgement Screenshot', 'UDIN Screenshot', 'Token Screenshot']}
            />
        </Grid>
    </Grid>)
}


const CBFileForm = forwardRef((props, ref) => {
    const {closeUpload} = props;
    const [value, setValue] = useState(0);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const formikRef = useRef(null);
    const location = useLocation();
    const ca = location.state?.ca;

    const cbFileUpload = useMutation(
        ['cbs', 'files'],
        cbFilesAPI,
        {
            onMutate: variables => {
                handleProgressOpen();
                const files = variables['files'].map(file => {
                    file['cb'] = props.transactionId;
                    return file;
                });
                variables['files'] = files;
            },
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                closeUpload();
            }
        });

    const caPartCFileUpload = useMutation(
        ['cbs', 'cas', 'files'],
        caPartCFilesAPI,
        {
            onMutate: variables => {
                handleProgressOpen();
                const files = variables['files'].map(file => {
                    file['ca'] = props.transactionId;
                    return file;
                });
                variables['files'] = files;
            },
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                closeUpload();
            }
        });

    const tabsButtonDesign = {
        color: 'primary.main',
        height: '64px',
        maxWidth: '100%',
        flexGrow: 1,
        '&.Mui-selected': {
            backgroundColor: (theme) => `${alpha(theme.palette.primary.main, 0.08)}`,
        }
    }

    function handlePageChange(e, newValue) {
        setValue(newValue);
    }

    function submitForm() {
        formikRef.current.submitForm();
    }

    useImperativeHandle(ref, () => {
        return {
            submitForm
        }
    });

    const isError = cbFileUpload.isError || caPartCFileUpload.isError;
    const error = cbFileUpload.error || caPartCFileUpload.error;

    return (
        <Box>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validateOnChange={false}
                validationSchema={validationSchema}
                onSubmit={values => {
                    if (value === 0) {
                        cbFileUpload.mutate(values)
                    } else if (value === 1) {
                        caPartCFileUpload.mutate(values)
                    }
                }}
            >
                {formik => (<Paper sx={{mx: 35}}>
                    <Box sx={{mb: 1}}>
                        <Tabs value={value} onChange={handlePageChange}>
                            <Tab
                                sx={tabsButtonDesign}
                                label={"15CB Files"}
                                value={0}
                            />
                            <Tab
                                sx={tabsButtonDesign}
                                label={"15CA-PartC Files"}
                                value={1}
                            />
                        </Tabs>
                    </Box>
                    <Box sx={{px: 3, pb: 3}}>
                        {
                            (value === 0) || (value === 1 && ca !== null) ? <>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>
                                        <Typography variant={'h6'} sx={{color: 'grey.700'}}>
                                            Upload {value === 0 ? '15CB' : '15CA-PartC'} Files
                                        </Typography>
                                        <Typography variant={'helpText'}>
                                            Total size of all files shouldn't be more than 50MB
                                        </Typography>
                                    </Box>
                                    <Box sx={{textAlign: 'end', mt: 1}}>
                                        {getSize(formik.values.files.reduce((total, file) => total + (file.file?.size ? file.file.size : 0), 0))}
                                        <Typography variant={'helpText'}>
                                            Total File size:
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{my: 1}}/>
                                <Form>
                                    <Box sx={{mt: 2}}>
                                        {
                                            isError && <Box my={2}>
                                                <APIError error={error}/>
                                                <AdditionalAPIError apiError={error} field={'File'}/>
                                            </Box>
                                        }
                                    </Box>
                                    <FieldArray name={'files'}>
                                        {arrayHelpers => (<>
                                            {formik.values.files.map((file, index) => (<React.Fragment key={index}>
                                                <Paper
                                                    sx={{
                                                        pt: 2,
                                                        px: 4,
                                                        pb: 4,
                                                        position: 'relative',
                                                        mb: 2,
                                                        boxShadow: '0',
                                                        border: '1px solid',
                                                        borderColor: 'grey.400'
                                                    }}
                                                >
                                                    {index > 0 && <IconButton
                                                        sx={{
                                                            position: 'absolute', right: '16px', top: '16px'
                                                        }}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        <CloseIcon/>
                                                    </IconButton>}
                                                    <Typography sx={{mb: 1, color: 'grey.700'}}
                                                                variant={'h6'}>File {index + 1}</Typography>
                                                    <FileForm
                                                        index={index}
                                                        arrayHelpers={arrayHelpers}
                                                        apiError={cbFileUpload.error}
                                                    />
                                                </Paper>
                                            </React.Fragment>))}
                                            <Button startIcon={<AddIcon/>}
                                                    onClick={() => arrayHelpers.push(fileFields)}>Add
                                                File</Button>
                                        </>)}
                                    </FieldArray>
                                </Form>
                            </> : <Alert sx={{mt: 2}} severity={'warning'}>
                                Can not upload files for 15CA-PartC until it is
                                created
                            </Alert>
                        }
                    </Box>
                </Paper>)}
            </Formik>
        </Box>
    )
});


export default CBFileForm;