import {MenuItem, TextField as MuiTextField} from "@mui/material";
import {useField} from "formik";
import {useFormikFieldError} from "../utils";
import {useId} from "react";

export default function TextField(props) {
    const [field, meta, helpers] = useField(props);
    const {onChange, value, ...otherFields} = field;
    const {
        helperText = ' ',
        select = false,
        upperCase = false,
        lowerCase = false,
        isReading = false,
        options = null,
        apiError,
        name,
        sx,
        ...otherProps
    } = props;
    const id = useId();

    const {error, textFieldHelperText} = useFormikFieldError(meta, name, apiError, helperText)

    function handleChange(e) {
        if (upperCase) {
            helpers.setValue(e.target.value.toUpperCase(), true);
        } else if (lowerCase) {
            helpers.setValue(e.target.value.toLowerCase(), true);
        } else {
            onChange(e);
        }
    }

    return (
        <MuiTextField
            select={select}
            error={error}
            variant={'standard'}
            size={"small"}
            id={id}
            InputProps={{
                readOnly: isReading,
                disableUnderline: isReading,
            }}
            helperText={textFieldHelperText}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            value={value !== null ? value : ''}
            {...otherProps}
            {...otherFields}
            sx={{
                "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                ...sx
            }}
            FormHelperTextProps={{
                component: "div"
            }}
        >
            {
                select && options && Object.entries(options).map(([key, value]) => (
                    <MenuItem
                        key={key}
                        value={key}
                    >
                        {value}
                    </MenuItem>
                ))
            }
        </MuiTextField>
    )

}