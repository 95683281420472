import {Form, Formik} from "formik";
import {Box, Button, Grid, Typography} from "@mui/material";
import TextField from "../utils/components/TextField";
import * as Yup from "yup";
import RadioField from "../utils/components/RadioField";
import {useMutation, useQuery} from "@tanstack/react-query";
import API from "../utils/API";
import {useContext, useRef, useState} from "react";
import {accountantListAPI, EntityDropdown, getPreviousDates, remitteeListAPI, remitterListAPI} from "./utils";
import {b64toBlob, downloadBlobFile, downloadHelper, parseDate} from "../utils/utils";
import {ProgressContext} from "../utils/ProgressContext";
import {LabeledProgressContext} from "../utils/LabeledProgressContext";
import APIError from "../utils/components/APIError";


async function summaryAPI(url) {
    const response  = await API.post(url);
    return response.data;
}


export default function SummaryFilters(props) {
    const {setSummaryOpen} = props;
    const [remitter, setRemitter] = useState('-1');
    const formikRef = useRef();
    const dates = getPreviousDates(new Date(), 'dd-mm-yyyy');
    const datesFormatted = getPreviousDates(new Date());
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const {handleLabeledProgressOpen, handleLabeledProgressClose, setProgressValue} = useContext(LabeledProgressContext)

    const initialValues = {
        fixed_date: '0',
        start_date: datesFormatted.previousMonth.start,
        end_date: datesFormatted.previousMonth.end,
        accountant: '-1',
        remitter: '-1',
        remittee: '-1',
        client_copy: 'N',
        filter_by: 'acknowledgement_date',
        files: 'N'
    }

    const validationSchema = Yup.object().shape({
        start_date: Yup.date().required(),
        end_date: Yup.date().required()
    });

    const accountantList = useQuery(
        ['accountants', 'list'],
        accountantListAPI,
        {
            staleTime: 3600000,
        }
    )

    const remitterList = useQuery(
        ['remitters', 'list'],
        remitterListAPI,
        {
            staleTime: 3600000,
        }
    )

    const remitteeList = useQuery(
        ['remittees', 'list', remitter],
        () => remitteeListAPI(remitter),
        {
            staleTime: 3600000,
            enabled: remitter !== '-1'
        }
    );


    const summary = useMutation(
        ['cbs', 'summary'],
        (data) => {
            const keys = Object.entries(data).map(([key, value]) => `${key}=${value}`);
            const url = `cbs/summary/?${keys.join('&')}`;

            if (data['files'] === 'N'){
                return summaryAPI(url)
            }
            else {
                return downloadHelper(url, {}, setProgressValue, handleLabeledProgressClose)
            }
        },
        {
            onMutate: variables => {
                if (variables['files'] === 'N'){
                    handleProgressOpen()
                }
                else {
                    handleLabeledProgressOpen()
                }
            },
            onSuccess: (data, variables, context) => {
                if (variables.files === 'Y'){
                    downloadBlobFile(`Summary ${parseDate(variables.start_date)} - ${parseDate(variables.end_date)}.zip`, data);
                }
                else {
                    downloadBlobFile(`Summary ${parseDate(variables.start_date)} - ${parseDate(variables.end_date)}.xlsx`, b64toBlob(data.file));
                }
                setSummaryOpen(false);
            },
            onSettled: (data, error, variables) => {
                if (variables['files'] === 'N'){
                    handleProgressClose()
                }
                else {
                    handleLabeledProgressClose()
                }
            }
        }
    )

    function handleFixedDateChange(formik, e){
        let option = e.target.value;
        switch (option){
            case '0': {
                formik.setFieldValue('start_date', datesFormatted.previousMonth.start);
                formik.setFieldValue('end_date', datesFormatted.previousMonth.end);
                break
            }
            case '1': {
                formik.setFieldValue('start_date', datesFormatted.previousQuarter.start);
                formik.setFieldValue('end_date', datesFormatted.previousQuarter.end);
                break
            }
            case '2': {
                formik.setFieldValue('start_date', datesFormatted.previousYear.start);
                formik.setFieldValue('end_date', datesFormatted.previousYear.end);
                break
            }
            default: {

            }
        }
    }

    return (
        <>
            {
                summary.isError &&
                <Box>
                    <APIError error={summary.error}/>
                </Box>
            }
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={summary.mutate}
            >
                {
                    formik => (
                        <Form>
                            <Grid container columnSpacing={4} rowSpacing={4}>
                                <Grid item xs={6}>
                                    <RadioField
                                        name={'filter_by'}
                                        label={'Filter By'}
                                        options={[['acknowledgement_date', 'Acknowledgement Date'], ['creation_date', 'Creation Date']]}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioField
                                        name={'client_copy'}
                                        label={'Client Copy'}
                                        options={[['Y', 'Yes'], ['N', 'No']]}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioField
                                        name={'fixed_date'}
                                        label={'Date Range'}
                                        onChange={(e) => handleFixedDateChange(formik, e)}
                                        options={[
                                            [
                                                '0',
                                                <Box display={'flex'} alignItems={'center'}>
                                                    Previous Month
                                                    <Typography component={'span'} sx={{ml: 1}} variant={'helpText'}>
                                                        ({dates.previousMonth.start} - {dates.previousMonth.end})
                                                    </Typography>
                                                </Box>
                                            ],
                                            [
                                                '1',
                                                <Box display={'flex'} alignItems={'center'}>
                                                    Previous Quarter
                                                    <Typography component={'span'} sx={{ml: 1}} variant={'helpText'}>
                                                        ({dates.previousQuarter.start} - {dates.previousQuarter.end})
                                                    </Typography>
                                                </Box>
                                            ],
                                            [
                                                '2',
                                                <Box display={'flex'} alignItems={'center'}>
                                                    Previous Year
                                                    <Typography component={'span'} sx={{ml: 1}} variant={'helpText'}>
                                                        ({dates.previousYear.start} - {dates.previousYear.end})
                                                    </Typography>
                                                </Box>
                                            ],
                                            [
                                                '3',
                                                'Custom'
                                            ]
                                        ]}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                                {
                                    formik.values.fixed_date === '3' && (
                                        <>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type={'date'}
                                                    name={'start_date'}
                                                    label={'Start Date'}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type={'date'}
                                                    name={'end_date'}
                                                    label={'End Date'}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    fullWidth
                                                    required
                                                />
                                            </Grid>
                                        </>
                                    )
                                }
                                <Grid item xs={6}>
                                    <EntityDropdown
                                        label={'Accountant'}
                                        name={'accountant'}
                                        api={accountantList}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <EntityDropdown
                                        label={'Remitter'}
                                        name={'remitter'}
                                        api={remitterList}
                                        onChange={(e) => {
                                            formik.setFieldValue('remitter', e.target.value);
                                            setRemitter(e.target.value);
                                        }}
                                    />
                                </Grid>
                                {
                                    formik.values.remitter !== '-1' && <Grid item xs={6}>
                                        <EntityDropdown
                                            label={'Remittee'}
                                            name={'remittee'}
                                            api={remitteeList}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <RadioField
                                        name={'files'}
                                        label={'Include Files'}
                                        options={[['Y', 'Yes'], ['N', 'No']]}
                                        required
                                    />
                                </Grid>
                            </Grid>

                            <Box display={'flex'} gap={3} justifyContent={'center'} mt={3}>
                                <Button variant={'outlined'} onClick={() => setSummaryOpen(false)}>Close</Button>
                                <Button variant={'contained'} type={'submit'} disabled={!formik.isValid}>Get Summary</Button>
                            </Box>

                        </Form>
                    )
                }
            </Formik>
        </>
    )
}