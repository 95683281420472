import React, {useContext, useState} from "react";
import {CAContext} from "./CAContext";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    List,
    ListItemText,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemitterForm from "./RemitterForm";
import RemitteeForm from "./RemitteeForm";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function Entity(){
    const {caState} = useContext(CAContext);
    const {remitterRef, remitteeRef, setActiveStep} = caState.baseData;
    const [errors, setErrors] = useState(null);

    function handleEntitySave(){
        let remitter = remitterRef.current.validateForm();
        let remittee = remitteeRef.current.validateForm();

        Promise.all([remitter, remittee]).then((res) => {
            const individualErrorCounts = res.map(err => Object.keys(err).length);
            if (remitterRef.current.values['is_ca_applicable'] === 'N'){
                individualErrorCounts[0] = 1;
            }
            if (remitteeRef.current.values['is_ca_applicable'] === 'N'){
                individualErrorCounts[1] = 1;
            }
            const errorCounts = individualErrorCounts.reduce((sum, count) => sum + count, 0);

            if (errorCounts === 0) {
                setActiveStep(1, 0);
            } else {
                setErrors(individualErrorCounts)
            }
        });
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column'
                }}
            >
                {
                    errors && <Alert
                        severity={'error'}
                        icon={false}
                        onClose={() => setErrors(null)}
                    >
                        <List disablePadding={true}>
                            {errors[0] !== 0 && <ListItemText>Error in <b>Remitter</b><br/></ListItemText>}
                            {errors[1] !== 0 && <ListItemText>Error in <b>Remittee</b><br/></ListItemText>}
                        </List>

                    </Alert>
                }
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant={'h5'}>Remitter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RemitterForm/>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant={'h5'}>Remittee</Typography>
                    </AccordionSummary
                    >
                    <AccordionDetails>
                        <RemitteeForm/>
                    </AccordionDetails>
                </Accordion>
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Button
                        variant={'contained'}
                        endIcon={<NavigateNextIcon/>}
                        onClick={handleEntitySave}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </>
    )
}