import {defaultAddressFields} from "../utils/constants/AddressFields";
import {getDateWithDelta} from "./utils";

function getInitialRemittanceForm(){
    return {
        mail_received: getDateWithDelta(0),
        is_system_calculation_applicable: 'Y',
        rounding: '2',
        amt_payable_inr: '',
        amt_payable_frgn: '',
        exchange_rate: '',
        expenses_reimbursement_frgn: '0',
        proposed_date_of_remittance: getDateWithDelta(15),
        is_grossed_up: 'N',
        is_it_taxable: 'Y',
        it_relevant_section_act: '',
        it_basis: '',
        it_taxable_income: '',
        it_tax_liability: '',
        it_rate: '',
        it_not_taxable_reason: '',
        is_dtaa_taxable: 'Y',
        dtaa_act: '',
        dtaa_article: '',
        dtaa_taxable_income: '',
        dtaa_tax_liability: '',
        dtaa_section: '0',
        dtaa_rate: '',
        dtaa_sect_a_rate: '',
        dtaa_sect_a_article: '',
        is_dtaa_sect_b_taxable: '-1',
        dtaa_sect_b_taxable_basis: '',
        dtaa_sect_b_non_taxable_article: '',
        dtaa_sect_c_amt_long_term: '',
        dtaa_sect_c_amt_short_term: '',
        dtaa_sect_c_basis: '',
        dtaa_sect_d_nature: '',
        is_dtaa_sect_d_taxable: 'N',
        dtaa_sect_d_taxable_article: '',
        dtaa_sect_d_non_taxable_article: 'NA',
        tds_amt_frgn: '',
        tds_amt_inr: '',
        tds_rate_as_per: '',
        tds_rate: '',
        actual_amt_after_tds_frgn: '',
        date_of_deduction:  getDateWithDelta(0)
    }
}

export function getInitialInvoiceForm(){
    return {
        invoice_number: '',
        invoice_file: '',
        invoice_amount: '',
        invoice_date: '',
        exchange_rate: '',
        exchange_date: getDateWithDelta(0)
    }
}

export const formData = {
    remitter: {
        i_or_we: '02',
        honorific: '03',
        name: '',
        pan_tan: '',
        bank_name: '',
        bank_name_other: '',
        branch_name: '',
        bsr_code: '',
        is_ca_applicable: 'N',
        area_code: '',
        range_code: '',
        ao_number: '',
        ...defaultAddressFields,
        state: '-1',
        pincode: '',
        place_of_business: '',
        email: '',
        phone: '',
        status: '1',
        residential_status: '1',
        landlord_name: '',
        verification_person_name: '',
        verification_i_we: '2',
        verification_designation: '',
        verification_place: '',
        accountant: '-1',
        usage_status: '1'
    },
    remittee: {
        name: '',
        honorific: '03',
        ...defaultAddressFields,
        state: '',
        zipcode: '',
        country_of_remittance: '',
        country_of_remittance_other: '',
        currency_of_remittance: '',
        currency_of_remittance_other: '',
        nature_of_remittance: '',
        nature_of_remittance_other: '',
        relevant_purpose_code: '-1',
        relevant_purpose_code_detail: '',
        is_ca_applicable: 'N',
        pan: '',
        status: '1',
        principal_place_of_business: '',
        email: '',
        phone: '',
        remitter: ''
    },
    accountant: {
        name: '',
        firm: '',
        state: '19',
        pincode: '',
        email: '',
        membership_number: '',
        registration_number: '',
        ...defaultAddressFields,
        country: '91',
        usage_status: '1'
    },
    invoices: {
        invoices: []
    },
    invoiceFunc: getInitialInvoiceForm,
    remittanceFunc: getInitialRemittanceForm,
    token: {token_number: ''},
    acknowledgement: {acknowledgement_number: '', acknowledgement_date: ''},
    udin: {udin_number: '', udin_date: ''},
    udinStatus: {udin_status: '0'},
    noteOnCertification: {note_on_certification: '0'},
    comments: {comments: ''},
    cancel: {canceled_by: '0'},
    mailSent: {mail_sent: '0'},
    updateStatus: {status: -1}
}