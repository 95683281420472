import {Box, Button, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {UserActions} from "../utils/UserContext";
import {ProgressContext} from "../utils/ProgressContext";
import {useMutation} from "@tanstack/react-query";
import {Form, Formik} from "formik";
import APIError from "../utils/components/APIError";
import TextField from "../utils/components/TextField";
import API from "../utils/API";
import * as Yup from "yup";
import BackButton from "../utils/components/BackButton";

const initialValues = {otp: ""};
const validationSchema = Yup.object().shape({
    // eslint-disable-next-line
    otp: Yup.string().matches(/\d{7}/, "Only digits should be entered").length(7, "OTP Should be ${length} digit").required("OTP is required")
});


async function loginOtpAPI(data) {
    const response = await API.post('accounts/users/login-otp/', data);
    return response.data;
}

async function resendOtpAPI(data) {
    const response = await API.post('accounts/users/resend-otp/', data);
    return response.data;
}

function LoginOTPForm({email}) {
    const navigate = useNavigate();
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const dispatch = useContext(UserActions);
    const [resendOtpName, setResendOtpName] = useState('Resend-OTP')

    const mutation = useMutation(
        ['accounts', 'users', 'login-otp'],
        loginOtpAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: data => {
                dispatch({type: 'login', user: data});
                navigate('/');
            }
        }
    )

    const resendOtp = useMutation(
        ['accounts', 'users', 'resend-otp'],
        resendOtpAPI,
        {
            onMutate: () => {
                setResendOtpName('Sending OTP ...');
            },
            onSuccess: () => {
                setResendOtpName('OTP Sent.')
                setTimeout(() => {
                    setResendOtpName('Resend-OTP')
                }, 5000);
            },
            onError: () => {
                setResendOtpName('Failed. Re-try in 5 seconds')
                setTimeout(() => {
                    setResendOtpName('Resend-OTP')
                }, 5000);
            }
        }
    )

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={value => {
                mutation.mutate({...value, email: email})
            }}>
            <Form>
                {mutation.isError && <Box sx={{mt: 5}}>
                    <APIError error={mutation.error}/>
                </Box>}
                <TextField
                    required
                    fullWidth
                    name={"otp"}
                    label={"Enter OTP"}
                    sx={{
                        mt: 5
                    }}
                    apiError={mutation.error}
                    autoFocus
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'start',
                        fontSize: '0.5rem',
                        position: 'relative',
                        mb: 2
                    }}
                >
                    {
                        resendOtpName === 'Resend-OTP' ? <Button
                            sx={{
                                position: 'absolute',
                                top: '-20px',
                                px: 1,
                                py: 0
                            }}
                            disableRipple
                            onClick={() => resendOtp.mutate({email: email})}
                        >
                            {resendOtpName}
                        </Button> : <Typography
                            sx={{
                                position: 'absolute',
                                top: '-20px',
                                px: 1,
                                py: 0,
                                m: 0
                            }}
                            variant={'helpText'}
                        >
                            {resendOtpName}
                        </Typography>
                    }

                </Box>
                <Button sx={{mt: 4}} type={"submit"} variant={"contained"}>Verify</Button>
            </Form>
        </Formik>
    )
}

export default function LoginOtp() {
    const location = useLocation();
    const email = location.state?.email;

    return (
        <Box sx={{textAlign: "center"}}>
            <BackButton to={-1} sx={{top: "36px", left: "36px"}} state={{email: email}}/>
            <Typography variant={"h3"}>Verify OTP</Typography>
            <Typography mt={3} variant={"helpText"}>
                Please enter the 7-digit OTP sent to your Email ID
            </Typography>
            <LoginOTPForm email={email}/>
        </Box>
    )
}