import {useReducer, useRef} from "react";
import {CAContext, caContextHandler} from "./CAContext";
import {useLocation} from "react-router-dom";
import {formData} from "./initialValues";
import {getActiveStep} from "../cbs/CBFormStepper";
import CAFormStepper from "./CAFormStepper";
import {Box} from "@mui/material";
import CADetailForm from "./CADetailForm";


export default function CAForm() {
    const location = useLocation();
    const remitterId = location.state?.remitter;
    const remitteeId = location.state?.remittee;
    const accountantId = location.state?.accountant;
    const transactionId = location.state?.transaction;
    const cbStatus = location.state?.status;
    const navigateOnSuccess = location.state?.navigateOnSuccess;
    const ca = location.state?.ca;
    const caStatus = location.state?.caStatus;

    const remitterRef = useRef();
    const remitteeRef = useRef();
    const caRef = useRef();

    const baseData = {
        remitteeId: remitteeId,
        remitterId: remitterId,
        accountantId: accountantId,
        transactionId: transactionId,
        cbStatus: cbStatus,
        navigateOnSuccess: navigateOnSuccess,
        activeStep: getActiveStep(0),
        setActiveStep: setActiveStep,
        ca: ca,
        caStatus: caStatus,
        remitterRef: remitterRef,
        remitteeRef: remitteeRef,
        caRef: caRef,
        pages: [
            {
                visited: false
            },
            {
                visited: false
            },
            {
                visited: false
            },
        ]
    };

    let formDataModified = formData;
    formDataModified.ca = formDataModified.caFunc();
    const [caState, caDispatch] = useReducer(caContextHandler, {baseData: baseData, formData: formDataModified});

    function setActiveStep(val, activeStep) {
        let pages = caState.baseData.pages;
        pages[activeStep].visited = true;
        caDispatch({
            type: 'addBaseData',
            data: {
                activeStep: val,
                pages: pages

            }
        });
    }

    return (
        <CAContext.Provider value={{caState: caState, caDispatch: caDispatch}}>
            <CAFormStepper/>
            <Box sx={{mt: 4}}>
                <CADetailForm/>
            </Box>
        </CAContext.Provider>
    )
}