import {IconButton} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Link from "./Link";

export default function BackButton(props) {
    const {to, sx, ...otherProps} = props;
    return (
        <Link as={IconButton} to={to} sx={{position: "absolute", top: "10px", left: "32px", ...sx}} {...otherProps}>
            <ArrowBackIcon sx={{fontSize: '1.75rem'}}/>
        </Link>
    )
}