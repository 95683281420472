import {Box, Button, Container, TableCell, Toolbar, Typography} from "@mui/material";
import DataTable from "../utils/components/DataTable";
import Link from "../utils/components/Link";
import {useContext, useState} from "react";
import {UserDetails} from "../utils/UserContext";
import {adminUser, organizationalUser} from "../utils/constants/UserTypes";
import withPermission from "../utils/components/PermissionRequired";
import {parseDateTime, StatusChip} from "../utils/utils";
import SearchTextField from "../utils/components/SearchTextField";

function Header() {
    return (
        <>
            <TableCell>Name</TableCell>
            <TableCell>Firm</TableCell>
            <TableCell>Membership Number</TableCell>
            <TableCell>Registration Number</TableCell>
            <TableCell>Usage Status</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
        </>
    )
}

function Row({element}) {
    return (
        <>
            <TableCell><Link underline={'none'} to={'./detail'}
                             state={{data: element}}>{element.name} </Link></TableCell>
            <TableCell>{element.firm}</TableCell>
            <TableCell>{element.membership_number}</TableCell>
            <TableCell>{element.registration_number}</TableCell>
            <TableCell><StatusChip status={element.usage_status}/></TableCell>
            <TableCell>{element.creator}</TableCell>
            <TableCell>{element.updator}</TableCell>
            <TableCell>{parseDateTime(element.created_at)}</TableCell>
            <TableCell>{parseDateTime(element.updated_at)}</TableCell>
        </>
    )
}


function AccountantList() {
    const user = useContext(UserDetails);
    const [search, setSearch] = useState('');
    const [queryParams, setQueryParams] = useState({search: ''});

    function handleKeyDown(e) {
        if (e.key === "Enter") {
            setQueryParams({...queryParams, search: search})
        }
    }

    function handleChange(e) {
        setSearch(e.currentTarget.value);
    }

    return (
        <>
            <Toolbar>
                <Typography variant={"h5"} sx={{color: "primary.main"}}>Accountant</Typography>
                {
                    user && user.user_type >= adminUser && <Box
                        sx={{
                            position: "absolute",
                            top: "12px",
                            right: "40px"
                        }}
                    >
                        <Button variant={'contained'} component={Link} to={'./create'}>Create Accountant</Button>
                    </Box>
                }
            </Toolbar>
            <Box
                sx={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 128px)'
                }}
            >
                <Container
                    sx={{
                        position: "relative",
                    }}
                >
                    <SearchTextField
                        value={search}
                        handleChange={handleChange}
                        handleKeyDown={handleKeyDown}
                        placeholder={'Search by Name, Email, Firm Name, Membership Number and Registration Number'}
                    />
                    <DataTable
                        message={'Select the accountants name to view their details.'}
                        header={<Header/>}
                        rowParser={(data) => <Row element={data}/>}
                        queryUrl={'accountants/'}
                        queryKey={['accountants']}
                        queryParams={queryParams}
                    />
                </Container>
            </Box>
        </>

    )
}

export default withPermission(AccountantList, organizationalUser);
