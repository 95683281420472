import {Box, Button, Grid, IconButton, TableCell} from "@mui/material";
import {
    downloadBlobFile,
    FormModal,
    getSize,
    parseDate,
    parseDateTime,
    showDownloadProgress,
    useConfirmation
} from "../utils/utils";
import React, {useContext, useMemo, useRef, useState} from "react";
import SearchTextField from "../utils/components/SearchTextField";
import DataTable from "../utils/components/DataTable";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import API from "../utils/API";
import {useMutation} from "@tanstack/react-query";
import {Form, Formik} from "formik";
import TextField from "../utils/components/TextField";
import * as Yup from "yup";
import {LabeledProgressContext} from "../utils/LabeledProgressContext";

function Header() {
    return (
        <>
            <TableCell>File Name</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Valid From</TableCell>
            <TableCell>Valid Till</TableCell>
            <TableCell>Actions</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
        </>
    )
}

function Row({element, handleDownload}) {
    return (
        <>
            <TableCell>{element.file_name}</TableCell>
            <TableCell>{getSize(element.size)}</TableCell>
            <TableCell>{element.file_type}</TableCell>
            <TableCell>{parseDate(element.valid_from)}</TableCell>
            <TableCell>{parseDate(element.valid_till)}</TableCell>
            <TableCell>
                <IconButton onClick={() => handleDownload({
                    id: element.id,
                    name: element.file_name
                })}>
                    <FileDownloadIcon color={'primary'}/>
                </IconButton>
            </TableCell>
            <TableCell>{element.creator}</TableCell>
            <TableCell>{element.updator}</TableCell>
            <TableCell>{parseDateTime(element.created_at)}</TableCell>
            <TableCell>{parseDateTime(element.updated_at)}</TableCell>
        </>
    )
}


async function downloadFileAPI(data, setProgress, handleLabeledProgressClose) {
    const response = await API.post(`/remittees/files/${data.id}/download/`, {
            'file_name': data.name,
        },
        {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                showDownloadProgress(progressEvent, setProgress, handleLabeledProgressClose)
            }
        });
    return response.data
}

async function downloadBulkFileAPI(data, id, setProgress, handleLabeledProgressClose) {
    const response = await API.post(`/remittees/${id}/download/`,
        data,
        {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                showDownloadProgress(progressEvent, setProgress, handleLabeledProgressClose)
            }
        }
    );
    return response.data;
}

export default function RemitteeFileList(props) {
    const [search, setSearch] = useState('');
    const [queryParams, setQueryParams] = useState({search: '', remittee_id: props.remittee.id});
    const {handleLabeledProgressOpen, handleLabeledProgressClose, setProgressValue} = useContext(LabeledProgressContext)
    const {open: modalOpen, handleOpen: handleModalOpen, handleClose: handleModalClose} = useConfirmation();
    const formikRef = useRef();

    const downloadSingleFile = useMutation(
        ['remittees', 'files', 'download'],
        (data) => downloadFileAPI(data, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => handleLabeledProgressOpen(),
            onSuccess: (data, variables, context) => {
                downloadBlobFile(variables.name, data);
            }
        }
    )

    const downloadBulkFile = useMutation(
        ['remittees', props.remittee.id, 'files'],
        (data) => downloadBulkFileAPI(data, props.remittee.id, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => {
                handleLabeledProgressOpen();
                handleModalClose();
            },
            onSuccess: (data) => {
                downloadBlobFile(`${props.remittee.folder}.zip`, data);
            },
        }
    )

    function handleKeyDown(e) {
        if (e.key === "Enter") {
            setQueryParams({...queryParams, search: search})
        }
    }

    function handleChange(e) {
        setSearch(e.currentTarget.value);
    }

    const footer = useMemo(() =>
            <>
                <Button sx={{mr: 3}} variant={'outlined'} onClick={handleModalClose}>Close</Button>
                <Button variant={'contained'} autoFocus onClick={() => formikRef.current.submitForm()}>Download</Button>
            </>,
        [handleModalClose]);

    const downloadFilesButton = <Button startIcon={<FileDownloadIcon/>} variant={'outlined'} size={'small'} onClick={handleModalOpen}>
        Download Files
    </Button>;

    return (
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <SearchTextField
                value={search}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                placeholder={'Search by File Name, File Type'}
            />
            <DataTable
                headerButton={downloadFilesButton}
                message={'Files associated with current Remittee'}
                header={<Header/>}
                rowParser={(data) => <Row element={data} handleDownload={downloadSingleFile.mutate}/>}
                queryUrl={'remittees/files/'}
                queryKey={['remittees', 'files']}
                queryParams={queryParams}
            />
            <FormModal
                open={modalOpen}
                title={'Download file in Bulk'}
                width={'500px'}
                footer={footer}
            >
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        valid_from: '',
                        valid_till: ''
                    }}
                    validationSchema={
                        Yup.object().shape({
                            valid_from: Yup.date().required(),
                            valid_till: Yup.date().required()
                        })
                    }
                    validateOnChange={false}
                    onSubmit={downloadBulkFile.mutate}
                >
                    <Form>
                        <Grid container columnSpacing={5}>
                            <Grid item xs={6}>
                                <TextField
                                    type={'date'}
                                    required
                                    fullWidth
                                    label={'Valid From'}
                                    name={'valid_from'}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type={'date'}
                                    required
                                    fullWidth
                                    label={'Valid Till'}
                                    name={'valid_till'}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </FormModal>
        </Box>
    )
}
