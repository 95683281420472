import * as Yup from "yup";
import {
    dropDownErrorMsg,
    lengthErrorMsg,
    maxLengthErrorMsg,
    minLengthErrorMsg
} from "../utils/constants/YupErrorMessages";
import {ascii} from "../utils/utils";
import {defaultAddressValidationSchema} from "../utils/constants/AddressFields";
import {getDateWithDelta} from "./utils";

export const remitterValidationSchema = Yup.object().shape({
    i_or_we: ascii.notOneOf(["-1"], dropDownErrorMsg).max(2, maxLengthErrorMsg).required('I/We is required'),
    honorific: ascii.notOneOf(["-1"], dropDownErrorMsg).max(2, maxLengthErrorMsg).required('Honorific is required'),
    name: ascii.max(125, maxLengthErrorMsg).uppercase().required('Name is required'),
    // eslint-disable-next-line no-undef
    pan_tan: ascii.length(10, lengthErrorMsg).uppercase().required('PAN is required'),
    bank_name: ascii.notOneOf(["-1"], dropDownErrorMsg).max(4, maxLengthErrorMsg).required('Bank Name is required'),
    bank_name_other: ascii.max(125, maxLengthErrorMsg).uppercase().when('bank_name', {
        is: val => val === '999',
        then: schema => schema.required('Bank Name other is required'),
        otherwise: schema => schema.nullable()
    }),
    branch_name: ascii.max(125, maxLengthErrorMsg).uppercase().required('Branch name is required'),
    bsr_code: ascii.max(7, maxLengthErrorMsg).min(7, minLengthErrorMsg).required('BSR Code is required'),
    is_ca_applicable: Yup.string().max(1, maxLengthErrorMsg).required('Is CA Applicable?'),
    area_code: ascii.max(3, maxLengthErrorMsg).uppercase().nullable(),
    ao_type: ascii.max(2, maxLengthErrorMsg).uppercase().nullable(),
    range_code: ascii.max(3, maxLengthErrorMsg).nullable(),
    ao_number: ascii.max(2, maxLengthErrorMsg).nullable(),
    flat_door_building: ascii.max(50, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Flat/Foor/Building is required'),
        otherwise: schema => schema.nullable()
    }),
    premise_building_village: ascii.max(50, maxLengthErrorMsg).nullable(),
    road_street: ascii.max(50, maxLengthErrorMsg).nullable(),
    area_locality: ascii.max(50, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Area/Locality is required'),
        otherwise: schema => schema.nullable()
    }),
    town_city_district: ascii.max(50, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Town/City/District is required'),
        otherwise: schema => schema.nullable()
    }),
    state: ascii.max(4, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('State is required'),
        otherwise: schema => schema.nullable()
    }),
    country: ascii.max(4, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('Country is required'),
        otherwise: schema => schema.nullable()
    }),
    pincode: ascii.max(6, maxLengthErrorMsg).min(6, minLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y', then: schema => schema.required('Pincode is required'), otherwise: schema => schema.nullable()
    }),
    place_of_business: ascii.max(75, maxLengthErrorMsg).uppercase().when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Place of Business is required'),
        otherwise: schema => schema.nullable()
    }),
    email: ascii.email().max(125, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y', then: schema => schema.required('Email is required'), otherwise: schema => schema.nullable()
    }),
    phone: ascii.max(10, maxLengthErrorMsg).min(10, minLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y', then: schema => schema.required('Phone is required'), otherwise: schema => schema.nullable()
    }),
    status: ascii.max(2, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('Status is required'),
        otherwise: schema => schema.nullable()
    }),
    residential_status: ascii.max(2, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('Residential Status is required'),
        otherwise: schema => schema.nullable()
    }),
    landlord_name: ascii.max(125, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Parent/Guardian name is required'),
        otherwise: schema => schema.nullable()
    }),
    verification_person_name: ascii.max(125, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Person Name is required'),
        otherwise: schema => schema.nullable()
    }),
    verification_i_we: ascii.max(2, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('Verification I/We is required'),
        otherwise: schema => schema.nullable()
    }),
    verification_designation: ascii.max(20, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Designation is required'),
        otherwise: schema => schema.nullable()
    }),
    verification_place: ascii.max(35, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y', then: schema => schema.required('Place is required'), otherwise: schema => schema.nullable()
    }),
});

export const remitteeValidationSchema = defaultAddressValidationSchema.concat(Yup.object().shape({
    honorific: ascii.notOneOf(["-1"], dropDownErrorMsg).max(2, maxLengthErrorMsg).required('Honorific Details is required'),
    name: ascii.max(125, maxLengthErrorMsg).uppercase().required('Name is required'),
    state: ascii.max(125, maxLengthErrorMsg).required('State is required'),
    zipcode: ascii.max(8, maxLengthErrorMsg).required('Zipcode is required'),
    country_of_remittance: ascii.max(4, maxLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Country of Remittance'),
    country_of_remittance_other: ascii.max(125, maxLengthErrorMsg).when('currency_of_remittance', {
        is: val => val === '9999',
        then: schema => schema.required('Country of Remittance (Other)'),
        otherwise: schema => schema.nullable()
    }),
    currency_of_remittance: ascii.max(4, maxLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Currency of Remittance'),
    currency_of_remittance_other: ascii.max(75, maxLengthErrorMsg).uppercase().when('currency_of_remittance', {
        is: val => val === '99',
        then: schema => schema.required('Currency of Remittance (Other)'),
        otherwise: schema => schema.nullable()
    }),
    nature_of_remittance: ascii.max(5, maxLengthErrorMsg).min(4, minLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Nature of Remittance'),
    nature_of_remittance_other: ascii.max(125, maxLengthErrorMsg).uppercase().strict().when('currency_of_remittance', {
        is: val => val === '16.99',
        then: schema => schema.required('Nature of Remittance (Other)'),
        otherwise: schema => schema.nullable()
    }),
    relevant_purpose_code: ascii.max(7, maxLengthErrorMsg).min(6, minLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Relevant Purpose Code'),
    relevant_purpose_code_detail: ascii.max(13, maxLengthErrorMsg).min(12, minLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).required('Relevant Purpose Code Detail'),
    is_ca_applicable: Yup.string().max(1, maxLengthErrorMsg).required('Is CA Applicable?'),
    pan: ascii.length(10, lengthErrorMsg).uppercase().nullable(),
    status: ascii.max(4, maxLengthErrorMsg).notOneOf(["-1"], dropDownErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Status of Remittee is Required'),
        otherwise: schema => schema.nullable(),
    }),
    principal_place_of_business: ascii.max(125, maxLengthErrorMsg).uppercase().when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Principal Place of Business is Required'),
        otherwise: schema => schema.nullable(),
    }),
    email: ascii.email().max(125, maxLengthErrorMsg).nullable(),
    phone: ascii.max(10, maxLengthErrorMsg).min(10, minLengthErrorMsg).nullable(),
}));


export const accountantValidationSchema = defaultAddressValidationSchema.concat(
    Yup.object().shape({
        name: ascii.max(125, maxLengthErrorMsg).uppercase('Name should be in uppercase').required('Name is required'),
        firm: ascii.max(125, maxLengthErrorMsg).uppercase('Firm should be in uppercase').required('Firm is required'),
        membership_number: ascii.length(6, lengthErrorMsg).required('Membership Number is required'),
        registration_number: ascii.length(8, lengthErrorMsg).required('Registration Number is required'),
        email: Yup.string().email('Enter a valid Email ID').max(125, maxLengthErrorMsg).required('Email is Required'),
        state: Yup.string().notOneOf(['1'], 'Select a value').required('State is required'),
        pincode: Yup.string().matches(/\d{6}/, 'Pincode should be 6 digits').required('Pincode is required')
    })
);

export const invoiceValidationSchema = Yup.object().shape({
    invoices: Yup.array().of(Yup.object().shape({
        invoice_number: Yup.string().required('Invoice Number is required'),
        invoice_file: Yup.mixed().required('Invoice file is required'),
        invoice_amount: Yup.number().required('Invoice Amount is required'),
        invoice_date: Yup.date().required('Invoice Date is required'),
        exchange_rate: Yup.number().required('Exchange Rate is required'),
        exchange_date: Yup.date().required('Exchange Date is required')
    })).min(1, 'Atleast one Invoice should be present')
});

export const remittanceValidationSchema = Yup.object().shape({
    mail_received: Yup.date().required(),
    is_system_calculation_applicable: Yup.string().max(1, maxLengthErrorMsg).required('System CalculationForm is required?'),
    rounding: Yup.number().when('is_system_calculation_applicable', {
        is: is_system_calculation_applicable => is_system_calculation_applicable === 'Y',
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
    }).label('Rounding scheme'),
    amt_payable_inr: Yup.number().required('Amount Payable (Indian Currency) is required'),
    amt_payable_frgn: Yup.number().required('Amount Payable (Foreign Currency) is required'),
    exchange_rate: Yup.number().required('Exchange Rate is required'),
    expenses_reimbursement_frgn: Yup.number().nullable(),
    proposed_date_of_remittance: Yup.date().min(getDateWithDelta(0), 'Either today or future date').required('Proposed date of remittance is required'),
    is_grossed_up: Yup.string().max(1, maxLengthErrorMsg).required('Is Grossed Up required'),
    is_it_taxable: Yup.string().max(1, maxLengthErrorMsg).required('Is taxable under Income-tax required?'),
    it_relevant_section_act: ascii.max(30, maxLengthErrorMsg).when('is_it_taxable', {
        is: val => val === 'Y',
        then: schema => schema.required('Relevant Section is Required'),
        otherwise: schema => schema.nullable()
    }),
    it_basis: ascii.max(125, maxLengthErrorMsg).when('is_it_taxable', {
        is: val => val === 'Y',
        then: schema => schema.required('IT Basis is Required'),
        otherwise: schema => schema.nullable()
    }),
    it_taxable_income: Yup.number().when(['is_it_taxable'], {
        is: val => val === 'Y',
        then: schema => schema.required('Taxable Income is Required'),
        otherwise: schema => schema.nullable()
    }),
    it_tax_liability: Yup.number().when(['is_it_taxable'], {
        is: val => val === 'Y',
        then: schema => schema.required('Tax Liability is Required'),
        otherwise: schema => schema.nullable()
    }),
    it_rate: Yup.number().when(['is_it_taxable', 'is_system_calculation_applicable'], {
        is: (is_it_taxable, is_system_calculation_applicable) => is_it_taxable === 'Y' && is_system_calculation_applicable === 'Y',
        then: schema => schema.required('Tax Rate is Required'),
        otherwise: schema => schema.nullable()
    }),
    it_not_taxable_reason: ascii.max(125, maxLengthErrorMsg).when('is_it_taxable', {
        is: (is_it_taxable) => !(is_it_taxable === 'Y'),
        then: schema => schema.required('Reason for Non-Taxability is required'),
        otherwise: schema => schema.nullable()
    }),
    is_dtaa_taxable: Yup.string().max(1, maxLengthErrorMsg).required('Is DTAA Taxable is required'),
    dtaa_act: ascii.max(30, maxLengthErrorMsg).nullable(),
    dtaa_article: ascii.max(125, maxLengthErrorMsg).nullable(),
    dtaa_taxable_income: Yup.number().nullable(),
    dtaa_tax_liability: Yup.number().nullable(),
    dtaa_section: Yup.string().when(['is_dtaa_taxable', 'is_system_calculation_applicable', 'is_it_taxable'],
        ([is_dtaa_taxable, is_system_calculation_applicable, is_it_taxable], schema) => {
            if (is_dtaa_taxable === 'Y' && is_it_taxable === 'Y') {
                if (is_system_calculation_applicable === 'Y') {
                    return schema.notOneOf(['0', '3'], dropDownErrorMsg).required('DTAA Section is required')
                }
                return schema.notOneOf(['0'], dropDownErrorMsg).required('DTAA Section is required')
            } else {
                return schema.nullable()
            }
        }),
    dtaa_rate: Yup.number().when(['is_dtaa_taxable', 'is_system_calculation_applicable', 'is_it_taxable'], {
        is: (is_dtaa_taxable, is_system_calculation_applicable, is_it_taxable) => is_dtaa_taxable === 'Y' && is_system_calculation_applicable === 'Y' && is_it_taxable === 'Y',
        then: schema => schema.required('Tax Rate is Required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_a_rate: Yup.number().when(['is_dtaa_taxable', 'dtaa_section', 'is_it_taxable'], ([is_dtaa_taxable, dtaa_section, is_it_taxable], schema) => {
        if(is_dtaa_taxable === 'Y' && is_it_taxable === 'Y'){
            if(dtaa_section === '1'){
                return schema.required('DTAA Section is Required');
            }
        }
        return schema.nullable()
    }),
    dtaa_sect_a_article: ascii.max(125, maxLengthErrorMsg).when(['is_dtaa_taxable', 'dtaa_section', 'is_it_taxable'], ([is_dtaa_taxable, dtaa_section, is_it_taxable], schema) => {
        if(is_dtaa_taxable === 'Y' && is_it_taxable === 'Y'){
            if(dtaa_section === '1'){
                return schema.required('DTAA Article is Required');
            }
        }
        return schema.nullable()
    }),
    is_dtaa_sect_b_taxable: Yup.string().max(2, maxLengthErrorMsg).when(['is_dtaa_taxable', 'dtaa_section', 'is_it_taxable'], {
        is: (is_dtaa_taxable, dtaa_section, is_it_taxable) => is_dtaa_taxable === 'Y' && (dtaa_section === '2') && is_it_taxable === 'Y',
        then: schema => schema.notOneOf(['-1'], dropDownErrorMsg).required('Field is required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_b_taxable_basis: ascii.max(125, maxLengthErrorMsg).when(['is_dtaa_taxable', 'dtaa_section', 'is_dtaa_sect_b_taxable', 'is_it_taxable'], {
        is: (is_dtaa_taxable, dtaa_section, is_dtaa_sect_b_taxable, is_it_taxable) => is_dtaa_taxable === 'Y' && is_dtaa_sect_b_taxable === 'Y' && dtaa_section === '2' && is_it_taxable === 'Y',
        then: schema => schema.required('Taxability basis is required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_b_non_taxable_article: ascii.max(125, maxLengthErrorMsg).when(['is_dtaa_taxable', 'dtaa_section', 'dtaa_sect_b_taxable_basis', 'is_it_taxable'], {
        is: (is_dtaa_taxable, dtaa_section, is_dtaa_sect_b_taxable, is_it_taxable) => is_dtaa_taxable === 'Y' && !(is_dtaa_sect_b_taxable === 'Y') && dtaa_section === '2' && is_it_taxable === 'Y',
        then: schema => schema.required('Non-Taxability basis is required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_c_amt_long_term: Yup.number().when(['is_dtaa_taxable', 'dtaa_section', 'is_it_taxable'], {
        is: (is_dtaa_taxable, dtaa_section, is_it_taxable) => is_dtaa_taxable === 'Y' && dtaa_section === '3' && is_it_taxable === 'Y',
        then: schema => schema.required('Long term capital gains amount is required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_c_amt_short_term: Yup.number().when(['is_dtaa_taxable', 'dtaa_section', 'is_it_taxable'], {
        is: (is_dtaa_taxable, dtaa_section, is_it_taxable) => is_dtaa_taxable === 'Y' && dtaa_section === '3' && is_it_taxable === 'Y',
        then: schema => schema.required('Short term capital gains amount is required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_c_basis: ascii.max(125, maxLengthErrorMsg).when(['is_dtaa_taxable', 'dtaa_section', 'is_it_taxable'], {
        is: (is_dtaa_taxable, dtaa_section, is_it_taxable) => is_dtaa_taxable === 'Y' && dtaa_section === '3' && is_it_taxable === 'Y',
        then: schema => schema.required('Section C basis is required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_d_nature: ascii.max(125, maxLengthErrorMsg).when(['is_dtaa_taxable', 'dtaa_section', 'is_it_taxable'], {
        is: (is_dtaa_taxable, dtaa_section, is_it_taxable) => is_dtaa_taxable === 'Y' && dtaa_section === '4' && is_it_taxable === 'Y',
        then: schema => schema.required('Section D Nature is required'),
        otherwise: schema => schema.nullable()
    }),
    is_dtaa_sect_d_taxable: Yup.string().max(2, maxLengthErrorMsg).when(['is_dtaa_taxable', 'dtaa_section', 'is_it_taxable'], {
        is: (is_dtaa_taxable, dtaa_section, is_it_taxable) => is_dtaa_taxable === 'Y' && dtaa_section === '4' && is_it_taxable === 'Y',
        then: schema => schema.required('Section D taxability is required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_d_taxable_article: ascii.max(125, maxLengthErrorMsg).when(['dtaa_section', 'is_dtaa_taxable', 'is_dtaa_sect_d_taxable', 'is_it_taxable'], {
        is: (dtaa_section, is_dtaa_taxable, is_dtaa_sect_d_taxable, is_it_taxable) => is_dtaa_sect_d_taxable === 'Y' && is_dtaa_taxable === 'Y' && (dtaa_section === '4') && is_it_taxable === 'Y',
        then: schema => schema.required('Section D taxable article required'),
        otherwise: schema => schema.nullable()
    }),
    dtaa_sect_d_non_taxable_article: ascii.max(125, maxLengthErrorMsg).when(['dtaa_section', 'is_dtaa_taxable', 'is_dtaa_sect_d_taxable', 'is_it_taxable'], {
        is: (dtaa_section, is_dtaa_taxable, is_dtaa_sect_d_taxable, is_it_taxable) => is_dtaa_sect_d_taxable === 'Y' && is_dtaa_taxable === 'Y' && (dtaa_section === '4') && is_it_taxable === 'Y',
        otherwise: schema => schema.required('Section D Non-taxable article required'),
        then: schema => schema.nullable()
    }),
    tds_amt_frgn: Yup.number().required('TDS Amount (Foreign) is required'),
    tds_amt_inr: Yup.number().required('TDS Amount (Indian) is required'),
    tds_rate_as_per: Yup.string().notOneOf(['-1'], dropDownErrorMsg).required('TDS Rate As Per is required'),
    tds_rate: Yup.number().required('TDS Rate is required'),
    actual_amt_after_tds_frgn: Yup.number().required('Actual Amount after TDS (Foreign) is required'),
    date_of_deduction: Yup.date().when(['tds_rate'], {
        is: val => val === 0,
        then: schema => schema.nullable(),
        otherwise: schema => schema.max(getDateWithDelta(0), 'Either today or past date').required('Date of Deduction is required'),
    })
});

export const computationValidationSchema = Yup.object().shape({
    is_system_calculation_applicable: Yup.string().max(1, maxLengthErrorMsg).required('System CalculationForm is required?'),
    rounding: Yup.number().when('is_system_calculation_applicable', {
        is: is_system_calculation_applicable => is_system_calculation_applicable === 'Y',
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
    }).label('Rounding scheme'),
    expenses_reimbursement_frgn: Yup.number().nullable(),
    is_grossed_up: Yup.string().max(1, maxLengthErrorMsg).required('Is Grossed Up required'),
    is_it_taxable: Yup.string().max(1, maxLengthErrorMsg).required('Is taxable under Income-tax required?'),
    it_rate: Yup.number().when(['is_it_taxable'], {
        is: (is_it_taxable) => is_it_taxable === 'Y',
        then: schema => schema.required('Tax Rate is Required'),
        otherwise: schema => schema.nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
    is_dtaa_taxable: Yup.string().max(1, maxLengthErrorMsg).required('Is DTAA Taxable is required'),
    dtaa_rate: Yup.number().when(['is_dtaa_taxable', 'is_it_taxable'], {
        is: (is_dtaa_taxable, is_it_taxable) => is_dtaa_taxable === 'Y' && is_it_taxable === 'Y',
        then: schema => schema.required('Tax Rate is Required'),
        otherwise: schema => schema.nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
});


export const tokenValidationSchema = Yup.object().shape({
    token_number: Yup.string().required('Token Number is Required')
});


export const acknowledgementValidationSchema = Yup.object().shape({
    acknowledgement_number: Yup.string().required().label('Acknowledgement Number'),
    acknowledgement_date: Yup.date().required().label('Acknowledgement Date')
});


export const udinValidationSchema = Yup.object().shape({
    udin_number: Yup.string().required().label('UDIN Number'),
    udin_date: Yup.date().required().label('UDIN Date')
});

export const udinStatusSchema = Yup.object().shape({
    udin_status: Yup.string().notOneOf(['-1']).required().label('UDIN Status')
});

export const noteOnCertificationSchema = Yup.object().shape({
    note_on_certification: Yup.string().notOneOf(['-1']).required().label('Note on Certification')
});

export const commentSchema = Yup.object().shape({
    comments: Yup.string().nullable()
});

export const cancelSchema = Yup.object().shape({
    canceled_by: Yup.string().notOneOf(['-1'], dropDownErrorMsg).required().label('Cancellation source'),
});

export const updateStatusSchema = Yup.object().shape({
    status: Yup.string().notOneOf(['-1'], dropDownErrorMsg).required().label('Status')
})

export const mailSentSchema = Yup.object().shape({
    mail_sent: Yup.date().required().label('Mail Sent')
})
