import {useContext} from "react";
import {CAContext} from "./CAContext";
import {Box, Step, StepButton, StepLabel, Stepper} from "@mui/material";

export function getSteps(status) {
    return [
        {
            name: 'Entity Details',
            isChecked: status >= 1,
        },
        {
            name: 'AO Details',
            isChecked: status >= 1,
        },
        {
            name: 'Acknowledgement Details',
            isChecked: status >= 2,
        },
    ];
}

function getCheckStatus(step, index, activeStep, status) {
    if (status !== 0) {
        return step.isChecked && activeStep !== index
    }
    return undefined
}

export default function CAFormStepper(){
    const {caState} = useContext(CAContext);
    const {caStatus, activeStep, setActiveStep} = caState.baseData;
    const steps = getSteps(caStatus)

    return(
        <Box sx={{mt: 1}}>
            <Stepper nonLinear={caStatus >= 1} sx={{mb: 2}} activeStep={activeStep}>
                {steps.map((step, index) => (
                    <Step
                        disabled={step.isDisabled}
                        key={step.name}
                        completed={getCheckStatus(step, index, activeStep, caStatus)}
                    >
                        <StepButton disabled={caStatus < 1} onClick={() => setActiveStep(index, activeStep)}>
                            <StepLabel
                                sx={{
                                    '.MuiStepLabel-label': {
                                        fontWeight: '600',
                                        fontSize: '1rem',
                                    },
                                    '.MuiStepLabel-label.Mui-active': {
                                        color: 'primary.main',
                                        fontWeight: '600',
                                    },
                                    '.MuiStepLabel-label.Mui-completed': {
                                        fontWeight: '600',
                                        color: 'success.main'
                                    },
                                }}
                                StepIconProps={{
                                    sx: {
                                        '&.Mui-completed': {
                                            color: 'success.main'
                                        }
                                    }
                                }}
                            >
                                {step.name}
                            </StepLabel>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export function getActiveStep(status) {
    switch (status) {
        case 0:
            return 0;
        case 1:
            return 0;
        case 2:
            return 3;
        default:
            return 0
    }
}
