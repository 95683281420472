import {createContext, useState} from "react";
import {Box, LinearProgress, Typography} from "@mui/material";
import {FormModal} from "./utils";

export const LabeledProgressContext = createContext(null);

export function LabeledProgressProvider({children}) {
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setProgress(0);
        setOpen(true);
    };

    return (
        <LabeledProgressContext.Provider value={{
            "handleLabeledProgressOpen": handleOpen,
            "handleLabeledProgressClose": handleClose,
            "setProgressValue": setProgress
        }}>
            {children}
            <FormModal
                open={open}
                title={'Downloading...'}
                width={'430px'}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        my: 4
                    }}
                >
                    <LinearProgress
                        variant={'determinate'}
                        color={'success'}
                        value={progress}
                        sx={{
                            flexGrow: '1',
                            height: "10px",
                            borderRadius: "5px",
                            backgroundColor: "grey.300"
                        }}
                    />
                    <Typography ml={2} sx={{color: "grey.700"}}>
                        {progress}%
                    </Typography>
                </Box>
            </FormModal>

        </LabeledProgressContext.Provider>
    )
}
