import {useContext, useEffect} from "react";
import {CAContext} from "./CAContext";
import {ProgressContext} from "../utils/ProgressContext";
import {useMutation, useQuery} from "@tanstack/react-query";
import {generalAPI} from "../cbs/utils";
import {getPatchValues} from "../utils/utils";
import API from "../utils/API";
import {Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import APIError from "../utils/components/APIError";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import {remitteeValidationSchema} from "../cbs/validations";
import {Formik} from "formik";
import RadioField from "../utils/components/RadioField";
import Mapping from "../utils/UtilityMapping.json";
import TextField from "../utils/components/TextField";

async function remitteeUpdateAPI(data, id) {
    const response = await API.patch(`cbs/remittees/${id}/`, data);
    return response.data;
}

export default function RemitteeForm(){
    const {caState, caDispatch} = useContext(CAContext);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const remitteeRef = caState.baseData.remitteeRef;

    const {data: remitteeData, isFetching: remitteeIsFetching, refetch: remitteeRefetch, isError: remitteeIsError, error: remitteeError} = useQuery(
        ['cbs', 'remittees', caState.baseData.remitteeId],
        () => generalAPI(`cbs/remittees/${caState.baseData.remitteeId}/`),
        {
            refetchOnWindowFocus: false,
        }
    );

    const updateRemittee = useMutation(
        ['cbs', 'remitters', caState.baseData.remitterId],
        (data) => remitteeUpdateAPI(data, caState.baseData.remitterId),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                remitteeRefetch();
            }
        }
    )


    useEffect(() => {
        function initializeForm(data, update=false) {
            caDispatch({
                type: update ? 'addUpdateFormData' : 'addFormData',
                data: {'remittee': data}
            })
        }

        if ((!caState.baseData.pages[caState.baseData.activeStep].visited) && remitteeData) {
                initializeForm(remitteeData, true)
        }


    }, [remitteeData, caState.baseData.activeStep, caState.baseData.pages, caDispatch]);

    function handleUpdateRemittee(){
        let remittee = remitteeRef.current.validateForm();
        remittee.then(res => {
            if (Object.keys(res).length === 0){
                updateRemittee.mutate(getPatchValues(caState.updateData.remittee, remitteeRef.current.values))
            }
        })
    }

    const isReading = caState.baseData.caStatus >= 2;

    if (remitteeIsError){
        return <>
            <Box sx={{mx: 25}}>
                <APIError error={remitteeError}/>
            </Box>
        </>
    }

    return (
        <>
            <Box
                sx={{
                    position: 'relative'
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-72px',
                        left: '125px'
                    }}
                >
                    {(remitteeIsFetching || updateRemittee.isLoading) &&
                        <CircularProgress size={'1.5rem'}/>}
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-40px',
                        right: 0
                    }}
                >
                    {
                        caState.baseData.caStatus <= 1 && <>
                            <Button startIcon={<CancelIcon/>}
                                    onClick={() => remitteeRef.current.resetForm({values: caState.updateData.remitter})}>Discard</Button>
                            <Button sx={{ml: 3}} startIcon={<SaveIcon/>} onClick={handleUpdateRemittee}>Save</Button>
                        </>
                    }
                </Box>
                <Formik
                    innerRef={remitteeRef}
                    enableReinitialize
                    initialValues={caState.formData.remittee}
                    validationSchema={remitteeValidationSchema}
                    validateOnChange={false}
                >
                    {formik => (
                        <>
                            <Grid container mt={2} sx={{alignItems: 'stretch'}}>
                                {
                                    updateRemittee.isError && <Grid item xs={12}>
                                        <Box sx={{mx: 10}}>
                                            <APIError error={updateRemittee.error}/>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={6} sx={{pr: 2}}>
                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                        Primary Details
                                    </Typography>
                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                        <Grid item xs={6}>
                                            <RadioField
                                                row
                                                required
                                                fullWidth
                                                isReading={true}
                                                label={'Honorific'}
                                                name={'honorific'}
                                                options={Object.entries(Mapping.remittee.beneficiaryHonorific).filter(([key, value]) => key !== '-1')}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                required
                                                isReading={true}
                                                name={'name'}
                                                label={'Name'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required
                                                isReading={true}
                                                name={'country_of_remittance'}
                                                label={'Country of Remittance'}
                                                options={Mapping.remittee.countryRemMadeSecb}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                isReading={true}
                                                disabled={formik.values.country_of_remittance !== '9999'}
                                                required={formik.values.country_of_remittance === '9999'}
                                                name={'country_of_remittance_other'}
                                                label={'Country of Remittance (Other)'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required
                                                isReading={true}
                                                name={'currency_of_remittance'}
                                                label={'Currency of Remittance'}
                                                options={Mapping.remittee.currencySecbCode}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                isReading={true}
                                                disabled={formik.values.currency_of_remittance !== '99'}
                                                required={formik.values.currency_of_remittance === '99'}
                                                name={'currency_of_remittance_other'}
                                                label={'Currency of Remittance (Other)'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required
                                                isReading={true}
                                                name={'nature_of_remittance'}
                                                label={'Nature of Remittance'}
                                                options={Mapping.remittee.natureRemCategory}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                isReading={true}
                                                disabled={formik.values.nature_of_remittance !== '16.99'}
                                                required={formik.values.nature_of_remittance === '16.99'}
                                                name={'nature_of_remittance_other'}
                                                label={'Nature of Remittance (Other)'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required
                                                isReading={true}
                                                name={'relevant_purpose_code'}
                                                label={'Relevant Purpose Code'}
                                                options={Mapping.remittee.revPurCategory}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required
                                                isReading={true}
                                                name={'relevant_purpose_code_detail'}
                                                label={'Relevant Purpose Category'}
                                                options={Mapping.remittee.revPurCode[formik.values.relevant_purpose_code ? formik.values.relevant_purpose_code : '-1']}

                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}
                                      sx={{
                                          borderLeft: "1px solid",
                                          borderColor: "rgba(0, 0, 0, 0.10)",
                                          pl: 2
                                      }}
                                >
                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                        Address Details
                                    </Typography>
                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                isReading={true}
                                                name={'flat_door_building'}
                                                label={'Flat/Door/Building'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                isReading={true}
                                                name={'premise_building_village'}
                                                label={'Premise/Building/Village'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                isReading={true}
                                                name={'road_street'}
                                                label={'Road/Street'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                isReading={true}
                                                name={'area_locality'}
                                                label={'Area/Locality'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                isReading={true}
                                                name={'town_city_district'}
                                                label={'Town/City/District'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                isReading={true}
                                                name={'state'}
                                                label={'State'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required
                                                isReading={true}
                                                name={'country'}
                                                label={'Country'}
                                                apiError={updateRemittee.error}
                                                options={Mapping.remitter.country}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                isReading={true}
                                                name={'zipcode'}
                                                label={'Zipcode'}
                                                apiError={updateRemittee.error}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioField
                                        fullWidth
                                        required={!isReading}
                                        isReading={isReading}
                                        name={'is_ca_applicable'}
                                        label={'Is 15CA Applicable?'}
                                        helperText={''}
                                        row
                                        apiError={updateRemittee.error}
                                    />
                                </Grid>

                                {
                                    formik.values.is_ca_applicable === 'Y' && (
                                        <Grid container item xs={12} mt={2}>
                                            <Grid item xs={6} sx={{pr: 2}}>
                                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                    Preliminary Details
                                                </Typography>
                                                <Grid container columnSpacing={2} rowSpacing={4}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            upperCase
                                                            isReading={isReading}
                                                            name={'pan'}
                                                            label={'PAN'}
                                                            apiError={updateRemittee.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            isReading={isReading}
                                                            name={'status'}
                                                            label={'Status'}
                                                            options={Mapping.remittee.status}
                                                            apiError={updateRemittee.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            upperCase
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'principal_place_of_business'}
                                                            label={'Principal Place of Business'}
                                                            apiError={updateRemittee.error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}
                                                  sx={{
                                                      borderLeft: "1px solid",
                                                      borderColor: "rgba(0, 0, 0, 0.10)",
                                                      pl: 2
                                                  }}>
                                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                    Contact Details
                                                </Typography>
                                                <Grid container columnSpacing={2} rowSpacing={4}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            isReading={isReading}
                                                            name={'email'}
                                                            label={'Email'}
                                                            apiError={updateRemittee.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            isReading={isReading}
                                                            name={'phone'}
                                                            label={'Phone'}
                                                            apiError={updateRemittee.error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </>
                    )}
                </Formik>
            </Box>
        </>
    )
}