import {useMutation, useQuery} from "@tanstack/react-query";
import CheckIcon from '@mui/icons-material/Check';
import API from "../utils/API";
import APIError from "../utils/components/APIError";
import {Box, Button, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import Mapping from "../utils/UtilityMapping.json";
import {b64toBlob, downloadBlobFile, parseDate, parseDateTime} from "../utils/utils";
import {useContext} from "react";
import {ProgressContext} from "../utils/ProgressContext";
import {useNavigate} from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import {noteOnCertificationMapping, udinMapping} from "./CBList";
import {UserDetails} from "../utils/UserContext";
import {superAdminUser} from "../utils/constants/UserTypes";


const CANCELED_BY = {
    '-1': 'SELECT',
    '0': 'BGSS',
    '1': 'CLIENT'
}

async function getTransactionPreview(transactionID) {
    const response = await API.get(`cbs/${transactionID}/preview/`);
    return response.data;
}


function Data(props) {
    return (
        <Box sx={{display: 'flex', justifyContent: 'left', m: props.m || 2, borderBottom: '1px solid rgba(0, 0, 0, 0.10)'}}>
            <Typography
                sx={{
                    color: 'grey.700',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: props.width || '350px',
                    mr: props.m || 2
                }}>
                <span>{props.name}</span>
                <span>:</span>
            </Typography>
            <Typography sx={{fontWeight: 700}}>{props.value}</Typography>
        </Box>
    )
}

function MapData(props) {
    const {fields, ...otherProps} = props;
    return (
        <>
            {
                fields.map((field, index) => (
                    <Data {...field} key={index} {...otherProps}/>
                ))
            }
        </>
    )
}

export default function CBPreview(props) {
    const {transactionId: transaction} = props;
    const user = useContext(UserDetails);

    const query = useQuery(
        ['cbs', transaction, 'preview'],
        () => getTransactionPreview(transaction),
    );

    if (query.data) {

        const remitter = query.data.remitter;
        const remitterFields = [
            {
                name: 'I/We',
                value: Mapping.remitter.iorWe[remitter['i_or_we']]
            },
            {
                name: 'Honorific',
                value: Mapping.remitter.remitterHonorific[remitter['honorific']],
            },
            {
                name: 'Name',
                value: remitter['name'],
            },
            {
                name: 'PAN/TAN',
                value: remitter['pan_tan']
            }
        ];

        const remittee = query.data.remittee;
        const remitteeFields = [
            {
                name: 'Honorific',
                value: Mapping.remittee.beneficiaryHonorific[remittee['honorific']]
            },
            {
                name: 'Name',
                value: remittee['name']
            },
            {
                name: 'Flat/Door/Building',
                value: remittee['flat_door_building'] || '-'
            },
            {
                name: 'Premise/Building/Village',
                value: remittee['premise_building_village'] || '-'
            },
            {
                name: 'Road/Street',
                value: remittee['road_street'] || '-'
            },
            {
                name: 'Area/Locality',
                value: remittee['area_locality'] || '-'
            },
            {
                name: 'Town/City/District',
                value: remittee['town_city_district'] || '-'
            },
            {
                name: 'State',
                value: remittee['state'],
            },
            {
                name: 'Country',
                value: Mapping.remittee.country[remittee['country']]
            },
            {
                name: 'Zipcode',
                value: remittee['zipcode']
            }
        ];

        const cb = query.data.cb;

        const remittanceDetails = [
            {
                name: 'Country of Remittance',
                value: Mapping.remittee.countryRemMadeSecb[remittee['country_of_remittance']]
            },
            {
                name: 'Country of Remittance (Other)',
                value: remittee['country_of_remittance_other'] || '-'
            },
            {
                name: 'Currency of Remittance',
                value: Mapping.remittee.currencySecbCode[remittee['currency_of_remittance']]
            },
            {
                name: 'Currency of Remittance (Other)',
                value: remittee['currency_of_remittance_other'] || '-'
            },
            {
                name: 'Bank Name',
                value: Mapping.remitter.nameBankCode[remitter['bank_name']]
            },
            {
                name: 'Bank Name (Other)',
                value: remitter['bank_name_other'] || '-'
            },
            {
                name: 'Branch Name',
                value: remitter['branch_name']
            },
            {
                name: 'BSR Code',
                value: remitter['bsr_code']
            },
            {
                name: 'Amount Payable (Foreign Currency)',
                value: cb['amt_payable_frgn'].toLocaleString('en-IN')
            },
            {
                name: 'Amount Payable (Indian Currency)',
                value: cb['amt_payable_inr'].toLocaleString('en-IN')
            },
            {
                name: 'Proposed Date of Remittance',
                value: parseDate(cb['proposed_date_of_remittance'])
            },
            {
                name: 'Nature of Remittance',
                value: Mapping.remittee.natureRemCategory[remittee['nature_of_remittance']]
            },
            {
                name: 'Nature of Remittance (Other)',
                value: remittee['nature_of_remittance_other'] || '-'
            },
            {
                name: 'Relevant Purpose Category',
                value: Mapping.remittee.revPurCategory[remittee['relevant_purpose_code']]
            },
            {
                name: 'Relevant Purpose Code',
                value: Mapping.remittee.revPurCode[remittee['relevant_purpose_code']][remittee['relevant_purpose_code_detail']]
            },
            {
                name: 'Is Grossed Up?',
                value: cb['is_grossed_up'] === 'Y' ? 'Yes' : 'No'
            }
        ];

        const itActDetails = [
            {
                name: 'Is Taxable under Income-Tax?',
                value: cb['is_it_taxable'] === 'Y' ? 'Yes' : 'No',
            },
            {
                name: 'Section act under which Remittance is covered',
                value: cb['it_relevant_section_act'] || '-'
            },
            {
                name: 'Basis of determining taxable income and tax liability',
                value: cb['it_basis'] || '-'
            },
            {
                name: 'Amount of Income chargeable to tax in India',
                value: cb['it_taxable_income']?.toLocaleString('en-IN') || '-'
            },
            {
                name: 'Tax Liability',
                value: cb['it_tax_liability']?.toLocaleString('en-IN') || '-'
            },
            {
                name: 'Reason for Remittance Not chargable to Tax in India',
                value: cb['it_not_taxable_reason']
            }
        ];

        const dtaaActDetails = [
            {
                name: 'Is taxable under DTAA Act?',
                value: cb['is_dtaa_taxable'] === 'Y' ? 'Yes' : 'No'
            },
            {
                name: 'Relevant DTAA Act',
                value: cb['dtaa_act'] || '-'
            },
            {
                name: 'Relevant DTAA Article',
                value: cb['dtaa_article'] || '-'
            },
            {
                name: 'Taxable Income',
                value: cb['dtaa_taxable_income']?.toLocaleString('en-IN') || '-'
            },
            {
                name: 'Tax Liability',
                value: cb['dtaa_tax_liability']?.toLocaleString('en-IN') || '-'
            }
        ];

        const dtaaSectionA = [
            {
                name: 'Is remittance for Royalties, fee for technical services, interest, dividend, etc?',
                value: parseInt(cb['dtaa_section']) === 1 ? 'Yes' : 'No'
            },
            {
                name: 'Relevant DTAA Article',
                value: cb['dtaa_sect_a_article'] || '-'
            },
            {
                name: 'Rate of TDS',
                value: cb['dtaa_sect_a_rate'] || '-'
            }
        ];

        const dtaaSectionB = [
            {
                name: 'In-case the remittance is on account of business income, please indicate',
                value: parseInt(cb['dtaa_section']) === 2 ? 'Yes' : 'No'
            },
            {
                name: 'Whether such income is liable to tax in India?',
                value: cb['is_dtaa_sect_b_taxable'] === 'Y' ? 'Yes' : 'No'
            },
            {
                name: 'If so, basis of arriving at the rate of deduction of tax',
                value: cb['dtaa_sect_b_taxable_basis'] || '-'
            },
            {
                name: 'If not, then specify relevant article of DTAA',
                value: cb['dtaa_sect_b_non_taxable_article'] || '-'
            }
        ];

        const dtaaSectionC = [
            {
                name: 'In-case the remittance is on account of capital gains\n',
                value: parseInt(cb['dtaa_section']) === 3 ? 'Yes' : 'No'
            },
            {
                name: 'Amount of long term capital gains',
                value: cb['dtaa_sect_c_amt_long_term']?.toLocaleString('en-IN') || '-'
            },
            {
                name: 'Amount of short term capital gains',
                value: cb['dtaa_sect_c_amt_short_term']?.toLocaleString('en-IN') || '-'
            },
            {
                name: 'Basis of arriving at taxable income',
                value: cb['dtaa_sect_c_basis'] || '-'
            }
        ];

        const dtaaSectionD = [
            {
                name: 'In-case of remittance not covered by sub-items A, B and C',
                value: parseInt(cb['dtaa_section']) === 4 ? 'Yes' : 'No'
            },
            {
                name: 'Nature of Remittance',
                value: cb['dtaa_sect_d_nature'] || '-'
            },
            {
                name: 'Whether such income is taxable to India',
                value: cb['is_dtaa_sect_d_taxable'] === 'Y' ? 'Yes' : 'No'
            },
            {
                name: 'If yes, Article of Applicable DTAA',
                value: cb['dtaa_sect_d_taxable_article'] || '-'
            },
            {
                name: 'If not, specify relevant article of DTAA',
                value: cb['dtaa_sect_d_non_taxable_article'] || '-'
            }
        ];

        const tdsDetails = [
            {
                name: 'TDS Amount (Foreign Currency)',
                value: cb['tds_amt_frgn']
            },
            {
                name: 'TDS Amount (Indian Currency)',
                value: cb['tds_amt_inr']
            },
            {
                name: 'Rate of TDS',
                value: Mapping.transactionCB.rateTdsSecbFlg[cb['tds_rate_as_per']]
            },
            {
                name: 'TDS Rate %',
                value: cb['tds_rate']
            },
            {
                name: 'Amount of Remittance after TDS (Foreign Currency)',
                value: cb['actual_amt_after_tds_frgn']
            },
            {
                name: 'Date of Deduction',
                value: cb['date_of_deduction'] ? parseDate(cb['date_of_deduction']) : '-'
            }
        ];

        const accountant = query.data.accountant;
        const accountantDetails = [
            {
                name: 'Name',
                value: accountant['name']
            },
            {
                name: 'Firm',
                value: accountant['firm']
            },
            {
                name: 'Membership Number',
                value: accountant['membership_number']
            },
            {
                name: 'Registration Number',
                value: accountant['registration_number']
            },
            {
                name: 'Flat/Door/Building',
                value: accountant['flat_door_building'] || '-'
            },
            {
                name: 'Premise/Building/Village',
                value: accountant['premise_building_village'] || '-'
            },
            {
                name: 'Road/Street',
                value: accountant['road_street'] || '-'
            },
            {
                name: 'Area/Locality',
                value: accountant['area_locality'] || '-'
            },
            {
                name: 'Town/City/District',
                value: accountant['town_city_district'] || '-'
            },
            {
                name: 'State',
                value: Mapping.accountant.state[accountant['state']]
            },
            {
                name: 'Country',
                value: Mapping.accountant.country[accountant['country']]
            },
            {
                name: 'Pincode',
                value: accountant['pincode'] || '-'
            },
        ];

        const invoices = query.data.invoices;
        const invoice_details = invoices.map(invoice => {
            let details = []
            details.push({name: 'Invoice Number', value: invoice.invoice_number});
            details.push({name: 'Invoice Amount', value: invoice.invoice_amount});
            details.push({name: 'Invoice Date', value: parseDate(invoice.invoice_date)});
            details.push({name: 'Exchange Rate', value: invoice.exchange_rate});
            details.push({name: 'Exchange Date', value: parseDate(invoice.exchange_date)});
            return details;
        });

        const initialSubmission = [
            {
                name: 'Checked By',
                value: cb.checked_by_name || '-',
            },
            {
                name: 'Token Number',
                value: cb.token_number || '-',
            },
            {
                name: 'Created By',
                value: cb.creator
            },
            {
                name: 'Updated By',
                value: cb.updator
            },
            {
                name: 'Created At',
                value: parseDateTime(cb.created_at)
            },
            {
                name: 'Updated At',
                value: parseDateTime(cb.updated_at)
            },
        ];

        const acknowledgementDetails = [
            {
                name: 'Number',
                value: cb.acknowledgement_number || '-',
            },
            {
                name: 'Date',
                value: cb.acknowledgement_date ? parseDate(cb.acknowledgement_date) : '-',
            },
        ];

        const udinDetails = [
            {
                name: 'Number',
                value: cb.udin_number || '-',
            },
            {
                name: 'Date',
                value: cb.udin_date ? parseDate(cb.udin_date) : '-',
            },
            {
                name: 'Status',
                value: udinMapping[cb.udin_status],
            }
        ]

        let metaData = [
            {
                name: 'Mail Received',
                value: cb.mail_received ? parseDate(cb.mail_received) : '-',
            },
            {
                name: 'Mail Sent',
                value: cb.mail_sent ? parseDate(cb.mail_sent) : '-',
            },
            {
                name: 'Note on Certification',
                value: noteOnCertificationMapping[cb.note_on_certification],
            },
            {
                name: 'Comments',
                value: cb.comments || '-',
            },
        ];

        if (cb.status === 7) {
            metaData.push(
                {
                    name: 'Cancellation Initiated By',
                    value: CANCELED_BY[cb.canceled_by],
                }
            )
        }

        if (user.user_type >= superAdminUser) {
            metaData.push(
                {
                    name: 'Folder',
                    value: cb.folder,
                }
            )
        }

        const metadataPaperSX = {
            height: '360px',
            overflowY: 'auto',
            flexShrink: 0,
            flexGrow: 0,
            width: '100%',
            p: 3
        };

        const cbFormPreview = <>
            <Grid item xs={12}>
                <Paper sx={{p: 3}}>
                    <Box sx={{
                        position: 'relative',
                    }}>
                        {/*<Box*/}
                        {/*    sx={{*/}
                        {/*        position: 'absolute',*/}
                        {/*        top: 0,*/}
                        {/*        right: 0*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {*/}
                        {/*        cb.is_checked ?*/}
                        {/*            <>*/}
                        {/*            </>*/}
                        {/*            : <Button*/}
                        {/*                startIcon={<CheckIcon/>}*/}
                        {/*                onClick={mutation.mutate}*/}
                        {/*                variant={'outlined'}*/}
                        {/*            >*/}
                        {/*                Check Transaction*/}
                        {/*            </Button>*/}
                        {/*    }*/}
                        {/*    <Button startIcon={<DownloadIcon/>} sx={{ml: 3}} variant={'outlined'}*/}
                        {/*            onClick={exportPDF}>Download*/}
                        {/*        Preview</Button>*/}
                        {/*</Box>*/}
                    </Box>
                    <Box id={'preview'}>
                        <Typography variant={'h5'} sx={{mt: 2}}>
                            Remitter Details
                        </Typography>
                        <MapData fields={remitterFields}/>
                        <Typography variant={'h5'} sx={{mt: 4}}>
                            Remittee Details
                        </Typography>
                        <MapData fields={remitteeFields}/>
                        <Typography variant={'h5'} sx={{mt: 4}}>
                            Remittance Details
                        </Typography>
                        <MapData fields={remittanceDetails}/>
                        <Typography variant={'h5'} sx={{mt: 4}}>
                            IT Act Details
                        </Typography>
                        <MapData fields={itActDetails}/>
                        <Typography variant={'h5'} sx={{mt: 4}}>
                            DTAA Details
                        </Typography>
                        <MapData fields={dtaaActDetails}/>
                        <Box sx={{mx: 2}}>
                            <Typography variant={'h6'} sx={{mt: 4}}>
                                DTAA Section A
                            </Typography>
                            <MapData fields={dtaaSectionA}/>
                            <Typography variant={'h6'} sx={{mt: 4}}>
                                DTAA Section B
                            </Typography>
                            <MapData fields={dtaaSectionB}/>
                            <Typography variant={'h6'} sx={{mt: 4}}>
                                DTAA Section C
                            </Typography>
                            <MapData fields={dtaaSectionC}/>
                            <Typography variant={'h6'} sx={{mt: 4}}>
                                DTAA Section D
                            </Typography>
                            <MapData fields={dtaaSectionD}/>
                        </Box>

                        <Typography variant={'h5'} sx={{mt: 4}}>
                            TDS Details
                        </Typography>
                        <MapData fields={tdsDetails}/>

                        <Typography variant={'h5'} sx={{mt: 4}}>
                            Accountant Details
                        </Typography>
                        <MapData fields={accountantDetails}/>
                    </Box>
                </Paper>
            </Grid>
        </>

        const otherMetadataPreview = <>
            <Grid item container xs={12} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                    <Paper sx={metadataPaperSX}>
                        <Typography variant={'h5'}>
                            Invoice Details
                        </Typography>
                        <Box sx={{m: 1}}>
                            {
                                invoice_details.map((details, index) => (
                                    <>
                                        <Typography variant={'h6'}>
                                            Invoice - {index + 1}
                                        </Typography>
                                        <MapData fields={details} key={details} width={'140px'}/>
                                    </>
                                ))
                            }
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper sx={metadataPaperSX}>
                        <Typography variant={'h5'}>
                            Basic Details
                        </Typography>
                        <MapData fields={initialSubmission} width={'120px'}/>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper sx={metadataPaperSX}>
                        <Typography variant={'h5'}>
                            Acknowledgement Details
                        </Typography>
                        <MapData fields={acknowledgementDetails} width={'100px'}/>
                        <Typography variant={'h5'} sx={{mt: 1}}>
                            UDIN Details
                        </Typography>
                        <MapData fields={udinDetails} width={'100px'}/>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper sx={{p: 3, ...metadataPaperSX}}>
                        <Typography variant={'h5'}>Other Details</Typography>
                        <MapData fields={metaData} width={'180px'}/>
                    </Paper>
                </Grid>

            </Grid>
        </>

        return <>
            <Grid sx={{mt: -3}} container columnSpacing={2} rowSpacing={4}>
                {
                    cb.status >= 2 ? <>
                        {otherMetadataPreview}
                        {cbFormPreview}
                    </> : <>
                        {cbFormPreview}
                        {otherMetadataPreview}
                    </>
                }
            </Grid>
        </>
    }

    if (query.isLoading) {
        return <Box sx={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
            <CircularProgress/>
        </Box>
    }

    return <>
        <APIError error={query.error}/>
    </>
}