import DataTable from "../utils/components/DataTable";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    TableCell,
    Tooltip,
    Typography
} from "@mui/material";
import Mapping from "../utils/UtilityMapping.json";
import {FormModal, parseDateTime, ShowChips} from "../utils/utils";
import Link from "../utils/components/Link";
import CBStatus, {accountantListAPI, cbStatusMapping, EntityDropdown} from "./utils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useEffect, useRef, useState} from "react";
import TextField from "../utils/components/TextField";
import {Form, Formik} from "formik";
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SummaryFilters from "./SummaryFilters";
import {useQuery} from "@tanstack/react-query";
import {remitteeListAPI, remitterListAPI} from "./utils";

function Header() {
    return (<>
        <TableCell>Sr. No.</TableCell>
        <TableCell>Remitter</TableCell>
        <TableCell>Remittee</TableCell>
        <TableCell>Accountant</TableCell>
        <TableCell>Clone</TableCell>
        <TableCell>Is System Calculation Enabled?</TableCell>
        <TableCell>Is Grossed Up?</TableCell>
        <TableCell>Invoice #</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>UDIN Status</TableCell>
        <TableCell>Note On Certification</TableCell>
        <TableCell>Checked By</TableCell>
        <TableCell>Nature of Transaction</TableCell>
        <TableCell>Token</TableCell>
        <TableCell>Acknowledgement</TableCell>
        <TableCell>Canceled By</TableCell>
        <TableCell>UDIN</TableCell>
        <TableCell>Created By</TableCell>
        <TableCell>Updated By</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Updated At</TableCell>
    </>)
}

export const udinMapping = {
    '-1': 'UDIN PENDING', 0: 'ADDED', 1: 'LINKED', 2: 'REVOKED'
}

export const cancelationMapping = {
    '-1': '-',
    "0": 'BGSS',
    "1": 'CLIENT'
}

export const noteOnCertificationMapping = {
    0: 'NA', 1: 'PENDING', 2: 'SENT'
}


function Row({element}) {
    let nature = Mapping.remittee.natureRemCategory[element.nature_of_remittance];
    if (element.nature_of_remittance === '16.99') {
        nature = element.nature_of_remittance_other
    }
    return (<>
        <TableCell>
            <Link
                underline={'none'}
                component={Link}
                to={'/cb/detail'}
                state={{
                    remitter: element.remitter,
                    remittee: element.remittee,
                    accountant: element.accountant,
                    status: element.status,
                    transaction: element.id,
                    navigateOnSuccess: -1,
                    ca: element.ca,
                    caStatus: element.ca_status,
                }}
            >
                {element.id}
            </Link>
        </TableCell>
        <TableCell>
            <Link
                underline={'none'}
                component={Link}
                to={'/cb/detail'}
                state={{
                    remitter: element.remitter,
                    remittee: element.remittee,
                    accountant: element.accountant,
                    status: element.status,
                    transaction: element.id,
                    navigateOnSuccess: -1,
                    ca: element.ca,
                    caStatus: element.ca_status,
                }}
            >
                {element.remitter_name}
            </Link>
        </TableCell>
        <TableCell>
            <Link
                underline={'none'}
                component={Link}
                to={'/cb/detail'}
                state={{
                    remitter: element.remitter,
                    remittee: element.remittee,
                    accountant: element.accountant,
                    status: element.status,
                    transaction: element.id,
                    navigateOnSuccess: -1,
                    ca: element.ca,
                    caStatus: element.ca_status,
                }}
            >
                {element.remittee_name}
            </Link>
        </TableCell>
        <TableCell>{element.accountant_name}</TableCell>
        <TableCell>
            <Tooltip title={'Clone Transaction'}>
                <IconButton
                    component={Link} to={'/cb/form'}
                    state={{
                        remitter: element.remitter,
                        remittee: element.remittee,
                        accountant: element.accountant,
                        status: 0,
                        transaction: element.id,
                        cloning: true,
                        navigateOnSuccess: -1
                    }}
                >
                    <ContentCopyIcon sx={{color: 'primary.main'}}/>
                </IconButton>
            </Tooltip>

            {/*<TransactionActions element={element}/>*/}
        </TableCell>
        <TableCell>{element.is_system_calculation_applicable ? 'YES' : 'NO'}</TableCell>
        <TableCell>{element.is_grossed_up === 'Y' ? 'YES' : 'NO'}</TableCell>
        <TableCell>{element.invoice_numbers}</TableCell>
        <TableCell><CBStatus status={element.status}/></TableCell>
        <TableCell>{udinMapping[element.udin_status]}</TableCell>
        <TableCell>{noteOnCertificationMapping[element.note_on_certification]}</TableCell>
        <TableCell>{element.checked_by_name}</TableCell>
        <TableCell>{nature}</TableCell>
        <TableCell>{element.token_number}</TableCell>
        <TableCell>{element.acknowledgement_number}</TableCell>
        <TableCell>{cancelationMapping[element.canceled_by.toString()]}</TableCell>
        <TableCell>{element.udin_number}</TableCell>
        <TableCell>{element.creator}</TableCell>
        <TableCell>{element.updator}</TableCell>
        <TableCell>{parseDateTime(element.created_at)}</TableCell>
        <TableCell>{parseDateTime(element.updated_at)}</TableCell>
    </>)
}

const cbMapping = {'-1': 'SELECT', ...cbStatusMapping}


const labelMapping = {
    id: 'SR.NO',
    template_remitter: 'REMITTER',
    template_remittee: 'REMITTEE',
    token_number: 'TOKEN',
    acknowledgement_number: 'ACKNOWLEDGEMENT',
    udin_number: 'UDIN',
    status: 'STATUS',
    nature_of_remittance: 'NATURE',
    template_accountant: 'ACCOUNTANT',
    remitter_name: 'REMITTER NAME',
    remittee_name: "REMITTEE NAME",
    invoice_number: "INVOICE NUMBER"
}


export default function CBList(props) {

    const {params: propsParams} = props;
    const {template_remitter, template_remittee} = propsParams;
    const [params, setParams] = useState(propsParams);
    const [summaryOpen, setSummaryOpen] = useState(false);
    const [filterChips, setFilterChips] = useState([]);
    const formikRef = useRef();
    const [remitter, setRemitter] = useState(propsParams?.template_remitter || '-1');

    const initialValues = {
        id: '',
        invoice_number: '',
        template_remitter: propsParams?.template_remitter || '-1',
        template_remittee: propsParams?.template_remittee || '-1',
        template_accountant: '-1',
        token_number: '',
        acknowledgement_number: '',
        udin_number: '',
        status: '-1',
        nature_of_remittance: '-1',
        remitter_name: '',
        remittee_name: ''
    }

    const accountantList = useQuery(['accountants', 'list'], accountantListAPI, {
        staleTime: 3600000,
    })

    const remitterList = useQuery(['remitters', 'list'], remitterListAPI, {
        staleTime: 3600000,
    })

    const remitteeList = useQuery(['remittees', 'list', remitter], () => remitteeListAPI(remitter), {
        staleTime: 3600000, enabled: remitter !== '-1'
    });

    function getNameById(data, id) {
        const foundItem = data?.find(item => item.id.toString() === id);
        return foundItem ? foundItem.name : null;
    }

    function updateChips(values) {
        let chips = [];
        let finalFilters = {}
        for (let key of Object.keys(values)) {
            if (values[key] !== '' && values[key] !== '-1') {
                if (key === 'status') {
                    chips.push([labelMapping[key], cbMapping[values[key]], key, '-1']);
                } else if (key === 'nature_of_remittance') {
                    chips.push([labelMapping[key], Mapping.remittee.natureRemCategory[values[key]], key, '-1']);
                } else if (key === 'template_remitter') {
                    chips.push([labelMapping[key], getNameById(remitterList.data, values[key].toString()), key, '-1']);
                } else if (key === 'template_remittee') {
                    chips.push([labelMapping[key], getNameById(remitteeList.data, values[key].toString()), key, '-1']);
                } else if (key === 'template_accountant') {
                    chips.push([labelMapping[key], getNameById(accountantList.data, values[key].toString()), key, '-1']);
                } else {
                    chips.push([labelMapping[key], values[key], key, '']);
                }
                finalFilters[key] = values[key];
            }
        }
        setFilterChips(chips);
        setParams({...props.params, ...finalFilters})
    }

    const handleFilterSubmit = (values) => {
        setParams({...props.params, ...values});
        updateChips(values);
    }
    
    useEffect(() => {
        let chips = [];
        if (template_remitter) {
            chips.push([labelMapping['template_remitter'], getNameById(remitterList.data, template_remitter.toString()), 'template_remitter', '-1'])
        }
        if (template_remittee) {
            chips.push([labelMapping['template_remittee'], getNameById(remitteeList.data, template_remittee.toString()), 'template_remittee', '-1'])
        }
        setFilterChips(chips);
    }, [template_remitter, template_remittee, remitteeList.data, remitterList.data]);

    return (
        <Box sx={{my: 2}}>
            <Paper sx={{my: 2}}>
                <Accordion defaultExpanded>
                    <AccordionSummary>
                        <Badge sx={{mr: 3, mt: 1}} badgeContent={filterChips.length} color="primary">
                            <FilterListIcon color="action"/>
                        </Badge>
                        <Typography variant={'h6'}>
                            Filters
                        </Typography>
                        <Box sx={{ml: 3, display: 'flex', gap: 2, my: 'auto', flexWrap: 'wrap'}}>
                            <ShowChips
                                filterChips={filterChips}
                                formikRef={formikRef}
                                propsParams={propsParams}
                            />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Formik
                            innerRef={formikRef}
                            initialValues={initialValues}
                            onSubmit={handleFilterSubmit}
                        >
                            {formik => (<Form>
                                <Grid container columnSpacing={2} rowSpacing={1}>
                                    <Grid item xs={3}>
                                        <EntityDropdown
                                            label={'Remitter'}
                                            name={'template_remitter'}
                                            api={remitterList}
                                            onChange={(e) => {
                                                formik.setFieldValue('template_remitter', e.target.value);
                                                setRemitter(e.target.value);
                                            }}
                                            disabled={Boolean(propsParams?.template_remitter)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            formik.values.template_remitter === '-1' ?
                                                <Typography sx={{color: '#616161', my: 2, textAlign: 'center'}}>Please select a Remitter</Typography>
                                                :
                                                <EntityDropdown
                                                    label={'Remittee'}
                                                    name={'template_remittee'}
                                                    api={remitteeList}
                                                    disabled={Boolean(propsParams?.template_remittee)}
                                                />
                                        }
                                    </Grid>
                                    <Grid item xs={3}>
                                        <EntityDropdown
                                            label={'Accountant'}
                                            name={'template_accountant'}
                                            api={accountantList}
                                            disabled={Boolean(propsParams?.template_remitter)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            name={'remitter_name'}
                                            label={'Remitter Name'}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            name={'remittee_name'}
                                            label={'Remittee Name'}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            name={'invoice_number'}
                                            label={'Invoice Number'}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            name={'id'}
                                            label={'SR. No.'}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            name={'token_number'}
                                            label={'Token Number'}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            name={'acknowledgement_number'}
                                            label={'Acknowledgement Number'}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            name={'udin_number'}
                                            label={'UDIN Number'}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            name={'status'}
                                            label={'Status'}
                                            options={cbMapping}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            select
                                            fullWidth
                                            name={'nature_of_remittance'}
                                            label={'Nature of Transaction'}
                                            options={Mapping.remittee.natureRemCategory}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            startIcon={<ClearAllIcon/>}
                                            variant={'outlined'}
                                            sx={{width: '100%'}}
                                            onClick={() => formik.resetForm()}
                                        >
                                            Clear Filters
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            type={'submit'}
                                            startIcon={<FilterListIcon/>}
                                            variant={'contained'}
                                            sx={{width: '100%'}}
                                        >
                                            Apply Filters
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>)}
                        </Formik>
                    </AccordionDetails>
                </Accordion>
            </Paper>

            <FormModal
                open={summaryOpen}
                title={'Summary'}
                width={'800px'}
                maxWidth={'xl'}
            >
                <SummaryFilters setSummaryOpen={setSummaryOpen}/>
            </FormModal>

            <DataTable
                headerButton={<Button variant={'outlined'} onClick={() => setSummaryOpen(true)}>Get Summary</Button>}
                message={'Select the transaction to preview their details.'}
                header={<Header/>}
                rowParser={(data) => <Row element={data}/>}
                queryUrl={'cbs/'}
                queryKey={['cbs']}
                queryParams={params}
            />
        </Box>
    )
}