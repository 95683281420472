import * as Yup from "yup";
import {
    dropDownErrorMsg,
    lengthErrorMsg,
    maxLengthErrorMsg,
    minLengthErrorMsg
} from "../utils/constants/YupErrorMessages";
import {ascii} from "../utils/utils";

const validationSchema = Yup.object().shape({
    i_or_we: ascii.notOneOf(["-1"], dropDownErrorMsg).max(2, maxLengthErrorMsg).required('I/We is required'),
    honorific: ascii.notOneOf(["-1"], dropDownErrorMsg).max(2, maxLengthErrorMsg).required('Honorific is required'),
    name: ascii.max(125, maxLengthErrorMsg).uppercase().required('Name is required'),
    // eslint-disable-next-line no-undef
    pan_tan: ascii.length(10, lengthErrorMsg).uppercase().required('PAN is required'),
    bank_name: ascii.notOneOf(["-1"], dropDownErrorMsg).max(4, maxLengthErrorMsg).required('Bank Name is required'),
    bank_name_other: ascii.max(125, maxLengthErrorMsg).uppercase().when('bank_name', {
        is: val => val === '999',
        then: schema => schema.required('Bank Name other is required'),
        otherwise: schema => schema.nullable()
    }),
    branch_name: ascii.max(125, maxLengthErrorMsg).uppercase().required('Branch name is required'),
    bsr_code: ascii.max(7, maxLengthErrorMsg).min(7, minLengthErrorMsg).required('BSR Code is required'),
    accountant: Yup.string().notOneOf(["-1"], dropDownErrorMsg).required('Accountant is required'),
    is_ca_applicable: Yup.string().max(1, maxLengthErrorMsg).required(),
    area_code: ascii.max(3, maxLengthErrorMsg).uppercase().nullable(),
    ao_type: ascii.max(2, maxLengthErrorMsg).uppercase().nullable(),
    range_code: ascii.max(3, maxLengthErrorMsg).nullable(),
    ao_number: ascii.max(2, maxLengthErrorMsg).nullable(),
    flat_door_building: ascii.max(50, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Flat/Foor/Building is required'),
        otherwise: schema => schema.nullable()
    }),
    premise_building_village: ascii.max(50, maxLengthErrorMsg).nullable(),
    road_street: ascii.max(50, maxLengthErrorMsg).nullable(),
    area_locality: ascii.max(50, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Area/Locality is required'),
        otherwise: schema => schema.nullable()
    }),
    town_city_district: ascii.max(50, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Town/City/District is required'),
        otherwise: schema => schema.nullable()
    }),
    state: ascii.max(4, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('State is required'),
        otherwise: schema => schema.nullable()
    }),
    country: ascii.max(4, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('Country is required'),
        otherwise: schema => schema.nullable()
    }),
    pincode: ascii.max(6, maxLengthErrorMsg).min(6, minLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y', then: schema => schema.required('Pincode is required'), otherwise: schema => schema.nullable()
    }),
    place_of_business: ascii.max(75, maxLengthErrorMsg).uppercase().when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Place of Business is required'),
        otherwise: schema => schema.nullable()
    }),
    email: ascii.email().max(125, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y', then: schema => schema.required('Email is required'), otherwise: schema => schema.nullable()
    }),
    phone: ascii.max(10, maxLengthErrorMsg).min(10, minLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y', then: schema => schema.required('Phone is required'), otherwise: schema => schema.nullable()
    }),
    status: ascii.max(2, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('Status is required'),
        otherwise: schema => schema.nullable()
    }),
    residential_status: ascii.max(2, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('Residential Status is required'),
        otherwise: schema => schema.nullable()
    }),
    landlord_name: ascii.max(125, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Parent/Guardian name is required'),
        otherwise: schema => schema.nullable()
    }),
    verification_person_name: ascii.max(125, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Person Name is required'),
        otherwise: schema => schema.nullable()
    }),
    verification_i_we: ascii.max(2, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(["-1"], dropDownErrorMsg).required('Verification I/We is required'),
        otherwise: schema => schema.nullable()
    }),
    verification_designation: ascii.max(20, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y',
        then: schema => schema.required('Designation is required'),
        otherwise: schema => schema.nullable()
    }),
    verification_place: ascii.max(35, maxLengthErrorMsg).when('is_ca_applicable', {
        is: val => val === 'Y', then: schema => schema.required('Place is required'), otherwise: schema => schema.nullable()
    }),
});

export default validationSchema;
