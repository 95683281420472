import withPermission from "../utils/components/PermissionRequired";
import {organizationalUser} from "../utils/constants/UserTypes";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    Button,
    Container, Grid,
    IconButton,
    TableCell,
    Toolbar,
    Typography
} from "@mui/material";
import DataTable from "../utils/components/DataTable";
import {downloadBlobFile, FormModal, parseDate, ShowChips, showDownloadProgress, useConfirmation} from "../utils/utils";
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import API from "../utils/API";
import {useContext, useRef, useState} from "react";
import {LabeledProgressContext} from "../utils/LabeledProgressContext";
import {useMutation} from "@tanstack/react-query";
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from "@mui/icons-material/FilterList";
import {Form, Formik} from "formik";
import TextField from "../utils/components/TextField";
import ClearAllIcon from "@mui/icons-material/ClearAll";

async function downloadFileAPI(data, setProgress, handleLabeledProgressClose) {
    const response = await API.post(`/rate-cards/${data.id}/download/`, {},
        {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                showDownloadProgress(progressEvent, setProgress, handleLabeledProgressClose)
            }
        });
    return response.data
}

function Header() {
    return (
        <>
            <TableCell>File Name</TableCell>
            <TableCell>Download Time</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Preview</TableCell>
            <TableCell>Download</TableCell>
        </>
    )
}

const timeOfDayMapping = {
    "0": "Morning - 11AM",
    "1": "Afternoon - 3PM"
}

function Row({element, handleDownload, handlePreview}) {
    return (
        <>
            <TableCell>{element.file_name}</TableCell>
            <TableCell>{timeOfDayMapping[element.time_of_day.toString()]}</TableCell>
            <TableCell>{parseDate(element.card_date)}</TableCell>
            <TableCell>
                <IconButton color={'primary'} onClick={() => {
                    handleDownload({...element, preview: true})
                    handlePreview()
                }}>
                    <PreviewIcon/>
                </IconButton>
            </TableCell>
            <TableCell>
                <IconButton color={'primary'} onClick={() => handleDownload(element)}>
                    <DownloadIcon/>
                </IconButton>
            </TableCell>
        </>
    )
}

const labelMapping = {
    month: 'MONTH',
    year: 'YEAR',
    date: "DATE"
}

const MonthMapping = {
    "-1": "SELECT",
    "01": "JANUARY",
    "02": "FEBRUARY",
    "03": "MARCH",
    "04": "APRIL",
    "05": "MAY",
    "06": "JUNE",
    "07": "JULY",
    "08": "AUGUST",
    "09": "SEPTEMBER",
    "10": "OCTOBER",
    "11": "NOVEMBER",
    "12": "DECEMBER",
}

const YearMapping = {
    "2024": "2024",
    "-1": "SELECT"
}


function RateCards() {
    const {handleLabeledProgressOpen, handleLabeledProgressClose, setProgressValue} = useContext(LabeledProgressContext)
    const {open: modalOpen, handleOpen: handleModalOpen, handleClose: handleModalClose} = useConfirmation(
        () => setPdfUrl(null)
    );
    const [pdfUrl, setPdfUrl] = useState(null);
    const [filterChips, setFilterChips] = useState([]);
    const formikRef = useRef();
    const [params, setParams] = useState({});

    const initialValues = {
        month: '-1',
        year: '-1',
        date: ''
    }

    const handleFilterSubmit = (values) => {
        let chips = [];
        let finalFilters = {};
        for (let key of Object.keys(values)) {
            if (values[key] !== '' && values[key] !== '-1') {
                if (key === 'month') {
                    chips.push([labelMapping[key], MonthMapping[values[key]], key, '-1']);
                } else if (key === 'year') {
                    chips.push([labelMapping[key], YearMapping[values[key]], key, '-1']);
                } else if (key === 'date'){
                    chips.push([labelMapping[key], parseDate(values[key]), key, '']);
                }
                finalFilters[key] = values[key];
            }
        }
        setFilterChips(chips);
        setParams(finalFilters)
    }

    const downloadSingleFile = useMutation(
        ['rate-cards', 'download'],
        (id) => downloadFileAPI(id, setProgressValue, handleLabeledProgressClose),
        {
            onMutate: () => handleLabeledProgressOpen(),
            onSuccess: (data, variables, context) => {
                if (variables['preview'] === true) {
                    setPdfUrl(URL.createObjectURL(data));
                } else {
                    downloadBlobFile(`${parseDate(variables.card_date)}-${variables.file_name}`, data);
                }
            }
        }
    )

    return (
        <>
            <Toolbar>
                <Typography variant={'navbarTitle'}>SBI Rate Cards</Typography>
            </Toolbar>
            <Container
                sx={{
                    position: "relative",
                }}>

                <Accordion defaultExpanded>
                    <AccordionSummary>
                        <Badge sx={{mr: 3, mt: 1}} badgeContent={filterChips.length} color="primary">
                            <FilterListIcon color="action"/>
                        </Badge>
                        <Typography variant={'h6'}>
                            Filters
                        </Typography>
                        <Box sx={{ml: 3, display: 'flex', gap: 2, my: 'auto', flexWrap: 'wrap'}}>
                            <ShowChips
                                filterChips={filterChips}
                                formikRef={formikRef}
                            />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Formik
                            innerRef={formikRef}
                            initialValues={initialValues}
                            onSubmit={handleFilterSubmit}
                        >
                            {
                                formik => (
                                    <Form>
                                        <Grid container columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    type={'date'}
                                                    name={'date'}
                                                    label={'Date'}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    name={'month'}
                                                    label={'Month'}
                                                    options={MonthMapping}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    name={'year'}
                                                    label={'Year'}
                                                    options={YearMapping}
                                                />
                                            </Grid>
                                            <Grid item xs={4}></Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    startIcon={<ClearAllIcon/>}
                                                    variant={'outlined'}
                                                    sx={{width: '100%'}}
                                                    onClick={() => formik.resetForm()}
                                                >
                                                    Clear Filters
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    type={'submit'}
                                                    startIcon={<FilterListIcon/>}
                                                    variant={'contained'}
                                                    sx={{width: '100%'}}
                                                >
                                                    Apply Filters
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }
                        </Formik>
                    </AccordionDetails>
                </Accordion>

                <DataTable
                    header={<Header/>}
                    rowParser={(data) => <Row element={data} handleDownload={downloadSingleFile.mutate}
                                              handlePreview={handleModalOpen}/>}
                    queryUrl={'rate-cards/'}
                    queryKey={['rate-cards']}
                    queryParams={params}
                />
            </Container>
            <FormModal
                open={modalOpen}
                title={'Rate Card'}
                footer={<Button variant={'contained'} startIcon={<CloseIcon/>}
                                onClick={() => handleModalClose(true)}>Close</Button>}
                maxWidth={'xl'}
                width={'80vw'}
            >
                <Box sx={{
                    height: '60vh'
                }}>
                    <object
                        data={pdfUrl}
                        type="application/pdf"
                        width={'100%'}
                        height={'100%'}
                    >
                        <p>Your browser does not support PDFs. Please download the PDF to view it.</p>
                    </object>
                </Box>
            </FormModal>
        </>
    )
}

export default withPermission(RateCards, organizationalUser);

