import React, {useContext, useEffect, useState} from "react";
import {CBContext} from "./CBContext";
import {
    Alert, Box, Button, CircularProgress, Collapse, Grid, List, ListItem, Paper, Tooltip, Typography
} from "@mui/material";
import {Formik} from "formik";
import TextField from "../utils/components/TextField";
import AutoCompleteField from "../utils/components/AutoCompleteField";
import RadioField from "../utils/components/RadioField";
import Mapping from "../utils/UtilityMapping.json";
import {computationValidationSchema, remittanceValidationSchema} from "./validations";
import FunctionsIcon from '@mui/icons-material/Functions';
import HelpIcon from '@mui/icons-material/Help';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import API from "../utils/API";
import {ProgressContext} from "../utils/ProgressContext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ComputationModal from "./ComputationModal";
import {useNavigate} from "react-router-dom";
import {generalAPI, makeNulls, removeNulls} from "./utils";
import {getPatchValues} from "../utils/utils";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {formData} from "./initialValues";
import APIError from "../utils/components/APIError";

const dtaaSections = ['A. If the Remittance is for royalties, fee for technical services, interests, dividend, etc (Not connected with permenant establishment) please indicate', 'B. Incase the remittance is on the account of business income, please indicate', 'C. Incase the remittance is on the account of capital gains, please indicate', 'D. Incase if the remittance is not covered by sub-items A, B, and C'];


const computationFields = ['is_system_calculation_applicable', 'expenses_reimbursement_frgn', 'is_grossed_up', 'is_it_taxable', 'it_rate', 'is_dtaa_taxable', 'dtaa_rate'];


const replicateFields = ['template_remitter', 'template_remittee', 'template_accountant', 'is_grossed_up', 'is_it_taxable', 'it_relevant_section_act', 'it_rate', 'it_basis', 'it_not_taxable_reason', 'is_dtaa_taxable', 'dtaa_act', 'dtaa_article', 'dtaa_taxable_income', 'dtaa_tax_liability', 'dtaa_section', 'dtaa_rate', 'dtaa_sect_a_article', 'is_dtaa_sect_b_taxable', 'dtaa_sect_b_taxable_basis', 'dtaa_sect_b_non_taxable_article', 'dtaa_sect_c_basis', 'dtaa_sect_d_nature', 'is_dtaa_sect_d_taxable', 'dtaa_sect_d_taxable_article', 'dtaa_sect_d_non_taxable_article']


const systemCalculationTooltip = 'If system calculation is enabled, then the disabled fields will be filled after the backend performs the computation'


async function computationAPI(data) {
    const response = await API.post('cbs/computation/', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }, formSerializer: {
            dots: true
        }
    });
    return response.data;
}


async function cbAPI(data) {
    const response = await API.post('/cbs/', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }, formSerializer: {
            dots: true
        }
    });
    return response.data;
}


async function cbUpdateAPI(id, data) {
    const response = await API.patch(`cbs/${id}/`, data);
    return response.data;
}

function DTAAFields(props) {
    const {cbState} = useContext(CBContext)
    const isReading = cbState.baseData.status >= 2;
    const {formik, error} = props;

    switch (parseInt(formik.values.dtaa_section)) {
        case 0:
            return <></>
        case 1:
            return <>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>DTAA Section A</Typography>
                </Grid>
                <Grid item xs={4}>
                    <AutoCompleteField
                        name={'dtaa_sect_a_article'}
                        label={'Article of DTAA'}
                        fillWidth
                        upperCase
                        required
                        isReading={isReading}
                        options={[
                            'ARTICLE 10',
                            'ARTICLE 11',
                            'ARTICLE 12',
                            'ARTICLE 13',
                            'ARTICLE 7 READ WITH ARTICLE 5'
                        ]}
                        apiError={error}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        name={'dtaa_sect_a_rate'}
                        label={'Rate of TDS required to be deducted in terms of such article of the applicable DTAA'}
                        type={'number'}
                        fullWidth
                        required
                        isReading={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                        apiError={error}
                    />
                </Grid>
            </>
        case 2:
            return <>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>DTAA Section B</Typography>
                </Grid>
                <Grid item xs={12}>
                    <RadioField
                        row
                        label={'Whether such income is taxable to tax in India?'}
                        name={'is_dtaa_sect_b_taxable'}
                        required
                        isReading={isReading}
                        apiError={error}
                    />
                </Grid>
                {formik.values.is_dtaa_sect_b_taxable === 'Y' ? <Grid item xs={4}>
                    <AutoCompleteField
                        name={'dtaa_sect_b_taxable_basis'}
                        label={'If so, the basis of arriving at the rate of deduction of tax'}
                        fullWidth
                        required
                        upperCase
                        isReading={isReading}
                        options={[
                            'ARTICLE 10',
                            'ARTICLE 11',
                            'ARTICLE 12',
                            'ARTICLE 13',
                            'ARTICLE 7 READ WITH ARTICLE 5'
                        ]}
                        apiError={error}
                    />
                </Grid> : <Grid item xs={4}>
                    <AutoCompleteField
                        name={'dtaa_sect_b_non_taxable_article'}
                        label={'If not, please furnish the reason there of, specifying relevant article of DTAA'}
                        fullWidth
                        required
                        upperCase
                        isReading={isReading}
                        options={[
                            'ARTICLE 10',
                            'ARTICLE 11',
                            'ARTICLE 12',
                            'ARTICLE 13',
                            'ARTICLE 7 READ WITH ARTICLE 5'
                        ]}
                        apiError={error}
                    />
                </Grid>}
                <Grid item xs={8}/>
            </>
        case 3:
            return <>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>DTAA Section C</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name={'dtaa_sect_c_amt_long_term'}
                        label={'Amount of long term capital gains'}
                        type={'number'}
                        fullWidth
                        required
                        isReading={isReading}
                        apiError={error}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        type={'number'}
                        name={'dtaa_sect_c_amt_short_term'}
                        label={'Amount of short term capital gains'}
                        fullWidth
                        required
                        isReading={isReading}
                        apiError={error}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name={'dtaa_sect_c_basis'}
                        label={'Basis of arriving at taxable income'}
                        fullWidth
                        required
                        upperCase
                        isReading={isReading}
                        apiError={error}
                    />
                </Grid>
            </>
        case 4:
            return <>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>DTAA Section D</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name={'dtaa_sect_d_nature'}
                        label={'Please specify the nature of remittance'}
                        fullWidth
                        required
                        upperCase
                        isReading={isReading}
                        apiError={error}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RadioField
                        row
                        required
                        isReading={isReading}
                        name={'is_dtaa_sect_d_taxable'}
                        label={'Whether taxable in India as per DTAA'}
                        apiError={error}
                    />
                </Grid>
                {formik.values.is_dtaa_sect_d_taxable === 'Y' ? <Grid item xs={4}>
                    <TextField
                        name={'dtaa_sect_d_taxable_article'}
                        label={'If yes, the rate of TDS required to be deducted in terms of such article of the applicable DTAA'}
                        fullWidth
                        required
                        upperCase
                        isReading={isReading}
                        apiError={error}
                    />
                </Grid> : <Grid item xs={4}>
                    <TextField
                        name={'dtaa_sect_d_non_taxable_article'}
                        label={'If not, then please furnish the reason there of, specifying relevant article of DTAA'}
                        fullWidth
                        required
                        upperCase
                        isReading={isReading}
                        apiError={error}
                    />
                </Grid>}
            </>
        default:
            return <></>
    }

}

function FindErrorTab(props) {
    const {error} = props;
    const responseError = []
    if (error.response?.data) {
        const data = error.response.data;

        if (Object.keys(data).includes('remitter')) {
            responseError.push('Error in Remitter');
        }
        if (Object.keys(data).includes('remittee')) {
            responseError.push('Error in Remittee');
        }
        if (Object.keys(data).includes('accountant')) {
            responseError.push('Error in Accountant');
        }
        if (Object.keys(data).includes('invoices')) {
            responseError.push('Error in invoices')
        }
        if (responseError.length > 0) {
            return <>
                <Alert severity={'error'} sx={{my: 1, width: '50%'}}>
                    <List disablePadding>
                        {responseError.map((err, index) => (<ListItem key={index} disablePadding>
                            {err}
                        </ListItem>))}
                    </List>
                </Alert>
            </>
        }
        return <></>
    }
    return <></>
}

export default function RemittanceForm() {

    const {cbState, cbDispatch} = useContext(CBContext)
    const isReading = cbState.baseData.status >= 2;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const [computationError, setComputationError] = useState(false);
    const [computationModal, setComputationModal] = useState(false);
    const remittanceRef = cbState.baseData.remittanceRef;
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const computation = useMutation(['cbs', 'computation'], computationAPI, {
        onMutate: () => handleProgressOpen(), onSettled: () => handleProgressClose(), onSuccess: (data) => {
            setComputationModal(true);
            cbDispatch({
                type: 'addFormData', data: {remittance: {...remittanceRef.current.values, ...data.cb}}
            });
        }
    });

    const cbCreate = useMutation(['cbs'], cbAPI, {
        onMutate: variables => {
            for (let key in variables) {
                if (variables[key] === '') {
                    variables[key] = null
                }
            }
            handleProgressOpen()
            return variables
        }, onSettled: () => handleProgressClose(), onSuccess: () => {
            navigate(-1)
        }
    })

    const cbUpdate = useMutation(['cbs', cbState.baseData.transactionId], (data) => cbUpdateAPI(cbState.baseData.transactionId, data), {
        onMutate: variables => {
            variables = makeNulls(variables);
            handleProgressOpen()
            return variables
        }, onSettled: () => {
            handleProgressClose()
        }, onSuccess: () => {
            queryClient.invalidateQueries(['cbs', cbState.baseData.transactionId])
        }
    })

    const {
        data: cbData,
        isFetching: cbIsFetching,
        refetch: cbRefetch
    } = useQuery(['cbs', cbState.baseData.transactionId], () => generalAPI(`cbs/${cbState.baseData.transactionId}/`), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: (cbState.baseData.status !== 0 || cbState.baseData.cloning === true) && !cbState.baseData.pages[cbState.baseData.activeStep].visited,
        select: data => {
            let finalData = {...formData.remittanceFunc()};
            if (cbState.baseData.cloning) {
                for (let key of replicateFields) {
                    finalData[key] = data[key];
                }
            } else {
                finalData = removeNulls(data);
            }
            return finalData;
        }
    });

    useEffect(() => {
        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited && cbData && (cbState.baseData.status !== 0 || cbState.baseData.cloning)) {
            cbDispatch({
                type: 'addUpdateFormData', data: {'remittance': cbData}
            })
        }

    }, [cbData, cbState.baseData.status, cbState.baseData.activeStep, cbState.baseData.pages, cbState.baseData.cloning, cbDispatch]);


    function handleComputationValidation() {
        let values = {...remittanceRef.current.values};
        const setFieldTouched = remittanceRef.current.setFieldTouched;
        const isValid = computationValidationSchema.isValidSync(values);
        if (!isValid) {
            setComputationError(true)
            for (let field of computationFields) {
                setFieldTouched(field, true, true)
            }
        } else {
            values['remitter'] = cbState.formData.remitter;
            values['remittee'] = cbState.formData.remittee;
            values['invoices'] = cbState.formData.invoices.invoices;
            computation.mutate(values)
        }
    }

    function handleSaveRemittance(func) {
        cbDispatch({
            type: 'addFormData', data: {
                remittance: remittanceRef.current.values
            }
        });
        func();
    }

    function handleUpdateRemittance() {
        let remittance = remittanceRef.current.validateForm();
        remittance.then(res => {
            if (Object.keys(res).length === 0) {
                const patchData = getPatchValues(cbState.updateData.remittance, remittanceRef.current.values);
                cbUpdate.mutate(patchData, {
                    onSuccess: () => cbRefetch(),
                })
            } else {
                for (let key of Object.keys(res)) {
                    remittanceRef.current.setFieldTouched(key, true, true);
                }
            }
        })
    }

    const isError = cbCreate.isError || cbUpdate.isError || computation.isError
    const error = cbCreate.error || cbUpdate.error || computation.error

    return (<>
        <Paper sx={{p: 3}}>
            <Formik
                innerRef={remittanceRef}
                initialValues={cbState.formData.remittance}
                validationSchema={remittanceValidationSchema}
                validateOnChange={false}
                enableReinitialize={true}
                onSubmit={(values) => {
                    if (cbState.baseData.status === 0 && !cbState.baseData.cloning) {
                        values['template_remitter'] = cbState.baseData.remitterId;
                        values['template_remittee'] = cbState.baseData.remitteeId;
                        values['template_accountant'] = cbState.baseData.accountantId;
                    }
                    values['remitter'] = cbState.formData.remitter;
                    values['remittee'] = cbState.formData.remittee;
                    values['accountant'] = cbState.formData.accountant;
                    values['invoices'] = cbState.formData.invoices.invoices;
                    cbCreate.mutate(values);
                }}
            >
                {formik => (<>
                    <Box sx={{position: 'relative'}}>
                        <Box sx={{position: 'absolute', right: '0px'}}>
                            {cbState.baseData.status === 1 && <>
                                <Button startIcon={<CancelIcon/>}
                                        onClick={() => remittanceRef.current.resetForm({values: cbState.updateData.remittance})}>Cancel</Button>
                                <Button sx={{ml: 3}} startIcon={<SaveIcon/>}
                                        onClick={handleUpdateRemittance}>Update</Button>
                            </>}
                        </Box>
                        <Box sx={{position: 'absolute', right: '0px', top: '56px'}}>
                            {(formik.values.is_system_calculation_applicable === 'Y') && <>
                                <Tooltip title={'System performs computation, fills the disabled fields'}>
                                    <Button disabled={cbState.baseData.status >= 2} sx={{ml: 3}} onClick={handleComputationValidation}
                                            startIcon={<FunctionsIcon/>} variant={'contained'}>
                                        Get Computation
                                    </Button>
                                </Tooltip>
                            </>}
                        </Box>

                        <Box
                            sx={{
                                position: 'absolute', top: '0px', left: '200px'
                            }}
                        >
                            {cbIsFetching && <CircularProgress size={'2rem'}/>}
                        </Box>
                    </Box>
                    <Box>
                        {computationError && <Alert sx={{width: '50%', my: 2}} severity={'error'}
                                                    onClose={() => setComputationError(false)} icon={false}>
                            Fill all the fields required for computation
                        </Alert>}
                    </Box>
                    {isError && <Box sx={{my: 2, width: '50%'}}>
                        <APIError error={error}/>
                    </Box>}
                    {
                        cbCreate.isError && <Box>
                            <FindErrorTab error={cbCreate.error}/>
                        </Box>
                    }
                    <Grid container columnSpacing={4} rowSpacing={4}>
                        <Grid item xs={12} mt={-4}>
                            <Typography variant={'h6'}>
                                Remittance Details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioField
                                row
                                isReading={isReading}
                                name={'is_system_calculation_applicable'}
                                label={<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                    Is System CalculationForm Applicable?
                                    <Tooltip placement={'right'} title={systemCalculationTooltip}>
                                        <HelpIcon/>
                                    </Tooltip>
                                </Box>}
                                apiError={error}
                            />
                        </Grid>
                        {formik.values.is_system_calculation_applicable === 'Y' && <>
                            <Grid item xs={12}>
                                <RadioField
                                    required
                                    isReading={isReading}
                                    name={'rounding'}
                                    label={'Rounding Schema'}
                                    options={[[0, 'No Rounding: (10.05) -> (10), (11.50) -> (12)'], [2, 'Rounding upto 2 decimal: (10.005) -> (10.01), (11.444) -> (11.44)'],]}
                                    apiError={error}
                                />
                            </Grid>
                        </>}
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required={!(formik.values.is_system_calculation_applicable === 'Y')}
                                type={'number'}
                                disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                label={'Amount Payable (Foreign Currency)'}
                                name={'amt_payable_frgn'}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required={!(formik.values.is_system_calculation_applicable === 'Y')}
                                type={'number'}
                                disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                label={'Amount Payable (Indian Currency)'}
                                name={'amt_payable_inr'}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required={!(formik.values.is_system_calculation_applicable === 'Y')}
                                type={'number'}
                                disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                label={'Exchange Rate'}
                                name={'exchange_rate'}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                type={'number'}
                                isReading={isReading}
                                label={'Expense Reimbursement (Foreign Currency)'}
                                name={'expenses_reimbursement_frgn'}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                type={'date'}
                                disabled={isReading}
                                label={'Proposed Date of Remittance'}
                                name={'proposed_date_of_remittance'}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                type={'date'}
                                disabled={isReading}
                                label={'Mail Received Time'}
                                name={'mail_received'}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RadioField
                                row
                                required
                                isReading={isReading}
                                name={'is_grossed_up'}
                                label={'Is Grossed Up?'}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'h6'}>
                                Taxability under the provision of Income-Tax act (without considering DTAA)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioField
                                row
                                required
                                isReading={isReading}
                                name={'is_it_taxable'}
                                label={'Is Taxable as per Income Tax ACT?'}
                                apiError={error}
                            />
                        </Grid>
                        {(formik.values.is_it_taxable === 'Y') ? <>
                            <Grid item xs={4}>
                                <AutoCompleteField
                                    name={'it_relevant_section_act'}
                                    label={'Relevant Section of Act under which remittance is covered'}
                                    fullWidth
                                    required={(formik.values.is_it_taxable === 'Y')}
                                    upperCase
                                    isReading={isReading}
                                    options={['9(1)(I)', '194LBB', 'SECTION 115TCA', '9(1)(V)', '9(1)(VI)', '9(1)(VII)']}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutoCompleteField
                                    name={'it_basis'}
                                    label={'Basis of determining taxable income and tax liability'}
                                    fullWidth
                                    required={(formik.values.is_it_taxable === 'Y')}
                                    upperCase
                                    isReading={isReading}
                                    options={['SECTION 115A OF THE ACT', '9(1)(I)']}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name={'it_taxable_income'}
                                    label={'Amount of income chargeable to tax'}
                                    fullWidth
                                    required={(formik.values.is_it_taxable === 'Y')}
                                    type={'number'}
                                    disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name={'it_tax_liability'}
                                    label={'Tax Liability'}
                                    fullWidth
                                    required={(formik.values.is_it_taxable === 'Y')}
                                    type={'number'}
                                    disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    (formik.values.is_it_taxable === 'Y') && (formik.values.is_system_calculation_applicable === 'Y') && (
                                        <AutoCompleteField
                                            name={'it_rate'}
                                            label={'Rate as per Income-Tax Act'}
                                            type={'number'}
                                            fullWidth
                                            required={(formik.values.is_it_taxable === 'Y') && (formik.values.is_system_calculation_applicable === 'Y')}
                                            disabled={isReading || !(formik.values.is_system_calculation_applicable === 'Y')}
                                            options={['20.8', '21.84', '23.92', '43.68']}
                                            apiError={error}
                                        />
                                    )
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'h6'}>
                                    If income is chargeable to tax in India and any relief is
                                    claimed under DTAA
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioField
                                    row
                                    required
                                    isReading={isReading}
                                    name={'is_dtaa_taxable'}
                                    label={'Is Taxable as per DTAA? (Please indicate whether tax residency certificate is obtained from the recipient of remittance)'}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutoCompleteField
                                    name={'dtaa_act'}
                                    label={'Please specify relevant DTAA'}
                                    fullWidth
                                    upperCase
                                    isReading={isReading}
                                    options={[
                                        'INDIA -  DTAA', 'INDIA - AFGHANISTAN DTAA', 'INDIA - ALBANIYA DTAA',
                                        'INDIA - ARGENTINE DTAA', 'INDIA - ARMENIA DTAA', 'INDIA - AUSTRALIA DTAA',
                                        'INDIA - AUSTRIA DTAA', 'INDIA - BAHAMAS DTAA', 'INDIA - BAHRAIN DTAA',
                                        'INDIA - BANGLADESH DTAA', 'INDIA - BELARUS DTAA', 'INDIA - BELGIUM DTAA',
                                        'INDIA - BELIZE DTAA', 'INDIA - BERMUDA DTAA', 'INDIA - BHUTAN DTAA',
                                        'INDIA - BOTSWANA DTAA', 'INDIA - BRAZIL DTAA',
                                        'INDIA - BRITISH VIRGIN ISLANDS DTAA', 'INDIA - BRUNEI DARUSSALAM DTAA',
                                        'INDIA - BULGARIA DTAA', 'INDIA - CANADA DTAA', 'INDIA - CAYMAN ISLANDS DTAA',
                                        'INDIA - CHILE DTAA', 'INDIA - CHINA DTAA', 'INDIA - COLOMBIA DTAA',
                                        'INDIA - CROATIA DTAA', 'INDIA - CYPRUS DTAA',
                                        'INDIA - CZECH REPUBLIC DTAA', 'INDIA - DENMARK DTAA', 'INDIA - ESTONIA DTAA',
                                        'INDIA - ETHIOPIA DTAA', 'INDIA - FIJI DTAA', 'INDIA - FINLAND DTAA',
                                        'INDIA - FRANCE DTAA', 'INDIA - GEORGIA DTAA', 'INDIA - GERMANY DTAA',
                                        'INDIA - GIBRALTAR DTAA', 'INDIA - GREECE DTAA', 'INDIA - GUERNSEY DTAA',
                                        'INDIA - HASHEMITE KINGDOM OF JORDAN DTAA', 'INDIA - HONG KONG DTAA',
                                        'INDIA - HUNGARY DTAA', 'INDIA - ICELAND DTAA', 'INDIA - INDONESIA DTAA',
                                        'INDIA - IRAN DTAA', 'INDIA - IRELAND DTAA', 'INDIA - ISLE OF MAN DTAA',
                                        'INDIA - ISRALE DTAA', 'INDIA - ITALY DTAA', 'INDIA - JAPAN DTAA',
                                        'INDIA - JERSEY DTAA', 'INDIA - KAZAKHSTAN DTAA', 'INDIA - KENYA DTAA',
                                        'INDIA - KOREA DTAA', 'INDIA - KUWAIT DTAA', 'INDIA - KYRGYZ REPUBLIC DTAA',
                                        'INDIA - LATVIA DTAA', 'INDIA - LEBANON DTAA', 'INDIA - LIBERIA DTAA',
                                        'INDIA - LIBYA DTAA', 'INDIA - LITHUANIA DTAA', 'INDIA - LUXEMBOURG DTAA',
                                        'INDIA - MACAO SAR DTAA', 'INDIA - MACEDONIA DTAA', 'INDIA - MALAYSIA DTAA',
                                        'INDIA - MALDIVES DTAA', 'INDIA - MALTA DTAA', 'INDIA - MARSHALL ISLAND DTAA',
                                        'INDIA - MAURITIUS DTAA', 'INDIA - MONGOLIA DTAA', 'INDIA - MONTENEGRO DTAA',
                                        'INDIA - MOROCCO DTAA', 'INDIA - MOZAMBIQUE DTAA',
                                        'INDIA - MYANMAR (UNION OF MYANMAR) DTAA', 'INDIA - NAMIBIA DTAA',
                                        'INDIA - NEPAL DTAA', 'INDIA - NETHERLANDS DTAA', 'INDIA - NEW ZEALAND DTAA',
                                        'INDIA - NORWAY DTAA', 'INDIA - OMAN DTAA',
                                        'INDIA - ORIENTAL REPUBLIC OF URUGUAY DTAA', 'INDIA - PAKISTAN DTAA',
                                        'INDIA - PEOPLE DEMOCRATIC REPUBLIC OF YEMEN DTAA', 'INDIA - PHILIPPINES DTAA',
                                        'INDIA - POLAND DTAA', 'INDIA - PORTUGUESE REPUBLIC DTAA', 'INDIA - QATAR DTAA',
                                        'INDIA - ROMANIA DTAA', 'INDIA - RUSSIA DTAA',
                                        'INDIA - SAINT KITTS AND NEVIS DTAA',
                                        'INDIA - SAINT VINCENT AND THE GRENADINES DTAA', 'INDIA - SAN MARINO DTAA',
                                        'INDIA - SAUDI ARABAI DTAA', 'INDIA - SERBIA DTAA', 'INDIA - SEYCHELLES DTAA',
                                        'INDIA - SINGAPORE DTAA', 'INDIA - SLOVAK REPUBLIC DTAA',
                                        'INDIA - SLOVENIA DTAA', 'INDIA - SOUTH AFRICA DTAA', 'INDIA - SPAIN DTAA',
                                        'INDIA - SRI LANKA DTAA', 'INDIA - SUDAN DTAA', 'INDIA - SWEDEN DTAA',
                                        'INDIA - SWISS CONFEDERATION DTAA', 'INDIA - SYRIAN ARAB REPUBLIC DTAA',
                                        'INDIA - TAIPEI DTAA', 'INDIA - TAJIKISTAN DTAA', 'INDIA - TANZANIA DTAA',
                                        'INDIA - THAILAND DTAA', 'INDIA - TRINIDAD AND TOBAGO DTAA',
                                        'INDIA - TURKEY DTAA', 'INDIA - TURKMENISTAN DTAA', 'INDIA - UAE DTAA',
                                        'INDIA - UAR (EGYPT) DTAA', 'INDIA - UGANDA DTAA', 'INDIA - UK DTAA',
                                        'INDIA - UKRAINE DTAA', 'INDIA - UNITED MEXICAN STATES DTAA',
                                        'INDIA - USA DTAA', 'INDIA - UZBEKISTAN DTAA', 'INDIA - VIETNAM DTAA',
                                        'INDIA - YEMEN ARAB REPUBLIC DTAA', 'INDIA - ZAMBIA DTAA'
                                    ]}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutoCompleteField
                                    name={'dtaa_article'}
                                    label={'Please specify relevant article of DTAA'}
                                    fullWidth
                                    upperCase
                                    isReading={isReading}
                                    options={[
                                        'ARTICLE 10',
                                        'ARTICLE 11',
                                        'ARTICLE 12',
                                        'ARTICLE 13',
                                        'ARTICLE 7 READ WITH ARTICLE 5'
                                    ]}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name={'dtaa_taxable_income'}
                                    label={'Taxable Income as per DTAA'}
                                    fullWidth
                                    type={'number'}
                                    disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name={'dtaa_tax_liability'}
                                    label={'Tax Liability as per DTAA'}
                                    fullWidth
                                    type={'number'}
                                    disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                    apiError={error}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    (formik.values.is_dtaa_taxable === 'Y') && (formik.values.is_system_calculation_applicable === 'Y') && (
                                        <TextField
                                            name={'dtaa_rate'}
                                            label={'DTAA Rate'}
                                            type={'number'}
                                            fullWidth
                                            required={(formik.values.is_dtaa_taxable === 'Y') && (formik.values.is_system_calculation_applicable === 'Y')}
                                            disabled={isReading || !(formik.values.is_system_calculation_applicable === 'Y')}
                                            apiError={error}
                                        />
                                    )
                                }
                            </Grid>
                            <Collapse
                                component={Grid} item xs={12}
                                in={(formik.values.is_dtaa_taxable === 'Y')}>
                                <RadioField
                                    required={!isReading}
                                    label={'DTAA Section'}
                                    name={'dtaa_section'}
                                    options={[[1, dtaaSections[0], {disabled: isReading}], [2, dtaaSections[1], {disabled: isReading}], [3, dtaaSections[2], {disabled: isReading || (formik.values.is_system_calculation_applicable === 'Y')}], [4, dtaaSections[3], {disabled: isReading}],]}
                                    apiError={error}
                                />
                            </Collapse>
                            {
                                formik.values.is_dtaa_taxable === 'Y' && (
                                    <DTAAFields formik={formik}/>
                                )
                            }
                        </> : <>
                            <Grid item xs={4}>
                                <AutoCompleteField
                                    name={'it_not_taxable_reason'}
                                    label={'IT ACT not applicable reason'}
                                    fillWidth
                                    required={!(formik.values.is_it_taxable === 'Y')}
                                    isReading={isReading}
                                    options={['AMOUNT NOT TAXABLE UNDER THE ACT', 'PAYMENT OF INTEREST FALLS WITHIN THE DEFINITION OF INTEREST U/ S 2(28A) AND HENCE EXEMPT UNDER SECTION 194A']}
                                    apiError={error}
                                />
                            </Grid>
                        </>}
                        <Grid item xs={12}>
                            <Typography variant={'h6'}>TDS Details</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name={'tds_amt_frgn'}
                                label={'TDS Amount (Foreign Currency)'}
                                type={'number'}
                                fullWidth
                                required={!(formik.values.is_system_calculation_applicable === 'Y')}
                                disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name={'tds_amt_inr'}
                                label={'TDS Amount (Indian Currency)'}
                                type={'number'}
                                fullWidth
                                required={!(formik.values.is_system_calculation_applicable === 'Y')}
                                disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name={'tds_rate'}
                                label={'TDS Rate'}
                                type={'number'}
                                fullWidth
                                required={!(formik.values.is_system_calculation_applicable === 'Y')}
                                disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <RadioField
                                row
                                required={!(formik.values.is_system_calculation_applicable === 'Y')}
                                isReading={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                fullWidth
                                label={'TDS Rate as Per'}
                                name={'tds_rate_as_per'}
                                options={Object.entries(Mapping.transactionCB.rateTdsSecbFlg).filter(([key, value]) => key !== '-1').map((entry) => [...entry, {disabled: (formik.values.is_system_calculation_applicable === 'Y')}])}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name={'actual_amt_after_tds_frgn'}
                                label={'Actual Amount after TDS (Foreign Currency)'}
                                type={'number'}
                                fullWidth
                                required={!(formik.values.is_system_calculation_applicable === 'Y')}
                                disabled={isReading || (formik.values.is_system_calculation_applicable === 'Y')}
                                apiError={error}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required={!(formik.values.tds_rate === 0)}
                                type={'date'}
                                disabled={isReading}
                                label={'Date of Deduction of tax at source (if any)'}
                                name={'date_of_deduction'}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                apiError={error}
                            />
                        </Grid>
                    </Grid>
                </>)}
            </Formik>
        </Paper>

        <ComputationModal open={computationModal} setOpen={setComputationModal} computation={computation}/>

        <Box sx={{textAlign: 'center', mt: 3}}>
            <Button
                startIcon={<NavigateBeforeIcon/>}
                variant={'outlined'}
                onClick={() => handleSaveRemittance(() => cbState.baseData.setActiveStep(1, cbState.baseData.activeStep))}
            >
                Previous
            </Button>
            <Button
                startIcon={<NavigateNextIcon/>}
                variant={'contained'}
                sx={{ml: 3}}
                disabled={cbState.baseData.status <= 1}
                onClick={() => cbState.baseData.setActiveStep(3, cbState.baseData.activeStep)}
            >
                Next
            </Button>
        </Box>
    </>)
}