import Link from "../utils/components/Link";
import {Box, Button, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import API from "../utils/API";
import {useLocation, useNavigate} from "react-router-dom";
import {ProgressContext} from "../utils/ProgressContext";
import {useContext} from "react";
import {UserActions} from "../utils/UserContext";
import {useMutation} from "@tanstack/react-query";
import APIError from "../utils/components/APIError";
import TextField from "../utils/components/TextField";
import PasswordField from "../utils/components/PasswordField";
import BackButton from "../utils/components/BackButton";
import {nonOrganizationUser} from "../utils/constants/UserTypes";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid Email ID").required("Email ID is required"),
    password: Yup.string().required("Password is required")
});


async function loginAPI(data) {
    const response = await API.post('accounts/users/login/', data);
    return response.data;
}

function LoginForm() {
    const navigate = useNavigate();
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const dispatch = useContext(UserActions);
    const location = useLocation();
    let email = location.state?.email;
    email = email ? email : "";

    const initialValues = {email: email, password: ""};

    const mutation = useMutation(
        ['accounts', 'users', 'login'],
        loginAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: data => {
                if (data['user_type'] !== nonOrganizationUser) {
                    dispatch({type: 'login', user: data});
                    navigate('/')
                } else {
                    navigate('../login-otp', {state: {email: data['email']}})
                }
            }
        }
    )

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={mutation.mutate}
        >
            <Form>
                {mutation.isError && <Box sx={{mt: 5}}>
                    <APIError error={mutation.error}/>
                </Box>}
                <TextField
                    required
                    fullWidth
                    lowerCase
                    type={'email'}
                    name={"email"}
                    label={"Email ID"}
                    sx={{
                        mt: 5
                    }}
                    apiError={mutation.error}
                    autoFocus
                />
                <PasswordField
                    required
                    fullWidth
                    name={"password"}
                    label={"Password"}
                    formControlSxProps={{mt: 5}}
                />
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{mt: 5}}>
                    <FormControlLabel control={<Checkbox/>} label={"Remember me"}/>
                    <Link underline={"none"} to={"../forgot-password"}>Forgot Password?</Link>
                </Stack>
                <Button sx={{mt: "32px"}} type={"submit"} variant={"contained"}>Login</Button>
            </Form>
        </Formik>
    )
}

export default function Login() {
    return (
        <Box sx={{textAlign: "center"}}>
            <BackButton to={-1} sx={{top: "36px", left: "36px"}}/>
            <Typography variant={"h3"}>Log In</Typography>
            <Typography mt={3} variant={"helpText"}>
                Welcome back! Please enter your email id and password to login.
            </Typography>
            <LoginForm/>
        </Box>
    )
}