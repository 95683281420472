import {useContext} from "react";
import {CBContext} from "./CBContext";
import Entity from "./Entity";
import InvoiceForm from "./InvoiceForm";
import RemittanceForm from "./RemittanceForm";
import TokenNumberForm from "./TokenNumberForm";
import AcknowledgementForm from "./AcknowledgementForm";
import UdinForm from "./UdinForm";

export default function CBDetailForm(){
    const {cbState} = useContext(CBContext);

    switch (cbState.baseData.activeStep){
        case 0:
            return <Entity/>
        case 1:
            return <InvoiceForm/>
        case 2:
            return <RemittanceForm/>
        case 3:
            return <TokenNumberForm/>
        case 4:
            return <AcknowledgementForm/>
        case 5:
            return <UdinForm/>
        default:
            return <></>
    }
}