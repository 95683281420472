import AutorenewIcon from "@mui/icons-material/Autorenew";
import {parseTime} from "../utils";

export default function fetchingStatus(query) {
    if (query.isFetching) {
        return <><AutorenewIcon fontSize={'0.8rem'}/> Updating in background.</>
    }
    return (
        <>Data last updated at <b>{parseTime(query.dataUpdatedAt)}</b></>
    )
}