import {defaultAddressFields} from "../utils/constants/AddressFields";
import API from "../utils/API";
import {forwardRef, useContext, useImperativeHandle, useRef} from "react";
import {ProgressContext} from "../utils/ProgressContext";
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getPatchValues, Modal, useConfirmation} from "../utils/utils";
import {Box, Button, Divider, Grid, Paper, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import validationSchema from "./validationSchema";
import {Formik} from "formik";
import APIError from "../utils/components/APIError";
import TextField from "../utils/components/TextField";
import RadioField from "../utils/components/RadioField";
import Mapping from "../utils/UtilityMapping.json";
import {adminUser} from "../utils/constants/UserTypes";
import {UserDetails} from "../utils/UserContext";

const initialValues = {
    i_or_we: '02',
    honorific: '03',
    name: '',
    pan_tan: '',
    bank_name: '',
    bank_name_other: '',
    branch_name: '',
    bsr_code: '',
    is_ca_applicable: 'N',
    tan: '',
    area_code: '',
    range_code: '',
    ao_number: '',
    ...defaultAddressFields,
    state: '-1',
    pincode: '',
    place_of_business: '',
    email: '',
    phone: '',
    status: '1',
    residential_status: '1',
    landlord_name: '',
    verification_person_name: '',
    verification_i_we: '2',
    verification_designation: '',
    verification_place: '',
    accountant: '-1',
    usage_status: '1'
}

async function remitterCreateAPI(data) {
    const response = await API.post('remitters/', data);
    return response.data;
}


async function remitterUpdateAPI(id, data) {
    const response = await API.patch(`remitters/${id}/`, data);
    return response.data;
}

async function remitterDeleteAPI(id) {
    const response = await API.delete(`remitters/${id}/`);
    return response.data;
}

export async function accountantListAPI() {
    const response = await API.get('accountants/list/');
    return response.data;
}


const RemitterForm = forwardRef((props, ref) => {

    const user = useContext(UserDetails);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const navigate = useNavigate();
    const {data} = props;
    const formikRef = useRef();
    const queryClient = useQueryClient();
    let id = data ? data.id : null;
    const isCreating = !Boolean(id);

    const createRemitter = useMutation(
        ['remitters'],
        remitterCreateAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate('/remitters')
            }
        }
    )

    const updateRemitter = useMutation(
        ['remitters', id],
        (data) => remitterUpdateAPI(id, data),
        {
            onMutate: () => {
                handleProgressOpen()
            },
            onSettled: () => handleProgressClose(),
            onSuccess: (data) => {
                queryClient.invalidateQueries({queryKey: ['remitters', data.id]});
                handleEditClose(true);
            }
        }
    )

    const deleteRemitter = useMutation(
        ['remitters', id],
        () => remitterDeleteAPI(id),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate('/remitters')
            }
        }
    )

    const {open: edit, handleOpen: handleEditOpen, handleClose: handleEditClose} = useConfirmation();
    const {open: deleteOpen, handleOpen: handleDeleteOpen, handleClose: handleDeleteClose} = useConfirmation(
        deleteRemitter.mutate,
    );
    const {open: discardOpen, handleOpen: handleDiscardOpen, handleClose: handleDiscardClose} = useConfirmation(
        () => {
            formikRef.current.resetForm();
            if (isCreating) {
                navigate(-1);
            } else {
                handleEditClose(true);
            }
        },
    );
    const {open: saveOpen, handleOpen: handleSaveOpen, handleClose: handleSaveClose} = useConfirmation(
        () => formikRef.current.submitForm()
    );
    const {
        open: cancelDelete,
        handleOpen: handleCancelDeleteOpen,
        handleClose: handleCancelDeleteClose
    } = useConfirmation(
        () => updateRemitter.mutate({'usage_status': '1'})
    );

    useImperativeHandle(ref, () => {
        return {
            handleDiscardOpen,
            handleSaveOpen
        }
    })

    let header;
    if (isCreating) {
        header = null;
    } else if (!edit) {
        if (data.usage_status === "1") {
            header = (
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <Button
                            startIcon={<DeleteIcon/>}
                            onClick={handleDeleteOpen}
                            color={'error'}
                        >
                            Delete
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            startIcon={<EditIcon/>}
                            onClick={handleEditOpen}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
            )
        } else if (['2', '3'].includes(data.usage_status) && user.user_type >= adminUser) {
            header = (
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <Button
                            startIcon={<DeleteIcon/>}
                            onClick={handleDeleteOpen}
                            color={'error'}
                        >
                            Delete
                        </Button>
                        <Button
                            startIcon={<CancelIcon/>}
                            onClick={handleCancelDeleteOpen}
                        >
                            Cancel Delete
                        </Button>
                    </Box>
                </Box>
            )
        }
    } else if (edit) {
        header = (
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Button
                        startIcon={<DeleteIcon/>}
                        onClick={handleDeleteOpen}
                        color={'error'}
                        disabled
                    >
                        Delete
                    </Button>
                </Box>
                <Box>
                    <Button startIcon={<CancelIcon/>}
                            onClick={handleDiscardOpen}>Discard</Button>
                    <Button startIcon={<SaveIcon/>} onClick={handleSaveOpen}>Save</Button>
                </Box>
            </Box>
        )
    }

    function handleSubmit(values) {
        if (isCreating) {
            createRemitter.mutate(values);
        } else {
            updateRemitter.mutate(getPatchValues(data, values));
        }
    }

    const isReading = !isCreating && !edit;
    const api = isCreating ? createRemitter : updateRemitter


    const accountantList = useQuery(
        ['accountants', 'list'],
        accountantListAPI,
        {
            staleTime: 3600000,
        }
    )

    return (
        <>
            <Modal
                open={deleteOpen}
                handleClose={handleDeleteClose}
                title={'Delete Remitter'}
                message={'Are you sure you want to delete the remitter?\nThis action can not be un-done'}
            />
            <Modal
                open={discardOpen}
                handleClose={handleDiscardClose}
                title={'Discard Edits'}
                message={'All the changes made will be discarded.\nAre you sure you want to proceed? '}
            />
            <Modal
                open={saveOpen}
                handleClose={handleSaveClose}
                title={isCreating ? 'Create Remitter' : 'Save Remitter'}
                message={isCreating ? 'New Remitter will added' : 'All the changes made will be permanently saved.\nAre you sure you want to proceed? '}
            />
            <Modal
                open={cancelDelete}
                handleClose={handleCancelDeleteClose}
                title={'Cancel Delete'}
                message={'Are you sure you want to cancel the delete operation?'}
            />
            <Formik
                innerRef={formikRef}
                validationSchema={validationSchema}
                initialValues={isCreating ? initialValues : data}
                onSubmit={handleSubmit}
                validateOnChange={false}
            >
                {
                    formik => (
                        <Box>
                            <Paper sx={{px: 3, pt: 1, pb: 3, mt: 1}}>
                                {header && (
                                    <>
                                        {header}
                                        <Divider sx={{my: 1}}/>
                                    </>
                                )}
                                {api.isError && <APIError error={api.error}/>}
                                <Grid container mt={2} sx={{alignItems: 'stretch'}}>
                                    <Grid item xs={6} sx={{pr: 2}}>
                                        <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                            Primary Details
                                        </Typography>
                                        <Grid container columnSpacing={2} rowSpacing={4}>
                                            <Grid item xs={6}>
                                                <RadioField
                                                    row
                                                    required={!isReading}
                                                    isReading={isReading}
                                                    fullWidth
                                                    label={'I/We'}
                                                    name={'i_or_we'}
                                                    options={Object.entries(Mapping.remitter.iorWe).filter(([key, value]) => key !== '-1')}
                                                    apiError={api.error}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <RadioField
                                                    row
                                                    required={!isReading}
                                                    isReading={isReading}
                                                    fullWidth
                                                    label={'Honorific'}
                                                    name={'honorific'}
                                                    options={Object.entries(Mapping.remitter.remitterHonorific).filter(([key, value]) => key !== '-1')}
                                                    apiError={api.error}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    upperCase
                                                    required={!isReading}
                                                    isReading={isReading || (edit && user.user_type < adminUser)}
                                                    name={'name'}
                                                    label={'Name'}
                                                    apiError={api.error}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    upperCase
                                                    required={!isReading}
                                                    isReading={isReading}
                                                    name={'pan_tan'}
                                                    label={'PAN/TAN Number'}
                                                    apiError={api.error}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                {
                                                    accountantList.isSuccess ? <TextField
                                                        fullWidth
                                                        select
                                                        disabled={accountantList.isLoading || accountantList.isFetching}
                                                        required={!isReading}
                                                        isReading={isReading}
                                                        name={'accountant'}
                                                        label={'Accountant'}
                                                        options={accountantList.data.reduce((acc, curval) => {
                                                            acc[curval.id] = curval.name;
                                                            return acc;
                                                        }, {'-1': 'Select'})}
                                                        apiError={api.error}
                                                        helperText={accountantList.isFetching ? 'Fetching Accountants' : ' '}
                                                    /> : <Typography variant={'helpText'}>Fetching Accountant List...</Typography>
                                                }
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}
                                          sx={{
                                              borderLeft: "1px solid",
                                              borderColor: "rgba(0, 0, 0, 0.10)",
                                              pl: 2
                                          }}
                                    >
                                        <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                            Bank Details
                                        </Typography>
                                        <Grid container columnSpacing={2} rowSpacing={4}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required={!isReading}
                                                    isReading={isReading}
                                                    name={'bank_name'}
                                                    label={'Bank Name'}
                                                    apiError={api.error}
                                                    options={Mapping.remitter.nameBankCode}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    upperCase
                                                    required={!isReading}
                                                    isReading={isReading}
                                                    disabled={formik.values.bank_name !== '999'}
                                                    name={'bank_name_other'}
                                                    label={'Bank Name Other'}
                                                    apiError={api.error}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    upperCase
                                                    required={!isReading}
                                                    isReading={isReading}
                                                    name={'branch_name'}
                                                    label={'Branch Name'}
                                                    apiError={api.error}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    required={!isReading}
                                                    isReading={isReading}
                                                    name={'bsr_code'}
                                                    label={'BSR Code'}
                                                    apiError={api.error}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioField
                                            fullWidth
                                            row
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'is_ca_applicable'}
                                            label={'Is 15CA Applicable?'}
                                            helperText={''}
                                        />
                                    </Grid>
                                    {
                                        formik.values.is_ca_applicable === 'Y' && (
                                            <Grid container item xs={12} mt={2}>
                                                <Grid item xs={6} sx={{pr: 2}}>
                                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                        Address Details
                                                    </Typography>
                                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'flat_door_building'}
                                                                label={'Flat/Door/Building'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                isReading={isReading}
                                                                name={'premise_building_village'}
                                                                label={'Premise/Building/Village'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                isReading={isReading}
                                                                name={'road_street'}
                                                                label={'Road/Street'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'area_locality'}
                                                                label={'Area/Locality'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'town_city_district'}
                                                                label={'Town/City/District'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                select
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'state'}
                                                                label={'State'}
                                                                apiError={api.error}
                                                                options={Mapping.remitter.state}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                select
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'country'}
                                                                label={'Country'}
                                                                apiError={api.error}
                                                                options={Mapping.remitter.country}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'pincode'}
                                                                label={'Pincode'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Typography mt={1.5} variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                        Certification Details
                                                    </Typography>
                                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                                        <Grid item xs={6}>
                                                            <RadioField
                                                                row
                                                                required={!isReading}
                                                                fullWidth
                                                                label={'I/We'}
                                                                name={'verification_i_we'}
                                                                options={Object.entries(Mapping.remitter.verificationIorWe).filter(([key, value]) => key !== '-1')}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'verification_person_name'}
                                                                label={'Person Name'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'landlord_name'}
                                                                label={'Parent/Guardian Name'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'verification_designation'}
                                                                label={'Designation'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'verification_place'}
                                                                label={'Place'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}
                                                      sx={{
                                                          borderLeft: "1px solid",
                                                          borderColor: "rgba(0, 0, 0, 0.10)",
                                                          pl: 2
                                                      }}>
                                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                        Business Details
                                                    </Typography>
                                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                upperCase
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'place_of_business'}
                                                                label={'Place of Business'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'email'}
                                                                label={'Email'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                required={!isReading}
                                                                isReading={isReading}
                                                                name={'phone'}
                                                                label={'Phone'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}/>
                                                        <Grid item xs={6}>
                                                            <RadioField
                                                                required={!isReading}
                                                                fullWidth
                                                                label={'Status'}
                                                                name={'status'}
                                                                options={Object.entries(Mapping.remitter.status).filter(([key, value]) => key !== '-1')}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioField
                                                                required={!isReading}
                                                                fullWidth
                                                                label={'Residential Status'}
                                                                name={'residential_status'}
                                                                options={Object.entries(Mapping.remitter.residentialStatus).filter(([key, value]) => key !== '-1')}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                        Optional Details
                                                    </Typography>
                                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                upperCase
                                                                isReading={isReading}
                                                                name={'area_code'}
                                                                label={'Area Code'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                upperCase
                                                                isReading={isReading}
                                                                name={'ao_type'}
                                                                label={'AO Type'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                isReading={isReading}
                                                                name={'range_code'}
                                                                label={'Range Code'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>

                                                            <TextField
                                                                fullWidth
                                                                isReading={isReading}
                                                                name={'ao_number'}
                                                                label={'AO Number'}
                                                                apiError={api.error}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Paper>
                        </Box>
                    )
                }
            </Formik>
        </>
    )
})

export default RemitterForm;