import {Box, Button, Container, Toolbar, Typography} from "@mui/material";
import {adminUser} from "../utils/constants/UserTypes";
import withPermission from "../utils/components/PermissionRequired";
import BackButton from "../utils/components/BackButton";
import RemitterForm from "./RemitterForm";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import {useRef} from "react";

function RemitterCreate() {
    const ref = useRef();
    return (
        <>
            <Toolbar>
                <BackButton to={-1}/>
                <Typography variant={"h5"} sx={{color: "primary.main"}}>Create Remitter</Typography>
                <Box
                    sx={{
                        position: 'absolute',
                        right: '40px'
                    }}
                >
                    <Button startIcon={<CancelIcon/>} variant={'contained'}
                            onClick={() => ref.current.handleDiscardOpen()}>Discard</Button>
                    <Button startIcon={<AddIcon/>} sx={{ml: 3}} onClick={() => ref.current.handleSaveOpen()}
                            variant={'contained'}>Create</Button>
                </Box>
            </Toolbar>
            <Container
            sx={{
                position: "relative",
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 128px)'
            }}>
                <RemitterForm ref={ref}/>
            </Container>
        </>
    )
}

export default withPermission(RemitterCreate, adminUser);
