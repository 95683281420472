import {
    alpha,
    AppBar,
    Avatar,
    Box,
    Button,
    IconButton,
    ListItemIcon, ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
import Link from "./Link";
import API from "../API";
import {useContext, useState} from "react";
import {UserActions, UserDetails} from "../UserContext";
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import {useMutation} from "@tanstack/react-query";
import {adminUser, organizationalUser, superAdminUser} from "../constants/UserTypes";

async function logoutAPI() {
    const response = await API.post('accounts/users/logout/');
    return response.data;
}

function CustomNavLink(props) {
    return (
        <Link {...props}
              underline={"none"}
              sx={{
                  display: "block",
                  textAlign: "center",
                  py: "20px",
                  px: "20px",
                  "&.active": {
                      borderBottom: "4px solid",
                      fontWeight: "bold",
                  }
              }}/>
    )
}

export default function TopBar() {
    const user = useContext(UserDetails);
    const userAction = useContext(UserActions);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState();

    const mutation = useMutation(
        ['accounts', 'users', 'logout'],
        logoutAPI,
        {
            onSettled: () => userAction({type: 'logout'})
        }
    )

    function openMenu(e) {
        setAnchorEl(e.currentTarget);
    }

    function closeMenu() {
        setAnchorEl(null);
    }

    let component = (
        <Box sx={{px: "20px", display: "flex", alignItems: "center"}}>
            <Button component={NavLink} to={"accounts/login"} variant={"contained"}>Login</Button>
        </Box>
    )

    if (location.pathname === '/accounts/login') {
        component = null;
    } else if (user !== null && user.user_type >= organizationalUser) {
        const initials = user.name.split(" ").map((n) => n[0]).join("").slice(0, 2)
        component = (
            <>
                <CustomNavLink to={'cb'}>CB</CustomNavLink>
                <CustomNavLink to={'remitters'}>Remitter</CustomNavLink>
                <CustomNavLink to={'accountants'}>Accountants</CustomNavLink>
                {user.user_type >= adminUser && <CustomNavLink to={'users'}>User</CustomNavLink>}
                {user.user_type >= superAdminUser && <CustomNavLink to={'ipaddress'}>IP Address</CustomNavLink>}
                <CustomNavLink to={'rate-cards'}>SBI Rate Cards</CustomNavLink>
                <IconButton
                    onClick={openMenu}
                >
                    <Avatar
                        sx={{
                            bgcolor: '#fff',
                            border: "1px solid",
                            borderColor: "primary.main",
                            color: "primary.main"
                        }}
                    >
                        {initials}
                    </Avatar>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={closeMenu}
                    onClick={closeMenu}
                    MenuListProps={{
                        sx: {
                            width: "224px",
                            '.MuiMenuItem-root:hover': {
                                backgroundColor: (theme) => `${alpha(theme.palette.primary.main, 0.2)}`,
                            },
                            color: 'primary.main',
                            '.MuiListItemIcon-root': {
                                color: 'primary.main'
                            }
                        },
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <PersonIcon/>
                        </ListItemIcon>
                        <ListItemText>Profile</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={mutation.mutate}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                </Menu>
            </>
        )
    }

    return (
        <AppBar position={'static'}>
            <Toolbar sx={{bgcolor: "#fff"}}>
                <Typography
                    variant={"h5"}
                    flexGrow={1}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#c53143",
                        letterSpacing: "0.025em",
                        textDecoration: "none"
                    }}
                    component={Link}
                    to={'/'}
                >
                    Console
                </Typography>
                {component}
            </Toolbar>

        </AppBar>
    )
}
