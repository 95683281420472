import {Box, Button, Container, Grid, IconButton, TableCell, Toolbar, Typography} from "@mui/material";
import DataTable from "../utils/components/DataTable";
import withPermission from "../utils/components/PermissionRequired";
import {superAdminUser} from "../utils/constants/UserTypes";
import {FormModal, Modal, useConfirmation} from "../utils/utils";
import {useMutation} from "@tanstack/react-query";
import API from "../utils/API";
import {useContext, useRef, useState} from "react";
import {ProgressContext} from "../utils/ProgressContext";
import DeleteIcon from "@mui/icons-material/Delete";
import {Form, Formik} from "formik";
import TextField from "../utils/components/TextField";
import APIError from "../utils/components/APIError";
import * as Yup from "yup";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

function Header() {
    return (
        <>
            <TableCell>IPAddress</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Actions</TableCell>
        </>
    )
}


function Row({element, onDelete}) {
    return (
        <>
            <TableCell>{element.ip_address}</TableCell>
            <TableCell>{element.location}</TableCell>
            <TableCell><IconButton onClick={onDelete}><DeleteIcon sx={{color: 'error.main'}}/></IconButton></TableCell>
        </>
    )
}

async function ipAddressDeleteAPI(id) {
    const response = await API.delete(`accounts/ipaddress/${id}/`);
    return response.data;
}

async function ipAddressCreateAPI(values) {
    const response = await API.post('accounts/ipaddress/', values);
    return response.data;
}

function IPAddress() {
    const [deleteIP, setDeleteIP] = useState(null);
    const [open, setOpen] = useState(false);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const dataRef = useRef();

    const deleteIPAddress = useMutation(
        ['accounts', 'ipaddress'],
        (id) => ipAddressDeleteAPI(id),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                dataRef.current.refetch();
            }
        }
    );

    const createIPAddress = useMutation(
        ['accounts', 'ipaddress'],
        ipAddressCreateAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                dataRef.current.refetch();
                setOpen(false);
            }
        }
    )

    const {open: deleteOpen, handleOpen: handleDeleteOpen, handleClose: handleDeleteClose} = useConfirmation(
        () => {
            deleteIPAddress.mutate(deleteIP);
            setDeleteIP(null);
        },
        () => setDeleteIP(null)
    );

    return <>
        <Toolbar>
            <Typography variant={"navbarTitle"}>IPAddresses</Typography>
            <Box
                sx={{
                    position: "absolute",
                    top: "12px",
                    right: "40px"
                }}
            >
                <Button variant={'contained'} onClick={() => setOpen(true)}>Add IPAddress</Button>
            </Box>
        </Toolbar>
        <Box
            sx={{
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 128px)'
            }}
        >
            <Container
                sx={{
                    position: "relative",
                }}
            >
                <DataTable
                    ref={dataRef}
                    header={<Header/>}
                    rowParser={(data) => <Row element={data} onDelete={() => {
                        setDeleteIP(data.id);
                        handleDeleteOpen();
                    }}/>}
                    queryUrl={'accounts/ipaddress/'}
                    queryKey={['accounts', 'ipaddress']}
                />
            </Container>
        </Box>
        <Modal
            open={deleteOpen}
            handleClose={handleDeleteClose}
            title={'Delete IP Address'}
            message={'Are you sure you want to delete the IP Address?\nThis action can not be un-done'}
        />
        <FormModal
            open={open}
            title={'Whitelist IP Address'}
            maxWidth={'md'}
            width={'700px'}
        >
            <Formik
                initialValues={{
                    ip_address: '',
                    location: ''
                }}
                validationSchema={Yup.object().shape({
                    ip_address: Yup.string().required().label('IPAddress'),
                    location: Yup.string().required().label('Location'),
                })}
                onSubmit={createIPAddress.mutate}
            >
                {
                    formik => (
                        <Form>
                            <Grid container rowSpacing={3} columnSpacing={5}>
                                <Grid item xs={12}>
                                    {createIPAddress.isError && <APIError error={createIPAddress.error}/>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'ip_address'}
                                        label={'IP Address'}
                                        fullWidth
                                        required
                                        apiError={createIPAddress.error}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'location'}
                                        label={'Location'}
                                        fullWidth
                                        required
                                        apiError={createIPAddress.error}
                                    />
                                </Grid>
                                <Grid sx={{textAlign: 'end'}} item xs={12}>
                                    <Button startIcon={<CancelIcon/>} sx={{mr: 3}} variant={'outlined'} onClick={() => setOpen(false)}>Cancel</Button>
                                    <Button startIcon={<SaveIcon/>} variant={'contained'} disabled={!formik.isValid} type={'submit'}>Save</Button>
                                </Grid>
                            </Grid>

                        </Form>
                    )
                }
            </Formik>
        </FormModal>
    </>
}

export default withPermission(IPAddress, superAdminUser);
