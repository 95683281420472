import Image from "./404.svg"
import {Box, Button} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {NavLink} from "react-router-dom";
export default function Page404(){
    return (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={4} height={'100vh'}>
            <img src={Image} alt={'Page not found'} width={'40%'}/>
            <Box>
                <Button startIcon={<ArrowBackIcon/>} variant={'contained'} component={NavLink} to={'/'}>Back to Home Page</Button>
            </Box>
        </Box>
    )
}