import {Button, Container, Typography} from "@mui/material";
import Image from "./LinkExpired.svg"
import {NavLink} from "react-router-dom";

export default function LinkExpired() {
    return (
        <Container sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant={"h3"} mt={7} sx={{fontWeight: "light"}}>Sorry! Link expired</Typography>
            <img src={Image} alt={"Link Has expired"}/>
            <Button component={NavLink} to={"/"} variant={"contained"} sx={{mt: 5}}>Go Back</Button>
        </Container>
    )
}