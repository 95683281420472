import * as Yup from "yup";
import {defaultAddressValidationSchema} from "../utils/constants/AddressFields";
import {ascii} from "../utils/utils";
import {lengthErrorMsg, maxLengthErrorMsg} from "../utils/constants/YupErrorMessages";

const validationSchema = defaultAddressValidationSchema.concat(
    Yup.object().shape({
        name: ascii.max(125, maxLengthErrorMsg).uppercase('Name should be in uppercase').required('Name is required'),
        firm: ascii.max(125, maxLengthErrorMsg).uppercase('Firm should be in uppercase').required('Firm is required'),
        membership_number: ascii.length(6, lengthErrorMsg).required('Membership Number is required'),
        registration_number: ascii.length(8, lengthErrorMsg).required('Registration Number is required'),
        email: Yup.string().email('Enter a valid Email ID').max(125, maxLengthErrorMsg).required('Email is Required'),
        state: Yup.string().notOneOf(['1'], 'Select a value').required('State is required'),
        pincode: Yup.string().matches(/\d{6}/, 'Pincode should be 6 digits').required('Pincode is required')
    })
);

export default validationSchema;
