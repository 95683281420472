import {MuiFileInput} from "mui-file-input";
import {useField} from "formik";

import {useFormikFieldError} from "../utils";
import {useId} from "react";

export default function FileField(props) {
    const [field, meta, helpers] = useField(props);
    const {label, apiError, helperText = ' ', ...otherProps} = props;

    const {error, textFieldHelperText} = useFormikFieldError(meta, field.name, apiError, helperText)
    const id = useId();

    return (
        <>
            <MuiFileInput
                id={id}
                variant={'standard'}
                size={'small'}
                label={label}
                error={error}
                {...otherProps}
                onChange={(value) => {
                    helpers.setValue(value, true);
                }}
                value={field.value}
                onBlur={field.onBlur}
                helperText={textFieldHelperText}
            />
        </>
    )
}