import {useState} from "react";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem
} from "@mui/material";
import * as Yup from "yup";
import * as React from "react";
import API from "./API";

export function parseDate(date) {
    return new Date(date).toLocaleDateString('en-IN')
}

export function parseTime(date) {
    return new Date(parseInt(date)).toLocaleTimeString('en-IN')
}

export function parseDateTime(date) {
    return new Date(date).toLocaleString('en-in');
}

export function useConfirmation(onConfirm, onReject) {
    const [open, setOpen] = useState(false);

    function handleOpen() {
        setOpen(true);
    }

    function handleClose(type) {
        if (type) {
            if (onConfirm) {
                onConfirm();
            }
        } else {
            if (onReject) {
                onReject();
            }
        }
        setOpen(false);
    }

    return {
        open,
        handleOpen,
        handleClose
    }
}

export function useFormikFieldError(meta, name, apiError, helperText) {
    const apiErrorText = apiError && apiError.response && apiError.response.data[name];
    const isAPIError = Boolean(apiErrorText);
    const isValidationError = !!(meta.touched && meta.error)
    const error = (isValidationError || isAPIError);

    let textFieldHelperText = helperText;
    if (isValidationError) {
        textFieldHelperText = meta.error;
    } else if (isAPIError) {
        textFieldHelperText = (
            <List sx={{p: 0, m: 0}}>
                {apiErrorText.map((err, index) => (
                    <ListItem sx={{p: 0, m: 0}} key={index}>
                        {err}
                    </ListItem>
                ))}
            </List>
        )
    }

    return {error, textFieldHelperText}
}

export function Modal(props) {
    const {open, handleClose, title, message} = props;
    return (
        <Dialog
            open={open}
            aria-labelledby={title}
            aria-describedby={message}
        >
            <DialogTitle sx={{px: 3, pt: 3, pb: 2}}>
                {title}
            </DialogTitle>
            <DialogContent sx={{px: 3, pt: 2, pb: 2, width: "408px"}}>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{px: 3, pb: 3, pt: 2, justifyContent: "center"}}>
                <Button onClick={() => handleClose(false)} variant={'outlined'} sx={{mr: 2.25}}>No</Button>
                <Button onClick={() => handleClose(true)} variant={'contained'} autoFocus>Yes</Button>
            </DialogActions>
        </Dialog>
    )
}


export function FormModal(props) {
    const {open, title, width, children, footer, maxWidth='sm'} = props;
    return (
        <Dialog
            open={open}
            aria-labelledby={title}
            maxWidth={maxWidth}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent sx={{px: 3, pt: 2, pb: 2, width: width}}>
                {children}
            </DialogContent>
            <DialogActions sx={{px: 3, pb: 3, pt: 2, justifyContent: "center"}}>
                {footer}
            </DialogActions>
        </Dialog>
    )
}

export const isOrganizationalEmail = (email) => Boolean(email) && email.endsWith('@bgss.co.in');

export function getPatchValues(initialValues, finalValues) {
    let modifiedValues = {}
    Object.entries(finalValues).forEach(entry => {
        let key = entry[0];
        let value = entry[1];
        if (value !== initialValues[key]) {
            modifiedValues[key] = value
        }
    });
    return modifiedValues;
}

// eslint-disable-next-line
export const ascii = Yup.string().matches(/^[\x00-\x7F]*$/, 'Special characters not allowed')

export function StatusChip({status, sx, ...otherProps}) {
    switch (status) {
        case "1":
            return <Chip {...otherProps} label={'Operative'} variant={'outlined'} color={'success'}
                         sx={{backgroundColor: '#B2FFA8', ...sx}}/>
        case "2":
            return <Chip {...otherProps} label={'Under-Review'} variant={'outlined'} color={'warning'}
                         sx={{backgroundColor: '#FFF3E0', ...sx}}/>
        case "3":
            return <Chip {...otherProps} label={'In-Operative'} variant={'outlined'} color={'error'}
                         sx={{backgroundColor: '#FFDFDF', ...sx}}/>
        default:
            return <></>
    }
}

export function downloadBlobFile(fileName, data) {
    const url = window.URL.createObjectURL(
        new Blob([data]),
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        fileName
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

export function getSize(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export default function useStepper(steps) {
    const [activeStep, setActiveStep] = useState(0);

    function nextStep() {
        if (activeStep !== (steps - 1)) {
            setActiveStep(activeStep + 1);
        }
    }

    function prevStep() {
        if (activeStep !== 0) {
            setActiveStep(activeStep - 1);
        }
    }

    return {activeStep, setActiveStep, nextStep, prevStep};
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export const showDownloadProgress = (progressEvent, setProgress, handleLabeledProgressClose) => {
    const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
    );
    setProgress(percentCompleted);

    if (percentCompleted === 100 || progressEvent.total === undefined){
        handleLabeledProgressClose();
    }
}

export async function downloadHelper(url, data, setProgress, handleLabeledProgressClose){
    const response = await API.post(url, data,
        {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                showDownloadProgress(progressEvent, setProgress, handleLabeledProgressClose)
            }
        });
    return response.data
}

export function ShowChips(props) {
    const {filterChips, formikRef, propsParams} = props;

    let outputChips = []
    for (let chip of filterChips) {
        if ((propsParams?.template_remitter && chip[2] === 'template_remitter') || (propsParams?.template_remittee && chip[2] === 'template_remittee')) {
            outputChips.push(
                <Chip
                    label={<>{chip[0]}: <b>{chip[1]}</b></>}
                    key={chip[2]}
                    onDelete={null}
                />
            )
        } else {
            outputChips.push(
                <Chip
                    label={<>{chip[0]}: <b>{chip[1]}</b></>}
                    key={chip[2]}
                    onDelete={() => {
                        formikRef.current.setFieldValue(chip[2], chip[3]);
                        formikRef.current.submitForm();
                    }}
                />
            )
        }
    }

    return outputChips;

}
