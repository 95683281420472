import {useQuery} from "@tanstack/react-query";
import {useContext, useEffect} from "react";
import {CBContext} from "./CBContext";
import {generalAPI} from "./utils";
import {Formik} from "formik";
import {accountantValidationSchema} from "./validations";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import Mapping from "../utils/UtilityMapping.json";
import TextField from "../utils/components/TextField";
import APIError from "../utils/components/APIError";

export default function AccountantForm() {
    const {cbState, cbDispatch} = useContext(CBContext);

    const accountantRef = cbState.baseData.accountantRef;

    const {data: templateAccountantData, isFetching: templateAccountantIsFetching, isError: templateAccountantIsError, error: templateAccountantError} = useQuery(
        ['accountants', cbState.baseData.accountantId],
        () => generalAPI(`/accountants/${cbState.baseData.accountantId}/`),
        {
            refetchOnWindowFocus: false,
            enabled: cbState.baseData.status === 0 && cbState.baseData.cloning === false && !cbState.baseData.pages[cbState.baseData.activeStep].visited
        }
    );

    const {data: accountantData, isFetching: accountantIsFetching, isError: accountantIsError, error: accountantError} = useQuery(
        ['cbs', 'accountants', cbState.baseData.accountantId],
        () => generalAPI(`cbs/accountants/${cbState.baseData.accountantId}/`),
        {
            refetchOnWindowFocus: false,
            enabled: (cbState.baseData.status !== 0 || cbState.baseData.cloning === true) && !cbState.baseData.pages[cbState.baseData.activeStep].visited
        }
    );

    useEffect(() => {
        function initializeForm(data) {
            cbDispatch({
                type: 'addFormData',
                data: {'accountant': data}
            })
        }
        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited){
            if ((cbState.baseData.cloning || cbState.baseData.status !== 0) && (accountantData)) {
                initializeForm(accountantData)
            } else if (templateAccountantData) {
                initializeForm(templateAccountantData)
            }
        }


    }, [accountantData, templateAccountantData, cbState.baseData.status, cbState.baseData.cloning, cbDispatch, cbState.baseData.pages, cbState.baseData.activeStep]);

    const isError = templateAccountantIsError || accountantIsError
    const error = templateAccountantError || accountantError


    if (isError){
        return <>
            <Box sx={{mx: 25}}>
                <APIError error={error}/>
            </Box>
        </>
    }

    return (
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '-72px',
                    left: '145px'
                }}
            >
                {(templateAccountantIsFetching || accountantIsFetching) && <CircularProgress size={'2rem'}/>}
            </Box>
            <Formik
                innerRef={accountantRef}
                enableReinitialize
                initialValues={cbState.formData.accountant}
                validationSchema={accountantValidationSchema}
                validateOnChange={false}
            >
                {
                    formik => (
                        <Grid container sx={{mt: 2, alignItems: 'start'}}>
                            <Grid item xs={6} sx={{pr: 2}}>
                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                    Primary Details
                                </Typography>
                                <Grid container columnSpacing={2} rowSpacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            upperCase
                                            required
                                            isReading
                                            name={'name'}
                                            label={'Name'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            upperCase
                                            required
                                            isReading
                                            name={'firm'}
                                            label={'Firm Name'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading
                                            name={'membership_number'}
                                            label={'Membership Number'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading
                                            name={'registration_number'}
                                            label={'Registration Number'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            lowerCase
                                            required
                                            isReading
                                            name={'email'}
                                            type={'email'}
                                            label={'Email'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={6}
                                  sx={{
                                      borderLeft: "1px solid",
                                      borderColor: "rgba(0, 0, 0, 0.10)",
                                      pl: 2
                                  }}
                            >
                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                    Address Details
                                </Typography>
                                <Grid container columnSpacing={2} rowSpacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading
                                            name={'flat_door_building'}
                                            label={'Flat/Door/Building'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            isReading
                                            name={'premise_building_village'}
                                            label={'Premise/Building/Village'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            isReading
                                            name={'road_street'}
                                            label={'Road/Street'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading
                                            name={'area_locality'}
                                            label={'Area/Locality'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading
                                            name={'town_city_district'}
                                            label={'Town/City/District'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required
                                            isReading
                                            name={'state'}
                                            label={'State'}
                                            // apiError={api.error}
                                            options={Mapping.accountant.state}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required
                                            isReading
                                            name={'country'}
                                            label={'Country'}
                                            // apiError={api.error}
                                            options={Mapping.accountant.country}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            isReading
                                            name={'pincode'}
                                            label={'Pincode'}
                                            // apiError={api.error}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Formik>
        </Box>
    )

}