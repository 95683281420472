import {NavLink} from "react-router-dom";
import {Link as MuiLink} from "@mui/material"
import {forwardRef} from "react";

export const Link = forwardRef((props, ref) => {
    const {component = NavLink, ...other} = props;
    return (
        <MuiLink component={component} {...other} variant={"body1"} ref={ref}/>
    )
})

export default Link;
