import {
    Box, Button, Divider, Grid, IconButton, Paper, Typography
} from "@mui/material";
import {Formik, FieldArray, Form} from "formik";
import FileField from "../utils/components/FileField";
import AutoCompleteField from "../utils/components/AutoCompleteField";
import * as Yup from "yup"
import TextField from "../utils/components/TextField";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import React, {forwardRef, useContext, useImperativeHandle, useRef} from "react";
import API from "../utils/API";
import {useMutation} from "@tanstack/react-query";
import {ProgressContext} from "../utils/ProgressContext";
import APIError, {AdditionalAPIError} from "../utils/components/APIError";
import {getSize} from "../utils/utils";

let fileFields = {
    file: '', file_type: '', valid_from: '', valid_till: ''
}

let initialValues = {
    files: [fileFields]
}

const validationSchema = Yup.object().shape({
    files: Yup.array().of(Yup.object().shape({
        file: Yup.mixed().required('File is Required'),
        file_type: Yup.string().required('File Type is required'),
        valid_from: Yup.date().required('Valid From'),
        valid_till: Yup.date().required('Valid Till')
    }))
})

async function remitteeFilesAPI(data) {
    const response = await API.post('remittees/files/', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }, formSerializer: {
            dots: true
        }
    });
    return response.data;
}

function FileForm(props) {
    const {index} = props;

    return (<Grid container columnSpacing={5} rowSpacing={4}>
            <Grid item xs={6}>
                <FileField
                    fullWidth
                    required
                    name={`files.${index}.file`}
                    label={'Upload a File'}
                    helperText={'Maximum file size of 10MB'}
                />
            </Grid>
            <Grid item xs={6}>
                <AutoCompleteField
                    required
                    label={'File Type'}
                    name={`files.${index}.file_type`}
                    options={['NO PE', '10F', 'TRC', 'Agreement']}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type={'date'}
                    required
                    fullWidth
                    label={'Valid From'}
                    name={`files.${index}.valid_from`}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type={'date'}
                    required
                    fullWidth
                    label={'Valid Till'}
                    name={`files.${index}.valid_till`}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
        </Grid>)
}

export const RemitteeFileForm = forwardRef((props, ref) => {
    const {data, closeUpload} = props;
    fileFields['remittee'] = data.id;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const formikRef = useRef(null);

    const mutation = useMutation(['remittees', 'files'], remitteeFilesAPI, {
        onMutate: () => handleProgressOpen(), onSettled: () => handleProgressClose(), onSuccess: () => {
            closeUpload();
        }
    });

    function submitForm() {
        formikRef.current.submitForm();
    }

    useImperativeHandle(ref, () => {
        return {
            submitForm
        }
    })

    return (
        <Box>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validateOnChange={false}
                validationSchema={validationSchema}
                onSubmit={values => {
                    mutation.mutate(values);
                }}
            >
                {formik => (<Paper sx={{px: 3, pt: 1, pb: 3, mt: 1, mx: 40}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant={'h6'} sx={{color: 'grey.700'}}>
                                Upload Files
                            </Typography>
                            <Typography variant={'helpText'}>Total size of all files shouldn't be more than
                                50MB</Typography>
                        </Box>
                        <Box sx={{textAlign: 'end', mt: 1}}>
                            {getSize(formik.values.files.reduce((total, file) => total + (file.file?.size ? file.file.size : 0), 0))}
                            <Typography variant={'helpText'}>Total File size:</Typography>
                        </Box>
                    </Box>
                    <Divider sx={{my: 1}}/>
                    <Form>
                        <Box sx={{mt: 2}}>
                            {mutation.isError && <Box my={2}>
                                <APIError error={mutation.error}/>
                                <AdditionalAPIError apiError={mutation.error} field={'File'}/>
                            </Box>}
                            <FieldArray name={'files'}>
                                {arrayHelpers => (<>
                                        {formik.values.files.map((file, index) => (<React.Fragment key={index}>
                                                <Paper
                                                    sx={{
                                                        pt: 2,
                                                        px: 4,
                                                        pb: 4,
                                                        position: 'relative',
                                                        mb: 2,
                                                        boxShadow: '0',
                                                        border: '1px solid',
                                                        borderColor: 'grey.400'
                                                    }}
                                                >
                                                    {index > 0 && <IconButton
                                                        sx={{
                                                            position: 'absolute', right: '16px', top: '16px'
                                                        }}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        <CloseIcon/>
                                                    </IconButton>}
                                                    <Typography sx={{mb: 1, color: 'grey.700'}}
                                                                variant={'h6'}>File {index + 1}</Typography>
                                                    <FileForm
                                                        index={index}
                                                        arrayHelpers={arrayHelpers}
                                                        apiError={mutation.error}
                                                    />
                                                </Paper>
                                            </React.Fragment>))}
                                        <Button startIcon={<AddIcon/>}
                                                onClick={() => arrayHelpers.push(fileFields)}>Add
                                            File</Button>
                                    </>)}
                            </FieldArray>
                        </Box>
                    </Form>
                </Paper>
                )}
            < /Formik>
        </Box>
    )
})

export default RemitteeFileForm;