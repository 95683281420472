import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    List,
    ListItemText,
    Typography
} from "@mui/material";
import React, {useContext, useState} from "react";
import RemitterForm from "./RemitterForm";
import RemitteeForm from "./RemitteeForm";
import AccountantForm from "./AccountantForm";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {CBContext} from "./CBContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Entity() {
    const {cbState, cbDispatch} = useContext(CBContext);

    const remitterRef = cbState.baseData.remitterRef;
    const remitteeRef = cbState.baseData.remitteeRef;
    const accountantRef = cbState.baseData.accountantRef;
    const [errors, setErrors] = useState(null);

    function handleEntitySave() {
        let remitter = remitterRef.current.validateForm();
        let remittee = remitteeRef.current.validateForm();
        let accountant = accountantRef.current.validateForm();

        Promise.all([remitter, remittee, accountant]).then((res) => {
            const individualErrorCounts = res.map(err => Object.keys(err).length);
            const errorCounts = individualErrorCounts.reduce((sum, count) => sum + count, 0);
            if (errorCounts === 0) {
                if (cbState.baseData.status === 0){
                    cbDispatch({
                        type: 'addFormData',
                        data: {
                            'remitter': remitterRef.current.values,
                            'remittee': remitteeRef.current.values,
                            'accountant': accountantRef.current.values,
                        }
                    });
                }
                cbState.baseData.setActiveStep(1, 0);
            } else {
                setErrors(individualErrorCounts)
            }
        })

    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                flexDirection: 'column'
            }}
        >
            {
                errors && <Alert
                    severity={'error'}
                    icon={false}
                    onClose={() => setErrors(null)}
                >
                    <List disablePadding={true}>
                        {errors[0] !== 0 && <ListItemText>Error in <b>Remitter</b><br/></ListItemText>}
                        {errors[1] !== 0 && <ListItemText>Error in <b>Remittee</b><br/></ListItemText>}
                        {errors[2] !== 0 && <ListItemText>Error in <b>Accountant</b></ListItemText>}
                    </List>

                </Alert>
            }
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant={'h5'}>Remitter</Typography>
                </AccordionSummary
                >
                <AccordionDetails>
                    <RemitterForm/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant={'h5'}>Remittee</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RemitteeForm/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant={'h5'}>Accountant</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AccountantForm/>
                </AccordionDetails>
            </Accordion>
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <Button
                    variant={'contained'}
                    endIcon={<NavigateNextIcon/>}
                    onClick={handleEntitySave}
                >
                    Next
                </Button>
            </Box>
        </Box>
    )
}