import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchTextField(props) {
    const {handleChange, handleKeyDown, value, placeholder} = props;
    return (
        <TextField
            placeholder={placeholder}
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end" sx={{
                    px: 2,
                    color: 'primary.main'
                }}><SearchIcon/></InputAdornment>,
                sx: {
                    borderRadius: '30px',
                    px: 2,
                }
            }}
            sx={{
                mb: 3,
                mt: 1
            }}
            type={'search'}
            size={'small'}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
        />
    )
}