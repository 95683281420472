import API from "../utils/API";
import {useLocation} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import APIError from "../utils/components/APIError";
import {Box, Container, Toolbar, Typography} from "@mui/material";
import fetchingStatus from "../utils/components/QueryFetching";
import AccountantForm from "./AccountantForm";
import {organizationalUser} from "../utils/constants/UserTypes";
import withPermission from "../utils/components/PermissionRequired";
import {StatusChip} from "../utils/utils";
import BackButton from "../utils/components/BackButton";
import UserLogging from "../utils/components/UserLogging";

export async function userDetailAPI(id) {
    const response = await API.get(`accountants/${id}/`);
    return response.data;
}

function AccountantDetail() {
    const location = useLocation();
    let data = location.state?.data;


    const query = useQuery(
        ['accountants', data.id],
        () => userDetailAPI(data.id),
        {
            enabled: Boolean(data.id),
            placeholderData: data
        }
    );

    if (query.data) {
        return (
            <>
                <Toolbar>
                    <BackButton to={-1}/>
                    <Typography variant={"navbarTitle"}>{query.data.name}</Typography>
                    <StatusChip sx={{ml: 2}} status={query.data.usage_status}/>
                </Toolbar>
                <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 128px)'
                    }}
                >
                    <Container sx={{position: "relative"}}>
                        <Box
                            sx={{
                                position: 'absolute',
                                right: "44px",
                                top: "8px"
                            }}
                        >
                            <Typography variant={"helpText"}>{fetchingStatus(query)}</Typography>
                        </Box>
                        <AccountantForm data={query.data}/>
                        <UserLogging data={query.data}/>
                    </Container>
                </Box>
            </>
        )
    }

    if (query.error) {
        return <APIError error={query.error}/>
    }

    return <></>
}

export default withPermission(AccountantDetail, organizationalUser);