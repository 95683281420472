import API from "../utils/API";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useContext, useRef} from "react";
import {ProgressContext} from "../utils/ProgressContext";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import {Box, Button, Divider, Grid, Paper} from "@mui/material";
import {getPatchValues, isOrganizationalEmail, Modal, useConfirmation} from "../utils/utils";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import validationSchema from "./validationSchema";
import TextField from "../utils/components/TextField";
import RadioField from "../utils/components/RadioField";
import APIError from "../utils/components/APIError";

const initialValues = {
    'name': '',
    'email': '',
    'user_type': '0',
    'is_active': false
}

async function userCreateAPI(data) {
    const response = await API.post('accounts/users/', data);
    return response.data;
}


async function userUpdateAPI(id, data) {
    const response = await API.patch(`accounts/users/${id}/`, data);
    return response.data;
}

async function userDeleteAPI(id) {
    const response = await API.delete(`accounts/users/${id}/`);
    return response.data;
}

export default function UserForm(props) {
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const navigate = useNavigate();
    const {data} = props;
    const formikRef = useRef();
    const queryClient = useQueryClient();
    let id = data ? data.id : null;
    const isCreating = !Boolean(id);

    const createUser = useMutation(
        ['accounts', 'users'],
        userCreateAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate('/users')
            }
        }
    )

    const updateUser = useMutation(
        ['accounts', 'users', id],
        (data) => userUpdateAPI(id, data),
        {
            onMutate: () => {
                handleProgressOpen()
            },
            onSettled: () => handleProgressClose(),
            onSuccess: (data) => {
                queryClient.invalidateQueries({queryKey: ['accounts', 'users', data.id]});
                handleEditClose(true);
            }
        }
    )

    const deleteUser = useMutation(
        ['accounts', 'users', id],
        () => userDeleteAPI(id),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate('/users')
            }
        }
    )

    const {open: edit, handleOpen: handleEditOpen, handleClose: handleEditClose} = useConfirmation();
    const {open: deleteOpen, handleOpen: handleDeleteOpen, handleClose: handleDeleteClose} = useConfirmation(
        deleteUser.mutate,
    );
    const {open: discardOpen, handleOpen: handleDiscardOpen, handleClose: handleDiscardClose} = useConfirmation(
        () => {
            formikRef.current.resetForm();
            if (isCreating) {
                navigate(-1);
            } else {
                handleEditClose(true);
            }
        },
    );
    const {open: saveOpen, handleOpen: handleSaveOpen, handleClose: handleSaveClose} = useConfirmation(
        () => formikRef.current.submitForm()
    );


    let header;
    if (isCreating) {
        header = (
            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <Button startIcon={<CancelIcon/>}
                        onClick={handleDiscardOpen}>Discard</Button>
                <Button
                    startIcon={<AddBoxIcon/>}
                    onClick={handleSaveOpen}
                >
                    Create
                </Button>
            </Box>
        )
    } else if (!edit) {
        header = (
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Button
                        startIcon={<DeleteIcon/>}
                        onClick={handleDeleteOpen}
                    >
                        Delete
                    </Button>
                </Box>
                <Box>
                    <Button
                        startIcon={<EditIcon/>}
                        onClick={handleEditOpen}
                    >
                        Edit
                    </Button>
                </Box>
            </Box>
        )
    } else if (edit) {
        header = (
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Button
                        startIcon={<DeleteIcon/>}
                        onClick={handleDeleteOpen}
                        disabled
                    >
                        Delete
                    </Button>
                </Box>
                <Box>
                    <Button startIcon={<CancelIcon/>}
                            onClick={handleDiscardOpen}>Discard</Button>
                    <Button startIcon={<SaveIcon/>} onClick={handleSaveOpen}>Save</Button>
                </Box>
            </Box>
        )
    }

    function handleSubmit(values) {
        if (isCreating) {
            createUser.mutate(values);
        } else {
            updateUser.mutate(getPatchValues(data, values));
        }
    }

    const isReading = !isCreating && !edit;
    const api = isCreating ? createUser : updateUser

    return (
        <>
            <Modal
                open={deleteOpen}
                handleClose={handleDeleteClose}
                title={'Delete User'}
                message={'Are you sure you want to delete the user?\nThis action can not be un-done'}
            />
            <Modal
                open={discardOpen}
                handleClose={handleDiscardClose}
                title={'Discard Edits'}
                message={'All the changes made will be discarded.\nAre you sure you want to proceed? '}
            />
            <Modal
                open={saveOpen}
                handleClose={handleSaveClose}
                title={isCreating ? 'Create User' : 'Save User'}
                message={isCreating ? 'New user will added' : 'All the changes made will be permanently saved.\nAre you sure you want to proceed? '}
            />
            <Formik
                innerRef={formikRef}
                validationSchema={validationSchema}
                initialValues={isCreating ? initialValues : data}
                onSubmit={handleSubmit}
                validateOnChange={false}
            >
                {
                    formik => (
                        <Box mx={37} mt={5}>
                            <Paper sx={{px: 3, pt: 1, pb: 3, mt: 1}}>
                                {header && (
                                    <>
                                        {header}
                                        <Divider sx={{my: 1}}/>
                                    </>
                                )}
                                {api.isError && <APIError error={api.error}/>}
                                <Grid container>
                                    <Grid container item xs={6} rowSpacing={4}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'name'}
                                                label={'Name'}
                                                apiError={api.error}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                required={!isReading}
                                                isReading={isReading}
                                                disabled={!isCreating}
                                                name={'email'}
                                                label={'Email'}
                                                apiError={api.error}
                                                helperText={'Email ID once set cannot be updated.'}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <RadioField
                                                row
                                                required={!isReading}
                                                fullWidth
                                                label={'Status'}
                                                name={'is_active'}
                                                options={[
                                                    [true, 'Active', {disabled: isReading || (isCreating && !isOrganizationalEmail(formik.values.email))}],
                                                    [false, 'In Active', {disabled: isReading}],
                                                ]}
                                                helperText={'\'In Active\' can not access the Console'}
                                                apiError={api.error}
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <RadioField
                                                row
                                                required={!isReading}
                                                fullWidth
                                                label={'User Type'}
                                                name={'user_type'}
                                                options={[
                                                    [1, 'Non-Organizational User', {disabled: isReading || isOrganizationalEmail(formik.values.email)}],
                                                    [10, 'Organizational User', {disabled: isReading || !isOrganizationalEmail(formik.values.email)}],
                                                    [100, 'Admin', {disabled: isReading || !isOrganizationalEmail(formik.values.email)}],
                                                    [1000, 'Super Admin', {disabled: isReading || !isOrganizationalEmail(formik.values.email)}],
                                                ]}
                                                apiError={api.error}
                                                helperText={isCreating ? 'Users outside of organization will be getting a link on the Email ID\n' +
                                                    'describing the steps to set the password.' : ' '}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    )
                }

            </Formik>
        </>
    )
}