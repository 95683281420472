import TopBar from "./utils/components/TopBar";
import {Outlet} from "react-router-dom";
import {Box} from "@mui/material";
import {useContext, useEffect} from "react";
import {UserActions} from "./utils/UserContext";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
    const dispatch = useContext(UserActions);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(() => {
        dispatch({type: 'reload'});
    }, [dispatch]);

    return (
        <>
            <TopBar/>
            <Box
                sx={{
                    minHeight: "calc(100vh - 64px)",
                }}
            >
                <Outlet/>
            </Box>
        </>
    );
}

export default App;
