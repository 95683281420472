import {Outlet} from "react-router-dom";
import {adminUser} from "../utils/constants/UserTypes";
import withPermission from "../utils/components/PermissionRequired";

function Users() {
    return (
        <Outlet/>
    )
}

export default withPermission(Users, adminUser)
