import {createContext, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";

export const ProgressContext = createContext(null);

export function ProgressProvider({children}) {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };


    return (
        <ProgressContext.Provider value={{
            "handleProgressOpen": handleOpen,
            "handleProgressClose": handleClose,
        }}>
            {children}
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
                open={open}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </ProgressContext.Provider>
    )
}
