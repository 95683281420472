import {defaultAddressFields} from "../utils/constants/AddressFields";
import {getDateWithDelta} from "../cbs/utils";

function caFunc(){
    return (
        {
            ao_obtained: 'N',
            ao_cert: '',
            ao_officer_name: '',
            ao_officer_designation: '',
            ao_cert_date: '',
            ao_cert_number: '',
            verification_date: getDateWithDelta(0)
        }
    )
}
export const formData = {
    remitter: {
        i_or_we: '02',
        honorific: '03',
        name: '',
        pan_tan: '',
        bank_name: '',
        bank_name_other: '',
        branch_name: '',
        bsr_code: '',
        is_ca_applicable: 'N',
        area_code: '',
        range_code: '',
        ao_number: '',
        ...defaultAddressFields,
        state: '-1',
        pincode: '',
        place_of_business: '',
        email: '',
        phone: '',
        status: '1',
        residential_status: '1',
        landlord_name: '',
        verification_person_name: '',
        verification_i_we: '2',
        verification_designation: '',
        verification_place: '',
        accountant: '-1',
        usage_status: '1'
    },
    remittee: {
        name: '',
        honorific: '03',
        ...defaultAddressFields,
        state: '',
        zipcode: '',
        country_of_remittance: '',
        country_of_remittance_other: '',
        currency_of_remittance: '',
        currency_of_remittance_other: '',
        nature_of_remittance: '',
        nature_of_remittance_other: '',
        relevant_purpose_code: '-1',
        relevant_purpose_code_detail: '',
        is_ca_applicable: 'N',
        pan: '',
        status: '1',
        principal_place_of_business: '',
        email: '',
        phone: '',
        remitter: ''
    },
    accountant: {
        name: '',
        firm: '',
        state: '19',
        pincode: '',
        email: '',
        membership_number: '',
        registration_number: '',
        ...defaultAddressFields,
        country: '91',
        usage_status: '1'
    },
    caFunc: caFunc,
    acknowledgement: {acknowledgement_number: '', acknowledgement_date: ''},
    cancel: {canceled_by: '0'}
}