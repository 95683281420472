import {useContext} from "react";
import {UserDetails} from "../UserContext";
import {Navigate} from "react-router-dom";
import {Alert, Box} from "@mui/material";
import {organizationalUser} from "../constants/UserTypes";


export const withPermission = (OriginalComponent, user_type = organizationalUser) => {

    return (props) => {
        const user = useContext(UserDetails);

        if (user === null) {
            return <></>
        }

        if (Object.keys(user).length === 0) {
            return <Navigate to={'/accounts/login'}/>
        }

        if (user.user_type >= user_type) {
            return <OriginalComponent {...props}/>
        }

        return <Box>
            <Alert sx={{width: '500px', mx: 'auto', mt: 5}} severity={'error'}>You don't have permission to view this
                page</Alert>
        </Box>
    }
}

export default withPermission
