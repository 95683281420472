import {Outlet} from "react-router-dom";
import {Box, Card, CardContent, Paper} from "@mui/material";

export default function Accounts() {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pt: 8
            }}
        >
            <Card component={Paper} sx={{width: "608px"}}>
                <CardContent sx={{"&.MuiCardContent-root:last-child": {pb: 3}, pt: 3, px: 5, position: "relative"}}>
                    <Outlet/>
                </CardContent>
            </Card>
        </Box>
    )
}