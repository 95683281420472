import {Formik} from "formik";
import {Box, Button, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import TextField from "../utils/components/TextField";
import React, {useContext, useEffect} from "react";
import {CBContext} from "./CBContext";
import {ProgressContext} from "../utils/ProgressContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import API from "../utils/API";
import {udinValidationSchema} from "./validations";
import {generalAPI, removeNulls} from "./utils";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import APIError from "../utils/components/APIError";
import SaveIcon from "@mui/icons-material/Save";
import {getPatchValues} from "../utils/utils";


async function addUdinAPI(data, transactionId) {
    const response = await API.post(`cbs/${transactionId}/udin-number/`, data);
    return response.data;
}


export default function UdinForm() {
    const {cbState, cbDispatch} = useContext(CBContext);
    const {status} = cbState.baseData;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const isReading = status !== 4 || status === 7;
    const queryClient = useQueryClient();

    const mutation = useMutation(
        ['cbs', cbState.baseData.transactionId, 'udin-number'],
        (data) => addUdinAPI(data, cbState.baseData.transactionId),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                cbDispatch({
                    type: 'addBaseData',
                    data: {status: 5, navigateOnSuccess: -2}
                });
                queryClient.invalidateQueries(['cbs', cbState.baseData.transactionId])
            }
        }
    );

    const {data: cbData, isFetching: cbIsFetching} = useQuery(
        ['cbs', cbState.baseData.transactionId],
        () => generalAPI(`cbs/${cbState.baseData.transactionId}/`),
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: (cbState.baseData.status >= 4) && !cbState.baseData.pages[cbState.baseData.activeStep].visited,
            select: data => removeNulls(data)
        }
    );

    useEffect(() => {
        if (!cbState.baseData.pages[cbState.baseData.activeStep].visited && cbData && cbState.baseData.status !== 0 && !cbState.baseData.cloning) {
            cbDispatch({
                type: 'addUpdateFormData',
                data: {'udin': cbData}
            })
        }

    }, [cbData, cbState.baseData.status, cbState.baseData.activeStep, cbState.baseData.pages, cbState.baseData.cloning, cbDispatch]);


    return (
        <>
            <Paper sx={{p: 3, mx: 35}}>
                <Box
                    sx={{
                        position: 'relative',
                        my: 1
                    }}
                >
                    <Box>
                        <Typography variant={'h5'}>
                            UDIN Details
                        </Typography>
                    </Box>
                    {mutation.isError && <APIError error={mutation.error}/>}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '0',
                            left: '200px'
                        }}
                    >
                        {cbIsFetching && <CircularProgress size={'2rem'}/>}
                    </Box>
                </Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={cbState.formData.udin}
                    validationSchema={udinValidationSchema}
                    validateOnChange={false}
                    onSubmit={values => mutation.mutate(getPatchValues(cbState.updateData.udin, values))}
                >
                    {
                        formik => (
                            <Grid container columnSpacing={5} rowSpacing={4}>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'udin_number'}
                                        label={'UDIN Number'}
                                        apiError={mutation.error}
                                        required
                                        disabled={isReading}
                                        isReading={isReading}
                                        fullWidth
                                        autoFocus={!isReading}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'udin_date'}
                                        label={'UDIN Date'}
                                        required
                                        type={'date'}
                                        disabled={isReading}
                                        isReading={isReading}
                                        apiError={mutation.error}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{textAlign: 'center'}}>
                                    <Button
                                        startIcon={<SaveIcon/>}
                                        variant={'contained'}
                                        disabled={status !== 4}
                                        onClick={() => formik.submitForm()}
                                    >
                                        Save UDIN
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    }
                </Formik>
            </Paper>

            <Box sx={{textAlign: 'center', mt: 3}}>
                <Button
                    startIcon={<NavigateBeforeIcon/>}
                    variant={'outlined'}
                    onClick={() => cbState.baseData.setActiveStep(4, cbState.baseData.activeStep)}
                >
                    Previous
                </Button>
            </Box>
        </>
    )
}