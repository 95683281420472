import {Outlet} from "react-router-dom";
import withPermission from "../utils/components/PermissionRequired";
import {organizationalUser} from "../utils/constants/UserTypes";

function CB() {
    return (
        <>
            <Outlet/>
        </>
    )
}

export default withPermission(CB, organizationalUser);
