import axios from "axios";

const API = axios.create({
    baseURL: 'https://console-api.bgssassociates.com/'
})

API.interceptors.request.use((config) => {
    const user = JSON.parse(sessionStorage.getItem("user"));

    // Applies Authorization header if token is present in
    // session storage
    if (user !== null) {
        config.headers["Authorization"] = 'Bearer ' + user['token'];
    }

    return config
});

export default API;

