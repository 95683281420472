import {useId, useState} from "react";
import {useField} from "formik";
import {
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useFormikFieldError} from "../utils";

export default function PasswordField(props) {
    const [show, setShow] = useState(false);
    const [field, meta] = useField(props);
    const {label, required, fullWidth = false, helperText = ' ', apiError, name, formControlSxProps, ...other} = props;
    const {error, textFieldHelperText} = useFormikFieldError(meta, name, apiError, helperText)
    const id = useId();

    return (
        <FormControl
            error={error}
            fullWidth={fullWidth}
            required={required}
            variant="standard"
            sx={formControlSxProps}
        >
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Input
                id={id}
                label={label}
                size={"small"}
                type={show ? 'text' : 'password'}
                {...field}
                {...other}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShow(value => !value)}
                            onMouseDown={e => e.preventDefault()}
                        >
                            {show ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText component={"div"}>
                {textFieldHelperText}
            </FormHelperText>
        </FormControl>
    )
}