import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            light: "#5c6bc0",
            main: "#3949ab",
            dark: "#283593"
        },
        success: {
            light: "#4BCC38",
            main: "#286F1E",
            dark: "#12410B"
        }
    },
    typography: {
        fontFamily: "Poppins, sans-serif",
        button: {
            textTransform: "none",
            fontWeight: 700,
            letterSpacing: "0.07em"
        },
        h1: {
            fontWeight: 700
        },
        h2: {
            fontWeight: 700
        },
        h3: {
            fontWeight: 700
        },
        h4: {
            fontWeight: 700
        },
        h5: {
            fontWeight: 700
        },
        h6: {
            fontWeight: 700
        },
        helpText: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "0.75rem",
            fontWeight: "400",
            color: '#616161'
        },
        navbarTitle: {
            color: "#3949ab",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "1.5rem",
            lineHeight: "1.334",
            letterSpacing: "0em",
            maxWidth: "20%",
            whiteSpace: "nowrap"
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-display: swap;
                    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
                    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
                }
            `
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "10px",
                    padding: "8px 16px"
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "#3949ab"
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 600px)': {
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        maxWidth: "1440px"
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    display: "flex",
                    '@media (min-width: 0px)': {
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        height: "64px",
                    },
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(57, 73, 171, 0.08)",
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    padding: "0px",
                    boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.06)",
                    background: "#ffffff"
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.13)",
                    borderRadius: "10px"
                }
            }
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    color: "#3949ab"
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    helpText: "p",
                    navbarTitle: "h5"
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: "#616161",
                    fontWeight: "600",
                    height: "56px"
                },
                root: {
                    fontSize: "1rem",
                    padding: "12px 24px"
                }
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                toolbar: {
                    backgroundColor: "#fff",
                    '@media (min-width: 600px)': {
                        paddingRight: "24px",
                        height: "56px",
                    }
                },
            }
        },
        MuiChip: {
            defaultProps: {
                size: "small"
            }
        },
        MuiGrid: {
            styleOverrides: {
                container: {
                    marginTop: "0px",
                }
            },
        }
    }
});

export default function Theme({children}) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}