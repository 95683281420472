import {defaultAddressFields} from "../utils/constants/AddressFields";
import API from "../utils/API";
import {forwardRef, useContext, useImperativeHandle, useRef} from "react";
import {UserDetails} from "../utils/UserContext";
import {ProgressContext} from "../utils/ProgressContext";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {getPatchValues, Modal, useConfirmation} from "../utils/utils";
import {Box, Button, Divider, Grid, Paper, Typography} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {adminUser} from "../utils/constants/UserTypes";
import SaveIcon from "@mui/icons-material/Save";
import {Formik} from "formik";
import APIError from "../utils/components/APIError";
import Mapping from "../utils/UtilityMapping.json";
import RadioField from "../utils/components/RadioField";
import TextField from "../utils/components/TextField";
import {validationSchema} from "./validationSchema";

let initialValues = {
    name: '',
    honorific: '03',
    ...defaultAddressFields,
    state: '',
    zipcode: '',
    country_of_remittance: '',
    country_of_remittance_other: '',
    currency_of_remittance: '',
    currency_of_remittance_other: '',
    nature_of_remittance: '',
    nature_of_remittance_other: '',
    relevant_purpose_code: '-1',
    relevant_purpose_code_detail: '',
    is_ca_applicable: 'N',
    pan: '',
    status: '1',
    principal_place_of_business: '',
    email: '',
    phone: '',
    remitter: ''
}

async function remitteeCreateAPI(data) {
    const response = await API.post('remittees/', data);
    return response.data;
}


async function remitteeUpdateAPI(id, data) {
    const response = await API.patch(`remittees/${id}/`, data);
    return response.data;
}

async function remitteeDeleteAPI(id) {
    const response = await API.delete(`remittees/${id}/`);
    return response.data;
}

const RemitteeForm = forwardRef((props, ref) => {
    const user = useContext(UserDetails);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const navigate = useNavigate();
    const {data, remitter, onCreateSuccess, onCreateDiscard} = props;
    const formikRef = useRef();
    const queryClient = useQueryClient();
    let id = data ? data.id : null;
    const isCreating = !Boolean(id);

    if (isCreating) {
        initialValues["remitter"] = remitter.id;
        initialValues['is_ca_applicable'] = remitter.is_ca_applicable;
    }

    const createRemittee = useMutation(
        ['remittees'],
        remitteeCreateAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                onCreateSuccess();
            }
        }
    )

    const updateRemittee = useMutation(
        ['remittees', id],
        (data) => remitteeUpdateAPI(id, data),
        {
            onMutate: () => {
                handleProgressOpen()
            },
            onSettled: () => handleProgressClose(),
            onSuccess: (data) => {
                queryClient.invalidateQueries({queryKey: ['remittees', data.id]});
                handleEditClose(true);
            }
        }
    )

    const deleteRemitter = useMutation(
        ['remittees', id],
        () => remitteeDeleteAPI(id),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate('/remitters/remittees')
            }
        }
    )

    const {open: edit, handleOpen: handleEditOpen, handleClose: handleEditClose} = useConfirmation();
    const {open: deleteOpen, handleOpen: handleDeleteOpen, handleClose: handleDeleteClose} = useConfirmation(
        deleteRemitter.mutate,
    );
    const {open: discardOpen, handleOpen: handleDiscardOpen, handleClose: handleDiscardClose} = useConfirmation(
        () => {
            formikRef.current.resetForm();
            if (isCreating) {
                onCreateDiscard();
            } else {
                handleEditClose(true);
            }
        },
    );
    const {open: saveOpen, handleOpen: handleSaveOpen, handleClose: handleSaveClose} = useConfirmation(
        () => formikRef.current.submitForm()
    );
    const {
        open: cancelDelete,
        handleOpen: handleCancelDeleteOpen,
        handleClose: handleCancelDeleteClose
    } = useConfirmation(
        () => updateRemittee.mutate({'usage_status': '1'})
    );

    useImperativeHandle(ref, () => {
        return {
            handleDiscardOpen,
            handleSaveOpen
        }
    })

    let header;
    if (isCreating) {
        header = null;
    } else if (!edit) {
        if (data.usage_status === "1") {
            header = (
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <Button
                            startIcon={<DeleteIcon/>}
                            onClick={handleDeleteOpen}
                            color={'error'}
                        >
                            Delete
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            startIcon={<EditIcon/>}
                            onClick={handleEditOpen}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
            )
        } else if (['2', '3'].includes(data.usage_status) && user.user_type >= adminUser) {
            header = (
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <Button
                            startIcon={<DeleteIcon/>}
                            onClick={handleDeleteOpen}
                            color={'error'}
                        >
                            Delete
                        </Button>
                        <Button
                            startIcon={<CancelIcon/>}
                            onClick={handleCancelDeleteOpen}
                        >
                            Cancel Delete
                        </Button>
                    </Box>
                </Box>
            )
        }
    } else if (edit) {
        header = (
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Button
                        startIcon={<DeleteIcon/>}
                        onClick={handleDeleteOpen}
                        disabled
                    >
                        Delete
                    </Button>
                </Box>
                <Box>
                    <Button startIcon={<CancelIcon/>}
                            onClick={handleDiscardOpen}>Discard</Button>
                    <Button startIcon={<SaveIcon/>} onClick={handleSaveOpen}>Save</Button>
                </Box>
            </Box>
        )
    }

    function handleSubmit(values) {
        if (isCreating) {
            createRemittee.mutate(values);
        } else {
            updateRemittee.mutate(getPatchValues(data, values));
        }
    }

    const isReading = !isCreating && !edit;
    const api = isCreating ? createRemittee : updateRemittee


    return (
        <>
            <Modal
                open={deleteOpen}
                handleClose={handleDeleteClose}
                title={'Delete Remittee'}
                message={'Are you sure you want to delete the remittee?\nThis action can not be un-done'}
            />
            <Modal
                open={discardOpen}
                handleClose={handleDiscardClose}
                title={'Discard Edits'}
                message={'All the changes made will be discarded.\nAre you sure you want to proceed? '}
            />
            <Modal
                open={saveOpen}
                handleClose={handleSaveClose}
                title={isCreating ? 'Create Remittee' : 'Save Remittee'}
                message={isCreating ? 'New Remittee will added' : 'All the changes made will be permanently saved.\nAre you sure you want to proceed? '}
            />
            <Modal
                open={cancelDelete}
                handleClose={handleCancelDeleteClose}
                title={'Cancel Delete'}
                message={'Are you sure you want to cancel the delete operation?'}
            />
            <Formik
                innerRef={formikRef}
                initialValues={isCreating ? initialValues : data}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
            >
                {formik => (
                    <Box>
                        <Paper sx={{px: 3, pt: 1, pb: 3, mt: 1}}>
                            {
                                header && (
                                    <>
                                        {header}
                                        <Divider sx={{my: 1}}/>
                                    </>
                                )
                            }
                            {api.isError && <APIError error={api.error}/>}
                            <Grid container sx={{mt: 2, alignItems: 'stretch'}}>
                                <Grid item xs={6} sx={{pr: 2}}>
                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                        Primary Details
                                    </Typography>
                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                        <Grid item xs={6}>
                                            <RadioField
                                                row
                                                required={!isReading}
                                                isReading={isReading}
                                                fullWidth
                                                label={'Honorific'}
                                                name={'honorific'}
                                                options={Object.entries(Mapping.remittee.beneficiaryHonorific).filter(([key, value]) => key !== '-1')}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                required={!isReading}
                                                isReading={isReading || (edit && user.user_type < adminUser)}
                                                name={'name'}
                                                label={'Name'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'country_of_remittance'}
                                                label={'Country of Remittance'}
                                                options={Mapping.remittee.countryRemMadeSecb}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                disabled={formik.values.country_of_remittance !== '9999'}
                                                required={formik.values.country_of_remittance === '9999'}
                                                isReading={isReading}
                                                name={'country_of_remittance_other'}
                                                label={'Country of Remittance (Other)'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'currency_of_remittance'}
                                                label={'Currency of Remittance'}
                                                options={Mapping.remittee.currencySecbCode}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                disabled={formik.values.currency_of_remittance !== '99'}
                                                required={formik.values.currency_of_remittance === '99'}
                                                isReading={isReading}
                                                name={'currency_of_remittance_other'}
                                                label={'Currency of Remittance (Other)'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'nature_of_remittance'}
                                                label={'Nature of Remittance'}
                                                options={Mapping.remittee.natureRemCategory}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                upperCase
                                                disabled={formik.values.nature_of_remittance !== '16.99'}
                                                required={formik.values.nature_of_remittance === '16.99'}
                                                isReading={isReading}
                                                name={'nature_of_remittance_other'}
                                                label={'Nature of Remittance (Other)'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'relevant_purpose_code'}
                                                label={'Relevant Purpose Code'}
                                                options={Mapping.remittee.revPurCategory}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'relevant_purpose_code_detail'}
                                                label={'Relevant Purpose Category'}
                                                options={Mapping.remittee.revPurCode[formik.values.relevant_purpose_code]}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}
                                      sx={{
                                          borderLeft: "1px solid",
                                          borderColor: "rgba(0, 0, 0, 0.10)",
                                          pl: 2
                                      }}
                                >
                                    <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                        Address Details
                                    </Typography>
                                    <Grid container columnSpacing={2} rowSpacing={4}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'flat_door_building'}
                                                label={'Flat/Door/Building'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                isReading={isReading}
                                                name={'premise_building_village'}
                                                label={'Premise/Building/Village'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                isReading={isReading}
                                                name={'road_street'}
                                                label={'Road/Street'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'area_locality'}
                                                label={'Area/Locality'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'town_city_district'}
                                                label={'Town/City/District'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'state'}
                                                label={'State'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'country'}
                                                label={'Country'}
                                                apiError={api.error}
                                                options={Mapping.remitter.country}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                required={!isReading}
                                                isReading={isReading}
                                                name={'zipcode'}
                                                label={'Zipcode'}
                                                apiError={api.error}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <RadioField
                                        fullWidth
                                        row
                                        required={!isReading}
                                        isReading={isReading}
                                        name={'is_ca_applicable'}
                                        label={'Is 15CA Applicable?'}
                                        helperText={''}
                                    />
                                </Grid>

                                {
                                    formik.values.is_ca_applicable === 'Y' && (
                                        <Grid container item xs={12} mt={2}>
                                            <Grid item xs={6} sx={{pr: 2}}>
                                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                    Preliminary Details
                                                </Typography>
                                                <Grid container columnSpacing={2} rowSpacing={4}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            upperCase
                                                            isReading={isReading}
                                                            name={'pan'}
                                                            label={'PAN'}
                                                            apiError={api.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            isReading={isReading}
                                                            name={'status'}
                                                            label={'Status'}
                                                            options={Mapping.remittee.status}
                                                            apiError={api.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            upperCase
                                                            required={!isReading}
                                                            isReading={isReading}
                                                            name={'principal_place_of_business'}
                                                            label={'Principal Place of Business'}
                                                            apiError={api.error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}
                                                  sx={{
                                                      borderLeft: "1px solid",
                                                      borderColor: "rgba(0, 0, 0, 0.10)",
                                                      pl: 2
                                                  }}>
                                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                                    Contact Details
                                                </Typography>
                                                <Grid container columnSpacing={2} rowSpacing={4}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            isReading={isReading}
                                                            name={'email'}
                                                            label={'Email'}
                                                            apiError={api.error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            isReading={isReading}
                                                            name={'phone'}
                                                            label={'Phone'}
                                                            apiError={api.error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Paper>
                    </Box>
                )}
            </Formik>
        </>
    )
})

export default RemitteeForm;
