import * as Yup from "yup";
import {
    dropDownErrorMsg,
    maxLengthErrorMsg,
} from "../utils/constants/YupErrorMessages";
import {ascii} from "../utils/utils";

export const caValidationSchema = Yup.object().shape({
    ao_obtained: Yup.string().max(1, maxLengthErrorMsg).required().label('AO Obtained'),
    ao_cert: Yup.string().when('ao_obtained', {
        is: val => val === 'Y',
        then: schema => schema.notOneOf(['-1'], dropDownErrorMsg).required(),
        otherwise: schema => schema.nullable()
    }).label('Certificate Type'),
    ao_officer_name: ascii.when('ao_obtained', {
        is: val => val === 'Y',
        then: schema => schema.max(125, maxLengthErrorMsg).required(),
        otherwise: schema => schema.nullable()
    }).label('Officer Name'),
    ao_officer_designation: ascii.when('ao_obtained', {
        is: val => val === 'Y',
        then: schema => schema.max(125, maxLengthErrorMsg).required(),
        otherwise: schema => schema.nullable()
    }).label('Officer Designation'),
    ao_cert_date: Yup.date().when('ao_obtained', {
        is: val => val === 'Y',
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
    }).label('Certificate Date'),
    ao_cert_number: ascii.when('ao_obtained', {
        is: val => val === 'Y',
        then: schema => schema.max(10, maxLengthErrorMsg).required(),
        otherwise: schema => schema.nullable()
    }).label('Certificate Number'),
    verification_date: Yup.date().required().label('Verification Date')
})
