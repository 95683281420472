import {forwardRef, useContext, useImperativeHandle, useRef} from "react";
import {ProgressContext} from "../utils/ProgressContext";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import API from "../utils/API";
import {getPatchValues, Modal, useConfirmation} from "../utils/utils";
import {UserDetails} from "../utils/UserContext";
import {Box, Button, Divider, Grid, Paper, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import {defaultAddressFields} from "../utils/constants/AddressFields";
import validationSchema from "./validationSchema";
import {Formik} from "formik";
import APIError from "../utils/components/APIError";
import TextField from "../utils/components/TextField";
import Mapping from "../utils/UtilityMapping.json";
import {adminUser} from "../utils/constants/UserTypes";


const initialValues = {
    name: '',
    firm: '',
    state: '19',
    pincode: '',
    email: '',
    membership_number: '',
    registration_number: '',
    ...defaultAddressFields,
    country: '91',
    usage_status: '1'
}

async function accountantCreateAPI(data) {
    const response = await API.post('accountants/', data);
    return response.data;
}


async function accountantUpdateAPI(id, data) {
    const response = await API.patch(`accountants/${id}/`, data);
    return response.data;
}

async function accountantDeleteAPI(id) {
    const response = await API.delete(`accountants/${id}/`);
    return response.data;
}

const AccountantForm = forwardRef((props, ref) => {
    const user = useContext(UserDetails);
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const navigate = useNavigate();
    const {data} = props;
    const formikRef = useRef();
    const queryClient = useQueryClient();
    let id = data ? data.id : null;
    const isCreating = !Boolean(id);

    const createAccountant = useMutation(
        ['accountants'],
        accountantCreateAPI,
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate('/accountants')
            }
        }
    )

    const updateAccountant = useMutation(
        ['accountants', id],
        (data) => accountantUpdateAPI(id, data),
        {
            onMutate: () => {
                handleProgressOpen()
            },
            onSettled: () => handleProgressClose(),
            onSuccess: (data) => {
                queryClient.invalidateQueries({queryKey: ['accountants', data.id]});
                handleEditClose(true);
            }
        }
    )

    const deleteAccountant = useMutation(
        ['accountants', id],
        () => accountantDeleteAPI(id),
        {
            onMutate: () => handleProgressOpen(),
            onSettled: () => handleProgressClose(),
            onSuccess: () => {
                navigate('/accountants')
            }
        }
    )

    const {open: edit, handleOpen: handleEditOpen, handleClose: handleEditClose} = useConfirmation();
    const {open: deleteOpen, handleOpen: handleDeleteOpen, handleClose: handleDeleteClose} = useConfirmation(
        deleteAccountant.mutate,
    );
    const {open: discardOpen, handleOpen: handleDiscardOpen, handleClose: handleDiscardClose} = useConfirmation(
        () => {
            formikRef.current.resetForm();
            if (isCreating) {
                navigate(-1);
            } else {
                handleEditClose(true);
            }
        },
    );
    const {open: saveOpen, handleOpen: handleSaveOpen, handleClose: handleSaveClose} = useConfirmation(
        () => formikRef.current.submitForm()
    );
    const {
        open: cancelDelete,
        handleOpen: handleCancelDeleteOpen,
        handleClose: handleCancelDeleteClose
    } = useConfirmation(
        () => updateAccountant.mutate({'usage_status': '1'})
    );

    useImperativeHandle(ref, () => {
        return {
            handleDiscardOpen,
            handleSaveOpen
        }
    })

    let header;

    if (user !== null && user.user_type >= adminUser) {
        if (isCreating) {
            header = null;
        } else if (!edit) {
            if (data.usage_status === "1") {
                header = (
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Button
                                startIcon={<DeleteIcon/>}
                                onClick={handleDeleteOpen}
                                color={'error'}
                            >
                                Delete
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                startIcon={<EditIcon/>}
                                onClick={handleEditOpen}
                            >
                                Edit
                            </Button>
                        </Box>
                    </Box>
                )
            } else if (['2', '3'].includes(data.usage_status) && user.user_type >= adminUser) {
                header = (
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Button
                                startIcon={<DeleteIcon/>}
                                onClick={handleDeleteOpen}
                                color={'error'}
                            >
                                Delete
                            </Button>
                            <Button
                                startIcon={<CancelIcon/>}
                                onClick={handleCancelDeleteOpen}
                            >
                                Cancel Delete
                            </Button>
                        </Box>
                    </Box>
                )
            }
        } else if (edit) {
            header = (
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <Button
                            startIcon={<DeleteIcon/>}
                            onClick={handleDeleteOpen}
                            disabled
                        >
                            Delete
                        </Button>
                    </Box>
                    <Box>
                        <Button startIcon={<CancelIcon/>}
                                onClick={handleDiscardOpen}>Discard</Button>
                        <Button startIcon={<SaveIcon/>} onClick={handleSaveOpen}>Save</Button>
                    </Box>
                </Box>
            )
        }
    }

    function handleSubmit(values) {
        if (isCreating) {
            createAccountant.mutate(values);
        } else {
            updateAccountant.mutate(getPatchValues(data, values));
        }
    }

    const isReading = !isCreating && !edit;
    const api = isCreating ? createAccountant : updateAccountant


    return (
        <>
            <Modal
                open={deleteOpen}
                handleClose={handleDeleteClose}
                title={'Delete Accountant'}
                message={'Are you sure you want to delete the accountants?\nThis action can not be un-done'}
            />
            <Modal
                open={discardOpen}
                handleClose={handleDiscardClose}
                title={'Discard Edits'}
                message={'All the changes made will be discarded.\nAre you sure you want to proceed? '}
            />
            <Modal
                open={saveOpen}
                handleClose={handleSaveClose}
                title={isCreating ? 'Create Accountant' : 'Save Accountant'}
                message={isCreating ? 'New Accountant will added' : 'All the changes made will be permanently saved.\nAre you sure you want to proceed? '}
            />
            <Modal
                open={cancelDelete}
                handleClose={handleCancelDeleteClose}
                title={'Cancel Delete'}
                message={'Are you sure you want to cancel the delete operation?'}
            />
            <Formik
                innerRef={formikRef}
                validationSchema={validationSchema}
                initialValues={isCreating ? initialValues : data}
                onSubmit={handleSubmit}
                validateOnChange={false}
            >
                <Box mt={2}>
                    <Paper sx={{px: 3, pt: 1, pb: 3, mt: 1}}>
                        {header && (
                            <>
                                {header}
                                <Divider sx={{my: 1}}/>
                            </>
                        )}
                        {api.isError && <APIError error={api.error}/>}
                        <Grid container sx={{mt: 2, alignItems: 'start'}}>
                            <Grid item xs={6} sx={{pr: 2}}>
                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                    Primary Details
                                </Typography>
                                <Grid container columnSpacing={2} rowSpacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            upperCase
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'name'}
                                            label={'Name'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            upperCase
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'firm'}
                                            label={'Firm Name'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'membership_number'}
                                            label={'Membership Number'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'registration_number'}
                                            label={'Registration Number'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            lowerCase
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'email'}
                                            type={'email'}
                                            label={'Email'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={6}
                                  sx={{
                                      borderLeft: "1px solid",
                                      borderColor: "rgba(0, 0, 0, 0.10)",
                                      pl: 2
                                  }}
                            >
                                <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                    Address Details
                                </Typography>
                                <Grid container columnSpacing={2} rowSpacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'flat_door_building'}
                                            label={'Flat/Door/Building'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            isReading={isReading}
                                            name={'premise_building_village'}
                                            label={'Premise/Building/Village'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            isReading={isReading}
                                            name={'road_street'}
                                            label={'Road/Street'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'area_locality'}
                                            label={'Area/Locality'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'town_city_district'}
                                            label={'Town/City/District'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'state'}
                                            label={'State'}
                                            apiError={api.error}
                                            options={Mapping.accountant.state}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'country'}
                                            label={'Country'}
                                            apiError={api.error}
                                            options={Mapping.accountant.country}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required={!isReading}
                                            isReading={isReading}
                                            name={'pincode'}
                                            label={'Pincode'}
                                            apiError={api.error}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Formik>
        </>
    )
});

export default AccountantForm;