import React, {useContext, useEffect} from "react";
import {CAContext} from "./CAContext";
import {ProgressContext} from "../utils/ProgressContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import API from "../utils/API";
import {useNavigate} from "react-router-dom";
import {generalAPI, makeNulls} from "../cbs/utils";
import {Form, Formik} from "formik";
import {Box, Button, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import {caValidationSchema} from "./validations";
import APIError from "../utils/components/APIError";
import RadioField from "../utils/components/RadioField";
import TextField from "../utils/components/TextField";
import Mapping from "../utils/UtilityMapping.json";
import AddIcon from "@mui/icons-material/Add";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

async function caAPI(data) {
    const response = await API.post('cbs/cas/', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }, formSerializer: {
            dots: true
        }
    });
    return response.data;
}

async function caUpdateAPI(id, data) {
    const response = await API.patch(`cbs/cas/${id}/`, data);
    return response.data;
}

export default function AODetailForm() {
    const {caState, caDispatch} = useContext(CAContext);
    const isReading = caState.baseData.caStatus >= 2;
    const {setActiveStep} = caState.baseData;
    const {handleProgressOpen, handleProgressClose} = useContext(ProgressContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const caRef = caState.baseData.caRef;

    const caCreate = useMutation(['cbs', 'cas'], caAPI, {
        onMutate: variables => {
            variables = makeNulls(variables);
            handleProgressOpen()
            return variables
        }, onSettled: () => handleProgressClose(), onSuccess: () => {
            navigate(-1);
        }
    });

    const caUpdate = useMutation(['cbs', 'cas', caState.baseData.ca], (data) => caUpdateAPI(caState.baseData.ca, data), {
        onMutate: variables => {
            variables = makeNulls(variables);
            handleProgressOpen()
            return variables
        }, onSettled: () => {
            handleProgressClose();
            refetch();
        }, onSuccess: () => {
            queryClient.invalidateQueries(['cbs', 'cas', caState.baseData.ca])
        }
    });

    const {
        data,
        isFetching,
        refetch
    } = useQuery(
        ['cbs', 'cas', caState.baseData.ca],
        () => generalAPI(`cbs/cas/${caState.baseData.ca}/`),
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: (caState.baseData.caStatus !== 0) && !caState.baseData.pages[caState.baseData.activeStep].visited,
        });

    useEffect(() => {
        if (!caState.baseData.pages[caState.baseData.activeStep].visited && data && (caState.baseData.caStatus !== 0)) {
            caDispatch({
                type: 'addUpdateFormData', data: {'ca': data}
            })
        }

    }, [data, caState.baseData.ca, caState.baseData.activeStep, caState.baseData.pages, caDispatch, caState.baseData.caStatus]);

    const isError = caCreate.isError || caUpdate.isError;
    const error = caCreate.error || caUpdate.error;

    return (
        <>
            <Paper sx={{p: 3}}>
                <Box
                    sx={{position: 'relative'}}
                >
                    <Box
                        sx={{
                            position: 'absolute', top: '0px', left: '200px'
                        }}
                    >
                        {(isFetching || caUpdate.isLoading) &&
                            <CircularProgress size={'1.5rem'}/>}
                    </Box>
                    <Box sx={{position: 'absolute', right: '0px'}}>
                        {
                            caState.baseData.caStatus === 1 && <>
                                <Button startIcon={<CancelIcon/>}
                                        onClick={() => caRef.current.resetForm({values: caState.updateData.remitter})}>Discard</Button>
                                <Button sx={{ml: 3}} startIcon={<SaveIcon/>} onClick={() => caRef.current.submitForm()}>Save</Button>
                            </>
                        }
                        {
                            caState.baseData.caStatus === 0 && <>
                                <Button
                                    variant={'contained'}
                                    sx={{ml: 3}}
                                    startIcon={<AddIcon/>}
                                    onClick={() => caRef.current.submitForm()}
                                >
                                    Create
                                </Button>
                            </>
                        }
                    </Box>
                </Box>
                <Box sx={{my: 2, width: '50%'}}>
                    {
                        isError && <Grid item xs={12}>
                            <Box>
                                <APIError error={error}/>
                            </Box>
                        </Grid>
                    }
                </Box>
                <Formik
                    innerRef={caRef}
                    validationSchema={caValidationSchema}
                    initialValues={caState.formData.ca}
                    validateOnChange={false}
                    enableReinitialize={true}
                    onSubmit={values => {
                        console.log(values)
                        if (caState.baseData.caStatus === 0) {
                            values['cb'] = caState.baseData.transactionId
                            caCreate.mutate(values);
                        } else if (caState.baseData.caStatus === 1) {
                            caUpdate.mutate(values);
                        }
                    }}
                >
                    {
                        formik => (
                            <Form>
                                <Grid container columnSpacing={4} rowSpacing={4}>
                                    <Grid item xs={12} mt={-4}>
                                        <Typography variant={'h6'}>
                                            Remittance Details
                                        </Typography>
                                        <Typography variant={"h6"} fontWeight={"500"} color={"grey.700"}>
                                            15CA PartC Details
                                        </Typography>
                                        <Grid container columnSpacing={2} rowSpacing={4}>
                                            <Grid item xs={12}>
                                                <RadioField
                                                    row
                                                    required
                                                    fullWidth
                                                    isReading={isReading}
                                                    label={'AO Certificate Obtained?'}
                                                    name={'ao_obtained'}
                                                    helperText={''}
                                                    apiError={error}
                                                />
                                            </Grid>
                                            {
                                                formik.values.ao_obtained === 'Y' && <>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            select
                                                            required
                                                            fullWidth
                                                            isReading={isReading}
                                                            label={'AO Certificate Type'}
                                                            name={'ao_cert'}
                                                            options={Mapping.transactionCA.aoCertSection}
                                                            apiError={error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            isReading={isReading}
                                                            label={'Officer Name'}
                                                            name={'ao_officer_name'}
                                                            upperCase
                                                            apiError={error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            isReading={isReading}
                                                            label={'Officer Designation'}
                                                            name={'ao_officer_designation'}
                                                            upperCase
                                                            apiError={error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            isReading={isReading}
                                                            label={'Certification Date'}
                                                            name={'ao_cert_date'}
                                                            type={'date'}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            apiError={error}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            isReading={isReading}
                                                            label={'Certification Number'}
                                                            name={'ao_cert_number'}
                                                            upperCase
                                                            apiError={error}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={4}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    isReading={isReading}
                                                    label={'Verification Date'}
                                                    name={'verification_date'}
                                                    type={'date'}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    apiError={error}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }
                </Formik>
            </Paper>
            <Box sx={{textAlign: 'center', mt: 3}}>
                <Button
                    startIcon={<NavigateBeforeIcon/>}
                    variant={'outlined'}
                    onClick={() => setActiveStep(0, 1)}
                >
                    Previous
                </Button>
                <Button
                    variant={'contained'}
                    sx={{ml: 3}}
                    disabled={caState.baseData.caStatus <= 0}
                    startIcon={<NavigateNextIcon/>}
                    onClick={() => setActiveStep(2, 1)}
                >
                    Next
                </Button>
            </Box>
        </>
    )
}