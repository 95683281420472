import API from "../utils/API";
import {useLocation} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import APIError from "../utils/components/APIError";
import {alpha, Box, Button, Container, Tab, Tabs, Toolbar, Tooltip, Typography} from "@mui/material";
import fetchingStatus from "../utils/components/QueryFetching";
import {organizationalUser} from "../utils/constants/UserTypes";
import withPermission from "../utils/components/PermissionRequired";
import RemitterForm from "./RemitterForm";
import {StatusChip} from "../utils/utils";
import BackButton from "../utils/components/BackButton";
import {useRef, useState} from "react";
import TabPanel from "../utils/components/TabPanel";
import RemitteeList from "../remittees/RemitteeList";
import RemitteeForm from "../remittees/RemitteeForm";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import CBList from "../cbs/CBList";
import UserLogging from "../utils/components/UserLogging";

export async function remitterDetailAPI(id) {
    const response = await API.get(`remitters/${id}/`);
    return response.data;
}

function RemitterDetail() {
    const location = useLocation();
    const ref = useRef();
    let data = location.state?.data;
    let defaultValue = location.state && location.state.value ? location.state.value : 0
    const [value, setValue] = useState(defaultValue);
    const [createRemittee, setCreateRemitee] = useState(false);

    function handleTabChange(e, newValue) {
        setValue(newValue);
        setCreateRemitee(false);
    }

    const tabDesign = {
        position: 'absolute', left: '80px', top: 0
    }

    const tabsButtonDesign = {
        color: 'primary.main', height: '64px', '&.Mui-selected': {
            backgroundColor: (theme) => `${alpha(theme.palette.primary.main, 0.08)}`,
        }
    }


    const query = useQuery(['remitters', data.id], () => remitterDetailAPI(data.id), {
        enabled: Boolean(data.id), placeholderData: data
    });

    if (query.data) {

        let button;
        if (value === 0 && !createRemittee) {
            let baseButton = <Button disabled={query.data.usage_status >= 2} variant={'contained'}
                                     onClick={() => setCreateRemitee(true)}>Create Remittee</Button>

            if (query.data.usage_status >= 2) {
                button = <Tooltip title={'Cannot create Remittee for until the Remitter is \'OPERATIVE\''}>
                    <span>
                        {baseButton}
                    </span>
                </Tooltip>
            } else {
                button = baseButton
            }
        }

        return (<Box>
            <Toolbar>
                <BackButton to={-1}/>
                <Tabs value={value} onChange={handleTabChange} sx={tabDesign}>
                    <Tab
                        sx={tabsButtonDesign}
                        label={"Remittee List"}
                        value={0}
                    />
                    <Tab
                        sx={tabsButtonDesign}
                        label={"Transactions"}
                        value={1}
                    />
                    <Tab
                        label={"Details"}
                        sx={tabsButtonDesign}
                        value={2}
                    />
                </Tabs>
                <Typography variant={"navbarTitle"}>{query.data.name}</Typography>
                <StatusChip sx={{ml: 2}} status={query.data.usage_status}/>
                <Box
                    sx={{
                        position: "absolute", top: "12px", right: "40px"
                    }}
                >
                    {button}
                    {createRemittee && <>
                        <Button startIcon={<CancelIcon/>} variant={'contained'}
                                onClick={() => ref.current.handleDiscardOpen()}>Discard</Button>
                        <Button startIcon={<AddIcon/>} sx={{ml: 3}} onClick={() => ref.current.handleSaveOpen()}
                                variant={'contained'}>Create</Button>
                    </>}
                </Box>
            </Toolbar>
            <Box sx={{
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 128px)'
            }}>
                <Container sx={{
                    position: "relative",
                }}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    </Box>
                    {!createRemittee ? (<>
                        <TabPanel value={value} index={2} sx={{pt: 1}}>
                            <Box
                                sx={{
                                    position: 'absolute', right: "44px", top: "8px"
                                }}
                            >
                                <Typography variant={"helpText"}>{fetchingStatus(query)}</Typography>
                            </Box>
                            <RemitterForm data={query.data}/>
                            <UserLogging data={query.data}/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <CBList params={{
                                template_remitter: query.data.id
                            }}/>
                        </TabPanel>
                        <TabPanel value={value} index={0}>
                            <RemitteeList remitter={query.data}/>
                        </TabPanel>
                    </>) : (<RemitteeForm
                        ref={ref}
                        remitter={query.data}
                        onCreateSuccess={() => {
                            setValue(0);
                            setCreateRemitee(false)
                        }}
                        onCreateDiscard={() => setCreateRemitee(false)}/>)}
                </Container>
            </Box>
        </Box>)
    }

    if (query.error) {
        return <APIError error={query.error}/>
    }

    return <></>
}

export default withPermission(RemitterDetail, organizationalUser);