import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Theme from "./Theme";
import CssBaseline from '@mui/material/CssBaseline';
import {RouterProvider} from "react-router-dom";
import {router} from "./Routes";
import {ProgressProvider} from "./utils/ProgressContext";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {UserProvider} from "./utils/UserContext";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {LabeledProgressProvider} from "./utils/LabeledProgressContext";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false
        },
        mutations: {
            retry: false
        }
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Theme>
            <CssBaseline/>
            <QueryClientProvider client={queryClient}>
                <UserProvider>
                    <LabeledProgressProvider>
                        <ProgressProvider>
                            <RouterProvider router={router}>
                                <App/>
                            </RouterProvider>
                        </ProgressProvider>
                    </LabeledProgressProvider>
                </UserProvider>
                <ReactQueryDevtools/>
            </QueryClientProvider>
        </Theme>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
