import {Box, Container, Toolbar, Typography} from "@mui/material";
import CBList from "./CBList";
import BackButton from "../utils/components/BackButton";

export default function CBBase(){
    return (
        <>
            <Toolbar>
                <BackButton to={-1}/>
                <Typography variant={'navbarTitle'}>CB Transcations</Typography>
            </Toolbar>
            <Box
                sx={{
                    position: "relative",
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 128px)'
                }}
            >
                <Container>
                    <CBList params={{}}/>
                </Container>
            </Box>
        </>
    )
}