import {Container, Toolbar, Typography} from "@mui/material";
import UserForm from "./UserForm";
import BackButton from "../utils/components/BackButton";

export default function UserCreate() {
    return (
        <>
            <Toolbar>
                <BackButton to={-1}/>
                <Typography variant={"h5"} sx={{color: "primary.main"}}>Create User</Typography>
            </Toolbar>
            <Container sx={{overflowY: 'auto', maxHeight: 'calc(100vh - 128px)'}}>
                <UserForm/>
            </Container>
        </>
    )
}