import {Alert, List, ListItem, ListItemText} from "@mui/material";
import React from "react";

export default function APIError({error}) {
    let message;
    let severity = "error";

    if (error.code === "ERR_NETWORK") {
        message = "Oops! Looks like our server took a coffee break ☕️. We're on it!"
        severity = "warning"
    } else if (error.code === "ERR_BAD_RESPONSE" && error.response.status === 500) {
        message = "Server's on vacation, but we're summoning the IT wizards!"
        severity = "warning"
    } else if (error.response.status === 400 && error.response.data.detail) {
        let detailErrors = error.response.data.detail;
        message = (
            <List disablePadding>
                {
                    detailErrors && detailErrors.map((detailError, index) => (
                        <ListItem key={index} sx={{p: 0}} disablePadding>
                            <ListItemText
                                primary={detailError}
                            />
                        </ListItem>
                    ))
                }
            </List>
        )
    } else if (error.response.status > 400) {
        message = error.response.data.detail
    }

    if (message) {
        return <Alert severity={severity} sx={{alignItems: 'center'}}>{message}</Alert>
    }

    return <></>;
}


export function AdditionalAPIError({apiError, field='Invoice'}) {

    if (apiError.response.status !== 400 || !Array.isArray(apiError.response.data)){
        return <></>
    }

    return (
        <>
            <Alert icon={false} severity={'error'}>
                <List disablePadding>
                    {apiError.response.data.map((errors, rowIndex) => (
                        <ListItem disablePadding key={rowIndex} sx={{flexDirection: 'column', alignItems: 'start'}}>
                            {
                                Object.keys(errors).length !== 0 &&
                                <>
                                    <ListItemText>{field} - {rowIndex + 1}</ListItemText>
                                    <List disablePadding>
                                        {Object.keys(errors).map((key, errIndex) => (
                                            <React.Fragment key={`${rowIndex}-${errIndex}`}>
                                                <ListItem sx={{py: 0}}>
                                                    <ListItemText>{errors[key]}</ListItemText>
                                                </ListItem>
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </>
                            }
                        </ListItem>
                    ))}
                </List>
            </Alert>
        </>
    )
}
